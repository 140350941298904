const styles = theme => ({
    root: {
        padding: 10,
        width: 'calc(100% - 20px)',
    },
});

export {
    styles,
};
