const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 15,
        backgroundColor: '#00000050',
        minWidth: 320,
        borderRadius: 5,
    },
    formControl: {
        margin: '15px 15px 0 15px',
        width: '100%',
    },
    loginButton: {
        marginTop: 15,
        width: '100%',
    },
    loginHeader: {
        position: 'relative',
        top: -35,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: '1.5em',
    },
    avatar: {
        width: 60,
        height: 60,
    },
    input: {
        color: '#f4f4f4b5',
    },
    label: {
        color: '#fff',
        textShadow: '0 1px 15px black',
    },
    btnContainer: {
        display: 'inline-flex',
        justifyContent: 'space-evenly',
        width: '100%',
        alignItems: 'center',
    },
});

export {
    styles,
};
