import { robotActionTypes } from './robotTypes';
import {
    updateAddSelectedRobot,
    updateCurrentRobotSection,
    updateCurrentRobotState,
    updateRemoveSelectedRobot,
    updateRobots,
    updateShowRobotControlState
} from './robotUtils';

const INITIAL_STATE = {
    showSettingDrawer: false,
    currentRobot: null,
    selectedRobots: [],
    robots: [],
    simulationRobots: [],
    simulationRobotStreamers: [],
    currentlyDrivingRobot: null,
    // TODO replace with something more sensible. only for demo
    path: [],
};

const robotReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case robotActionTypes.SET_CURRENT_ROBOT:
            return {
                ...state,
                currentRobot: action.payload,
            };
        case robotActionTypes.SET_ROBOTS:
            //case of reseting robots
            if (action.payload.length === 0) {
                return {
                    ...state,
                    robots: [].concat(action.payload),
                    currentlyDrivingRobot: null,
                };
            } else {
                return {
                    ...state,
                    robots: [].concat(action.payload),
                };
            }
        case robotActionTypes.SET_SIMULATION_ROBOTS:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    simulationRobots: action.payload,
                    currentlyDrivingRobot: null,
                }
            } else {
                return {
                    ...state,
                    simulationRobots: action.payload,
                }
            }
        case robotActionTypes.TOGGLE_CURRENT_ROBOT_DRAWER:
            return {
                ...state,
                showSettingDrawer: !state.showSettingDrawer,
            }
        case robotActionTypes.TOGGLE_CURRENT_ROBOT_SHOW_ROBOT:
            if (action.payload.isLiveMode) {
                return {
                    ...state,
                    robots: updateShowRobotControlState(state.robots, action.payload.robot, 'showRobot'),
                    currentRobot: updateCurrentRobotState(action.payload.robot, 'showRobot'),
                }
            } else {
                return {
                    ...state,
                    simulationRobots: updateShowRobotControlState(state.simulationRobots, action.payload.robot, 'showRobot'),
                    currentRobot: updateCurrentRobotState(action.payload.robot, 'showRobot'),
                }
            }
        case robotActionTypes.TOGGLE_CURRENT_ROBOT_SHOW_TRAIL:
            if (action.payload.isLiveMode) {
                return {
                    ...state,
                    robots: updateShowRobotControlState(state.robots, action.payload.robot, 'showTrail'),
                    currentRobot: updateCurrentRobotState(action.payload.robot, 'showTrail'),
                }
            } else {
                return {
                    ...state,
                    simulationRobots: updateShowRobotControlState(state.simulationRobots, action.payload.robot, 'showTrail'),
                    currentRobot: updateCurrentRobotState(action.payload.robot, 'showTrail'),
                }
            }
        case robotActionTypes.TOGGLE_CURRENT_ROBOT_SHOW_PATH:
            if (action.payload.isLiveMode) {
                return {
                    ...state,
                    robots: updateShowRobotControlState(state.robots, action.payload.robot, 'showPath'),
                    currentRobot: updateCurrentRobotState(action.payload.robot, 'showPath'),
                }
            } else {
                return {
                    ...state,
                    simulationRobots: updateShowRobotControlState(state.simulationRobots, action.payload.robot, 'showPath'),
                    currentRobot: updateCurrentRobotState(action.payload.robot, 'showPath'),
                }
            }
        case robotActionTypes.TOGGLE_CURRENT_ROBOT_SHOW_SECTION:
            if (action.payload.isLiveMode) {
                return {
                    ...state,
                    robots: updateShowRobotControlState(state.robots, action.payload.robot, 'showSection'),
                    currentRobot: updateCurrentRobotState(action.payload.robot, 'showSection'),
                }
            } else {
                return {
                    ...state,
                    simulationRobots: updateShowRobotControlState(state.simulationRobots, action.payload.robot, 'showSection'),
                    currentRobot: updateCurrentRobotState(action.payload.robot, 'showSection'),
                }
            }
        case robotActionTypes.SET_LIVE_ROBOT_SECTION:
            return {
                ...state,
                robots: updateRobots(state.robots, state.currentRobot, action.payload),
                currentRobot: updateCurrentRobotSection(state.currentRobot, action.payload)
            };
        case robotActionTypes.SET_SIMULATED_ROBOT_SECTION:
            return {
                ...state,
                simulationRobots: updateRobots(state.simulationRobots, state.currentRobot, action.payload),
                currentRobot: updateCurrentRobotSection(state.currentRobot, action.payload)
            };
        // TODO replace with something more sensible. only for demo
        case robotActionTypes.SET_ROBOT_PATH:
            return {
                ...state,
                path: action.payload,
            };
        case robotActionTypes.SELECT_ROBOT:
            return {
                ...state,
                selectedRobots: updateAddSelectedRobot(state.selectedRobots, action.payload),
            }
        case robotActionTypes.UNSELECT_ROBOT:
            return {
                ...state,
                selectedRobots: updateRemoveSelectedRobot(state.selectedRobots, action.payload),
            }
        case robotActionTypes.UNSELECT_ALL_ROBOTS:
            return {
                ...state,
                selectedRobots: [],
                simulationRobotStreamers: [],
            }
        case robotActionTypes.ADD_SIMULATION_ROBOT_STREAMER:
            let newSimulationRobotStreamers = state.simulationRobotStreamers.slice();
            newSimulationRobotStreamers.push(action.payload);
            return {
                ...state,
                simulationRobotStreamers: newSimulationRobotStreamers,
            }
        case robotActionTypes.REMOVE_SIMULATION_ROBOT_STREAMER:
            return {
                ...state,
                simulationRobotStreamers: state.simulationRobotStreamers
                    .filter(robot => robot.modelId !== action.payload.modelId),
            }
        case robotActionTypes.UPDATE_ROBOT_PATH_LENGTH:
            const pathCurrentRobot = {
                ...state.currentRobot,
                pathLength: action.payload.pathLength,
            };
            let pathRobots = action.payload.isLiveMode ? state.robots : state.simulationRobots;
            pathRobots = pathRobots.map(r => r.modelId === pathCurrentRobot.modelId ? pathCurrentRobot : r);
            if (action.payload.isLiveMode) {
                return {
                    ...state,
                    currentRobot: pathCurrentRobot,
                    robots: pathRobots,
                }
            } else {
                return {
                    ...state,
                    currentRobot: pathCurrentRobot,
                    simulationRobots: pathRobots,
                }
            }
        case robotActionTypes.UPDATE_ROBOT_TRAIL_LENGTH:
            const robotIndex = state.robots.findIndex(r => r.ipAddress === state.currentRobot.ipAddress);

            const trailCurrentRobot = {
                ...state.robots[robotIndex],
                trailLength: action.payload.trailLength,
            };

            const robotim = [].concat(state.robots);
            robotim.splice(robotIndex, 1, trailCurrentRobot);

            let trailRobots = action.payload.isLiveMode ? state.robots : state.simulationRobots;
            trailRobots = trailRobots.map(r => r.modelId === trailCurrentRobot.modelId ? trailCurrentRobot : r);

            if (action.payload.isLiveMode) {
                return {
                    ...state,
                    currentRobot: trailCurrentRobot,
                    robots: robotim,
                }
            } else {
                return {
                    ...state,
                    currentRobot: trailCurrentRobot,
                    simulationRobots: trailRobots,
                }
            }
        case robotActionTypes.TOGGLE_ROBOT_MODE:
            //payload is isLiveMode
            let newRobots = action.payload ? state.robots.slice() : state.simulationRobots.slice();
            newRobots.forEach(r => {
                if (r.modelId === state.currentRobot.modelId)
                    r.isRovMode = !r.isRovMode;
                else {
                    r.isRovMode = false;
                }
            });

            let newCurrentlyDrivingRobot = null;
            if (state.currentRobot.isRovMode === true) {
                newCurrentlyDrivingRobot = state.currentRobot;
            } else if (state.currentlyDrivingRobot !== null) {
                newCurrentlyDrivingRobot = state.currentlyDrivingRobot.modelId === state.currentRobot.modelId
                    ? null : state.currentlyDrivingRobot;
            }

            if (action.payload) {
                return {
                    ...state,
                    //currentRobot: newCurrentRobot,
                    robots: newRobots,
                    currentlyDrivingRobot: newCurrentlyDrivingRobot,
                }
            } else {
                return {
                    ...state,
                    //currentRobot: newCurrentRobot,
                    simulationRobots: newRobots,
                    currentlyDrivingRobot: newCurrentlyDrivingRobot,
                }
            }
        default:
            return state;
    }
};

export default robotReducer;
