const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minWidth: 500,
        maxWidth: 600,
        alignItems: 'center',
    },
    topBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    body: {
        fontSize: '2em',
        padding: 15,
        width: '100%',
        flexGrow: 1,
    },
    robotControlRoot: {
        marginBottom: 15,
    },
    customSectionTool : {
        marginTop: '20px',
    },
    customSectionNameInput : {
        width: '100%',
        marginTop:'20px',
    },
    customSectionButtonRow : {
        marginTop: '20px',
        display : 'flex',
        flexDirection : 'row',
        width : '100%',
        justifyContent: 'space-between',
    },
    isDrawingContainer : {
        pointerEvents : 'none',
    },
    settingsDrawerContent : {
        pointerEvents : 'auto',
    },
    menuItem: {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: 30,
    },
    deleteIcon: {
        '&:hover': {
            color: theme.customPalette.red,
        },
    },
    fabButton: {
        marginBottom: 15,
    },
    tooltipHelper: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.background.default,
    },
    highLightHelper: {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.35,
    },
    lengthControlContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    lengthControlSlider: {
        display: 'flex',
        width: '300px'
    },
    lengthControlLabel: {
        display: 'flex',
        paddingLeft: '20px',
    },
});

export {
    styles,
};
