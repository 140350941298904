import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';


import LoginForm from '../LoginForm'
import CompanyLogo from '../../static/images/troll_systems_logo.png';

import { styles } from './LandingPageStyles';

class LandingPage extends React.Component {
    static propTypes = {
        loadingUser: PropTypes.bool,
    };

    static defaultProps = {
        loadingUser: true,
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <a href="https://www.trollsystems.no/" target="_blank" rel="noreferrer noopener">
                        <img
                            alt=""
                            className={classes.headerImage}
                            src={CompanyLogo}
                        />
                    </a>
                </div>
                <div className={classes.content}>
                    <Slide
                        direction="down"
                        in={true}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        timeout={200}
                    >
                        <div className={classes.caption}>
                            <div>NetRobot</div>
                            <div>Clean nets - healthy fish!</div>
                        </div>
                    </Slide>
                    <LoginForm
                        loadingUser={this.props.loadingUser}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(LandingPage);
