import * as THREE from 'three';
import Environment from './Environment';
import { PoolSettings, RobotSettings, SiteSettings, WaterSettings } from './OperationalValues';
import { getRandomVal } from '../Helpers';

class Site extends Environment {

    constructor(assets = null, font, update) {
        super(assets);

        this.font = font;
        this.update = update;
        this.mesh = null;
        this.sectionGeo = null;
        this.curvatureGeo = null;
        this.cornerGeo = null;
    }

    generate() {
        if (this.update !== null && this.update.hasOwnProperty('location') && this.update.location.lon && this.update.location.lat) {
            WaterSettings.LONGITUDE = this.update.location.lon;
            WaterSettings.LATITUDE = this.update.location.lat;
            WaterSettings.IS_LOCATION_SET = true;
        }

        this.mesh = new THREE.Group();
        this.mesh.name = 'predefinedSections';
        this.sectionGeo = new THREE.PlaneBufferGeometry(1, 1);
        this.curvatureGeo = new THREE.CylinderBufferGeometry(PoolSettings.RADIUS, PoolSettings.RADIUS, 1,
            PoolSettings.SMOOTHNESS, 1, true, -Math.PI / 2,
            Math.PI / 2);
        this.cornerGeo = new THREE.SphereBufferGeometry(PoolSettings.RADIUS, PoolSettings.SMOOTHNESS,
            PoolSettings.SMOOTHNESS, 0, Math.PI / 2, Math.PI / 2,
            Math.PI / 2);
        if (this.update !== null && !!this.update.sections) {
            for (let i = 0; i < this.update.sections.length; i++) {
                let section = this.update.sections[i];
                let faceColor;
                let fontColor;
                if (i <= 9) {
                    faceColor = new THREE.Color(
                        'hsl(' + SiteSettings.COLOR[i].h.toString() + ', 40%, ' +
                        SiteSettings.COLOR[i].l.toString() +
                        '%)');
                    // TODO listen to color-theme change? (black or white text)
                    fontColor = new THREE.Color(`hsl(${SiteSettings.COLOR[i].h.toString()}, 0%, 90%)`);
                } else {
                    faceColor = new THREE.Color(section.color.r, section.color.g, section.color.b);
                    fontColor = null;
                }
                let faceMat = new THREE.MeshBasicMaterial(
                    {
                        color: faceColor,
                        transparent: SiteSettings.TRANSPARENT,
                        opacity: SiteSettings.OPACITY,
                        wireframe: SiteSettings.WIREFRAME
                    });
                let fontMat = new THREE.MeshBasicMaterial(
                    {
                        color: fontColor,
                        transparent: SiteSettings.TRANSPARENT,
                        opacity: SiteSettings.OPACITY,
                        wireframe: SiteSettings.WIREFRAME
                    });
                faceMat.side = SiteSettings.SIDE;
                fontMat.side = SiteSettings.SIDE;
                if (section.name.toLowerCase() === 'inlet 1' || section.name.toLowerCase() === 'inlet 2') {
                    this.generateInletSection(this.getFormattedBorder(section.border, 'inlet'), section.name, fontMat,
                        faceMat);
                } else if (section.name.toLowerCase() === 'outlet 1' || section.name.toLowerCase() === 'outlet 2') {
                    this.generateOutletSection(this.getFormattedBorder(section.border, 'outlet'), section.name,
                        faceMat, fontMat, this.sectionGeo.clone());
                } else if (section.name.toLowerCase() === 'right wall 1' || section.name.toLowerCase() === 'right wall 2') {
                    this.generateRightWallSection(this.getFormattedBorder(section.border, 'right wall'), section.name,
                        faceMat, fontMat, this.sectionGeo.clone());
                } else if (section.name.toLowerCase() === 'left wall 1' || section.name.toLowerCase() === 'left wall 2') {
                    this.generateLeftWallSection(this.getFormattedBorder(section.border, 'left wall'), section.name,
                        faceMat, fontMat, this.sectionGeo.clone());
                } else if (section.name.toLowerCase() === 'floor 1' || section.name.toLowerCase() === 'floor 2') {
                    this.generateFloorSection(this.getFormattedBorder(section.border, 'floor'), section.name,
                        faceMat, fontMat, this.sectionGeo.clone());
                }
                //case of custom section
                else {
                    this.generateCustomSection(this.getFormattedBorder(section.border, 'custom section'), section.name,
                        fontMat, faceMat);
                }
            }
        }
    }

    generateCustomSection({ p1, p2, p3, p4 }, name, fontMat, faceMat) {
        /**
        * The process used for coloring in custom sections are to use 2d shapes. For the xy plane, this simply involves
        * disgarding the z coordinate for the XY plane, however, for the YZ and XZ planes, this involves some quarterion
        * transformations
        */
        let csGroup = new THREE.Group();
        csGroup.name = name;

        let plane = null;
        if (p1.x === p2.x && p2.x === p3.x && p3.x === p4.x) {
            plane = 'yz';
        } else if (p1.y === p2.y && p2.y === p3.y && p3.y === p4.y) {
            plane = 'xz';
        } else if (p1.z === p2.z && p2.z === p3.z && p3.z === p4.z) {
            plane = 'xy';
        }

        let points = [
            new THREE.Vector3(parseFloat(p1.x), parseFloat(p1.y), parseFloat(p1.z)),
            new THREE.Vector3(parseFloat(p2.x), parseFloat(p2.y), parseFloat(p2.z)),
            new THREE.Vector3(parseFloat(p3.x), parseFloat(p3.y), parseFloat(p3.z)),
            new THREE.Vector3(parseFloat(p4.x), parseFloat(p4.y), parseFloat(p4.z))
        ];

        let csShape = new THREE.Shape();
        let csGeo = null;
        let csMesh = null;
        let tri = new THREE.Triangle(points[2], points[1], points[0]);
        let normal = new THREE.Vector3();
        tri.getNormal(normal);
        let baseNormalXY = new THREE.Vector3(0, 0, 1);
        let quaternion = new THREE.Quaternion().setFromUnitVectors(normal, baseNormalXY);
        switch (plane) {
            case 'yz':

                let tempPointsYZ = [];
                points.forEach(p => {
                    tempPointsYZ.push(p.clone().applyQuaternion(quaternion));
                });

                csShape = new THREE.Shape(tempPointsYZ);
                csGeo = new THREE.ShapeGeometry(csShape);
                csMesh = new THREE.Mesh(csGeo, faceMat);
                csMesh.geometry.vertices = points;


                if (p1.x < 20) {
                    csMesh.geometry.translate(0.1, 0, 0);
                } else {
                    csMesh.geometry.translate(-0.1, 0, 0);
                }
                break;

            case 'xz':

                let tempPointsXZ = [];
                points.forEach(p => {
                    tempPointsXZ.push(p.clone().applyQuaternion(quaternion));
                });

                csShape = new THREE.Shape(tempPointsXZ);
                csGeo = new THREE.ShapeGeometry(csShape);
                csMesh = new THREE.Mesh(csGeo, faceMat);
                csMesh.geometry.vertices = points;
                csMesh.geometry.translate(0, 0.1, 0);

                break;

            //XY plane
            default:
                csShape.moveTo(p1.x, p1.y);
                csShape.lineTo(p2.x, p2.y);
                csShape.lineTo(p3.x, p3.y);
                csShape.lineTo(p4.x, p4.y);
                csGeo = new THREE.ShapeBufferGeometry(csShape);
                csMesh = new THREE.Mesh(csGeo, faceMat);
                if (p1.z > -10) {
                    csMesh.geometry.translate(0, 0,
                        -PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH - SiteSettings.GAP_WALL -
                        0.1);
                } else {
                    csMesh.geometry.translate(0, 0, -PoolSettings.LENGTH_TOTAL + 0.1);
                }
                break;
        }

        csGroup.add(csMesh);

        this.mesh.add(csGroup);


    }

    generateInletSection({ p1, p2, p3, p4 }, name, fontMat, faceMat) {
        let inletGroup = new THREE.Group();
        inletGroup.name = name;
        inletGroup.border = {
            p1: new THREE.Vector3(p1.x, p1.y, p1.z),
            p2: new THREE.Vector3(p2.x, p2.y, p2.z),
            p3: new THREE.Vector3(p4.x, p4.y, p4.z),
            p4: new THREE.Vector3(p3.x, p3.y, p3.z)
        };

        let isLeftCurveNeeded = false, isRightCurveNeeded = false;
        if (p1.x > PoolSettings.WIDTH - PoolSettings.RADIUS && p1.y < PoolSettings.RADIUS) {
            isLeftCurveNeeded = true;
            p1.x = PoolSettings.WIDTH;
            p1.y = 0;
            p3.x = PoolSettings.WIDTH;
            p3.y = p3.y < PoolSettings.RADIUS ? PoolSettings.RADIUS : p3.y;
        }
        if (p2.x < PoolSettings.RADIUS && p2.y < PoolSettings.RADIUS) {
            isRightCurveNeeded = true;
            p2.x = p2.y = p4.x = 0;
            p4.y = p4.y < PoolSettings.RADIUS ? PoolSettings.RADIUS : p4.y;
        }

        let inletShape = new THREE.Shape();
        (function roundedRect(shape, radius, p1x, p1y, p2x, p2y, p3x, p3y, p4x, p4y) {
            shape.moveTo(p4x, p4y);
            if (isRightCurveNeeded) {
                shape.lineTo(p2x, radius);
                shape.absarc(p2x + radius, radius, radius, Math.PI, Math.PI + Math.PI / 2, false);
            } else shape.lineTo(p2x, p2y);
            if (isLeftCurveNeeded) {
                shape.lineTo(p1x - radius, p1y);
                shape.absarc(p1x - radius, radius, radius, Math.PI + Math.PI / 2, 2 * Math.PI, false);
            } else shape.lineTo(p1x, p1y);
            shape.lineTo(p3x, p3y);
        })(inletShape, PoolSettings.RADIUS, p1.x, p1.y, p2.x, p2.y, p3.x, p3.y, p4.x, p4.y);

        let inletGeo = new THREE.ShapeBufferGeometry(inletShape);
        inletGeo.translate(0, 0, -PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH - SiteSettings.GAP_WALL);
        inletGroup.add(new THREE.Mesh(inletGeo, faceMat));

        let centerX = (p1.x - p2.x) / 2 + p2.x,
            centerY = (p3.y - p1.y) / 2 + p1.y;
        inletGroup.add(this.generateSectionText('inlet', fontMat, name, p1.x - p2.x, p3.y - p1.y, centerX, centerY));

        this.mesh.add(inletGroup);
    }

    generateOutletSection({ p1, p2, p3, p4 }, name, faceMat, fontMat, sectionGeo) {
        let outletGroup = new THREE.Group();
        outletGroup.name = name;
        outletGroup.border = {
            p1: new THREE.Vector3(p1.x, p1.y, p1.z),
            p2: new THREE.Vector3(p2.x, p2.y, p2.z),
            p3: new THREE.Vector3(p4.x, p4.y, p4.z),
            p4: new THREE.Vector3(p3.x, p3.y, p3.z)
        };


        let w, h, centerX, centerY, isFloorCurveNeeded = false, isLeftCurveNeeded = false, isRightCurveNeeded = false;
        if (p1.y < PoolSettings.RADIUS) {
            isFloorCurveNeeded = true;
            p1.y = PoolSettings.RADIUS;
        }
        if (p1.x < PoolSettings.RADIUS) {
            isLeftCurveNeeded = true;
            p1.x = PoolSettings.RADIUS;
        }
        if (p2.x > PoolSettings.WIDTH - PoolSettings.RADIUS) {
            isRightCurveNeeded = true;
            p2.x = PoolSettings.WIDTH - PoolSettings.RADIUS;
        }

        w = p2.x - p1.x;
        h = p3.y - p1.y;
        centerX = w / 2 + p1.x;
        centerY = isFloorCurveNeeded ? h / 2 + PoolSettings.RADIUS : h / 2;

        let section = new THREE.Mesh(sectionGeo, faceMat);
        section.scale.x = w;
        section.scale.y = h;
        section.position.set(centerX, centerY, p1.z + SiteSettings.GAP_FACE);
        outletGroup.add(section);

        if (isFloorCurveNeeded && isLeftCurveNeeded) outletGroup.add(
            this.generateCorner('left', faceMat, this.cornerGeo, SiteSettings.GAP_OUTLET));
        if (isFloorCurveNeeded && isRightCurveNeeded) outletGroup.add(
            this.generateCorner('right', faceMat, this.cornerGeo, SiteSettings.GAP_OUTLET));
        if (isLeftCurveNeeded) outletGroup.add(
            this.generateWallCurvature('left', faceMat, this.curvatureGeo, h, centerY,
                SiteSettings.GAP_OUTLET));
        if (isRightCurveNeeded) outletGroup.add(
            this.generateWallCurvature('right', faceMat, this.curvatureGeo, h, centerY,
                SiteSettings.GAP_OUTLET));
        if (isFloorCurveNeeded) outletGroup.add(
            this.generateFloorCurvature('outlet', faceMat, this.curvatureGeo, w, centerX,
                SiteSettings.GAP_OUTLET));

        outletGroup.add(this.generateSectionText('outlet', fontMat, name, w, h, centerX, centerY));

        this.mesh.add(outletGroup);
    }

    generateRightWallSection({ p1, p2, p3, p4 }, name, faceMat, fontMat, sectionGeo) {
        let rightWallGroup = new THREE.Group();
        rightWallGroup.name = name;
        rightWallGroup.border = {
            p1: new THREE.Vector3(p1.x, p1.y, p1.z),
            p2: new THREE.Vector3(p2.x, p2.y, p2.z),
            p3: new THREE.Vector3(p4.x, p4.y, p4.z),
            p4: new THREE.Vector3(p3.x, p3.y, p3.z)
        };


        let w, h, centerY, centerZ, rot, isWallCurveNeeded = false, isFloorCurveNeeded = false;
        if (-p1.z > PoolSettings.LENGTH_TOTAL - PoolSettings.RADIUS) {
            isWallCurveNeeded = true;
            p1.z = -PoolSettings.LENGTH_TOTAL + PoolSettings.RADIUS;
        }
        if (p1.y < PoolSettings.RADIUS) {
            isFloorCurveNeeded = true;
            p1.y = PoolSettings.RADIUS;
        }

        w = -(p1.z - p2.z);
        h = p3.y - p1.y;
        centerZ = -w / 2 + p2.z;
        centerY = isFloorCurveNeeded ? h / 2 + PoolSettings.RADIUS : h / 2;
        rot = Math.PI / 2;

        let section = new THREE.Mesh(sectionGeo, faceMat);
        section.scale.x = w;
        section.scale.y = h;
        section.position.set(p1.x - SiteSettings.GAP_FACE, centerY, centerZ);
        section.rotateOnAxis(new THREE.Vector3(0, 1, 0), rot);
        rightWallGroup.add(section);

        if (isWallCurveNeeded && isFloorCurveNeeded) rightWallGroup.add(
            this.generateCorner('right', faceMat, this.cornerGeo, SiteSettings.GAP_WALL));
        if (isWallCurveNeeded) rightWallGroup.add(
            this.generateWallCurvature('right', faceMat, this.curvatureGeo, h, centerY,
                SiteSettings.GAP_WALL));
        if (isFloorCurveNeeded) rightWallGroup.add(
            this.generateFloorCurvature('right', faceMat, this.curvatureGeo, w, centerZ,
                SiteSettings.GAP_WALL));
        rightWallGroup.add(this.generateSectionText('right wall', fontMat, name, w, h, centerZ, centerY));

        this.mesh.add(rightWallGroup);
    }

    generateLeftWallSection({ p1, p2, p3, p4 }, name, faceMat, fontMat, sectionGeo) {
        let leftWallGroup = new THREE.Group();
        leftWallGroup.name = name;
        leftWallGroup.border = {
            p1: new THREE.Vector3(p1.x, p1.y, p1.z),
            p2: new THREE.Vector3(p2.x, p2.y, p2.z),
            p3: new THREE.Vector3(p4.x, p4.y, p4.z),
            p4: new THREE.Vector3(p3.x, p3.y, p3.z)
        };


        let w, h, centerZ, centerY, rot, isFloorCurveNeeded = false, isWallCurveNeeded = false;
        if (-p2.z > PoolSettings.LENGTH_TOTAL - PoolSettings.RADIUS) {
            isWallCurveNeeded = true;
            p2.z = -PoolSettings.LENGTH_TOTAL + PoolSettings.RADIUS;
        }
        if (p1.y < PoolSettings.RADIUS) {
            isFloorCurveNeeded = true;
            p1.y = PoolSettings.RADIUS;
        }

        w = -(p1.z - p2.z);
        h = p3.y - p1.y;
        centerZ = -w / 2 + p2.z;
        centerY = isFloorCurveNeeded ? h / 2 + PoolSettings.RADIUS : h / 2;
        rot = Math.PI / 2;

        let section = new THREE.Mesh(sectionGeo, faceMat);
        section.scale.x = w;
        section.scale.y = h;
        section.position.set(p1.x + SiteSettings.GAP_FACE, centerY, centerZ);
        section.rotateOnAxis(new THREE.Vector3(0, 1, 0), rot);
        leftWallGroup.add(section);

        if (isWallCurveNeeded && isFloorCurveNeeded) leftWallGroup.add(
            this.generateCorner('left', faceMat, this.cornerGeo, SiteSettings.GAP_WALL));
        if (isWallCurveNeeded) leftWallGroup.add(
            this.generateWallCurvature('left', faceMat, this.curvatureGeo, h, centerY, SiteSettings.GAP_WALL));
        if (isFloorCurveNeeded) leftWallGroup.add(
            this.generateFloorCurvature('left', faceMat, this.curvatureGeo, w, centerZ, SiteSettings.GAP_WALL));
        leftWallGroup.add(this.generateSectionText('left wall', fontMat, name, w, h, centerZ, centerY));

        this.mesh.add(leftWallGroup);
    }

    generateFloorSection({ p1, p2, p3, p4 }, name, faceMat, fontMat, sectionGeo) {
        let floorGroup = new THREE.Group();
        floorGroup.name = name;
        floorGroup.border = {
            p1: new THREE.Vector3(p1.x, p1.y, p1.z),
            p2: new THREE.Vector3(p2.x, p2.y, p2.z),
            p3: new THREE.Vector3(p4.x, p4.y, p4.z),
            p4: new THREE.Vector3(p3.x, p3.y, p3.z)
        };


        let w, l, centerX, centerZ, rot, isLeftCurveNeeded = false, isRightCurveNeeded = false,
            isOutCurveNeeded = false;
        if (p1.x < PoolSettings.RADIUS) {
            isLeftCurveNeeded = true;
            p1.x = PoolSettings.RADIUS;
        }
        if (p2.x > PoolSettings.WIDTH - PoolSettings.RADIUS) {
            isRightCurveNeeded = true;
            p2.x = PoolSettings.WIDTH - PoolSettings.RADIUS;
        }
        if (-p3.z > PoolSettings.LENGTH_TOTAL - PoolSettings.RADIUS) {
            isOutCurveNeeded = true;
            p3.z = -PoolSettings.LENGTH_TOTAL + PoolSettings.RADIUS;
        }

        w = p2.x - p1.x;
        l = -(p3.z - p1.z);
        centerX = w / 2 + p1.x;
        centerZ = -l / 2 + p1.z;
        rot = Math.PI / 2;

        let section = new THREE.Mesh(sectionGeo, faceMat);
        section.scale.x = w;
        section.scale.y = l;
        section.position.set(centerX, p1.y + SiteSettings.GAP_FACE, centerZ);
        section.rotateOnAxis(new THREE.Vector3(1, 0, 0), rot);
        floorGroup.add(section);

        if (isLeftCurveNeeded && isOutCurveNeeded) floorGroup.add(
            this.generateCorner('left', faceMat, this.cornerGeo, SiteSettings.GAP_FLOOR));
        if (isRightCurveNeeded && isOutCurveNeeded) floorGroup.add(
            this.generateCorner('right', faceMat, this.cornerGeo, SiteSettings.GAP_FLOOR));
        if (isLeftCurveNeeded) floorGroup.add(
            this.generateFloorCurvature('left', faceMat, this.curvatureGeo, l, centerZ, SiteSettings.GAP_FLOOR));
        if (isRightCurveNeeded) floorGroup.add(
            this.generateFloorCurvature('right', faceMat, this.curvatureGeo, l, centerZ, SiteSettings.GAP_FLOOR));
        if (isOutCurveNeeded) floorGroup.add(
            this.generateFloorCurvature('outlet', faceMat, this.curvatureGeo, w, centerX, SiteSettings.GAP_FLOOR));

        floorGroup.add(this.generateSectionText('floor', fontMat, name, w, l, centerX, centerZ));

        this.mesh.add(floorGroup);
    }

    generateWallCurvature(type, mat, geo, height, centerY, gap) {
        let curvature = new THREE.Mesh(geo, mat);
        curvature.scale.y = height;
        if (type === 'left') {
            curvature.position.set(PoolSettings.RADIUS + gap,
                centerY,
                -PoolSettings.LENGTH_TOTAL + PoolSettings.RADIUS + gap);
            curvature.rotateOnAxis(new THREE.Vector3(0, 1, 0), -Math.PI / 2);
            return curvature;
        } else if (type === 'right') {
            curvature.position.set(PoolSettings.WIDTH - PoolSettings.RADIUS - gap,
                centerY,
                -PoolSettings.LENGTH_TOTAL + PoolSettings.RADIUS + gap);
            curvature.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI);
            return curvature;
        }
    }

    generateCorner(type, mat, geo, gap) {
        let corner = new THREE.Mesh(geo, mat);
        if (type === 'right') {
            corner.position.set(PoolSettings.WIDTH - PoolSettings.RADIUS - gap, PoolSettings.RADIUS + gap,
                -PoolSettings.LENGTH_TOTAL + PoolSettings.RADIUS + gap);
            corner.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI);
            return corner;
        } else if (type === 'left') {
            corner.position.set(PoolSettings.RADIUS + gap, PoolSettings.RADIUS + gap,
                -PoolSettings.LENGTH_TOTAL + PoolSettings.RADIUS + gap);
            corner.rotateOnAxis(new THREE.Vector3(0, 1, 0), -Math.PI / 2);
            return corner;
        }
    }

    generateFloorCurvature(type, mat, geo, length, center, gap) {
        let curvature = new THREE.Mesh(geo, mat);
        curvature.rotateOnAxis(new THREE.Vector3(1, 0, 0), Math.PI / 2);
        curvature.scale.y = length;
        if (type === 'left') {
            curvature.position.set(PoolSettings.RADIUS + gap, PoolSettings.RADIUS + gap, center);
            return curvature;
        } else if (type === 'right') {
            curvature.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI / 2);
            curvature.position.set(PoolSettings.WIDTH - PoolSettings.RADIUS - gap, PoolSettings.RADIUS + gap, center);
            return curvature;
        } else if (type === 'outlet') {
            curvature.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), -Math.PI / 2);
            curvature.position.set(center, PoolSettings.RADIUS + gap,
                -PoolSettings.LENGTH_TOTAL + PoolSettings.RADIUS + gap);
            return curvature;
        }
    }

    generateSectionText(type, mat, text, width, height, centerX, centerY) {
        let textGeo = new THREE.TextGeometry(text, {
            font: this.font,
            size: 1,
            height: RobotSettings.HEIGHT / 2 // Depth of letters
        });
        textGeo.computeBoundingBox();
        textGeo.computeBoundingSphere();
        textGeo = new THREE.BufferGeometry().fromGeometry(textGeo);
        let textMesh = new THREE.Mesh(textGeo, mat);

        let boundingWidth = Math.abs(textGeo.boundingBox.max.x - textGeo.boundingBox.min.x),
            boundingHeight = Math.abs(textGeo.boundingBox.max.y - textGeo.boundingBox.min.y);
        let xScale = width / (boundingWidth / boundingHeight) * 0.6,
            yScale = height / 5;
        textMesh.scale.x = xScale;
        textMesh.scale.y = yScale;

        // eslint-disable-next-line
        switch (type) {
            case 'floor':
                textMesh.rotateOnAxis(new THREE.Vector3(1, 0, 0), -Math.PI / 2);
                textMesh.position.set(centerX - (boundingWidth * xScale) / 2, 0.01,
                    centerY + (boundingHeight * yScale) / 2);
                break;
            case 'inlet':
                textMesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), -Math.PI);
                textMesh.position.set(centerX + (boundingWidth * xScale) / 2,
                    centerY - (boundingHeight * yScale) / 2,
                    -0.02 - PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH);
                break;
            case 'outlet':
                textMesh.position.set(centerX - (boundingWidth * xScale) / 2,
                    centerY - (boundingHeight * yScale) / 2,
                    0.02 - PoolSettings.LENGTH_TOTAL);
                break;
            case 'left wall':
                textMesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), -Math.PI / 2);
                textMesh.position.set(0.02,
                    centerY - (boundingHeight * yScale) / 2,
                    centerX - (boundingWidth * xScale) / 2);
                break;
            case 'right wall':
                textMesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), -Math.PI / 2);
                textMesh.position.set(PoolSettings.WIDTH - 0.01,
                    centerY - (boundingHeight * yScale) / 2,
                    centerX - (boundingWidth * xScale) / 2);
                break;
        }

        return textMesh;
    }

    //  p3------p4
    //  |       |
    //  |       |
    //  p1------p2
    getFormattedBorder(border, type) {
        let formattedBorder;
        let xy1 = border.p1.split(', '),
            xy2 = border.p2.split(', '),
            xy3 = border.p3.split(', '),
            xy4 = border.p4.split(', ');


        //the case of custom sections
        if (type === 'custom section') {
            formattedBorder = {
                p1: {
                    x: xy1[0],
                    y: xy1[1],
                    z: xy1[2]
                },
                p2: {
                    x: xy2[0],
                    y: xy2[1],
                    z: xy2[2]
                },
                p3: {
                    x: xy3[0],
                    y: xy3[1],
                    z: xy3[2]
                },
                p4: {
                    x: xy4[0],
                    y: xy4[1],
                    z: xy4[2]
                }
            };
        } else if (type === 'inlet') {
            formattedBorder = {
                p1: {
                    x: Number(xy1[0]),
                    y: Number(xy1[1]),
                    z: (-PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH)
                },
                p2: {
                    x: Number(xy2[0]),
                    y: Number(xy2[1]),
                    z: (-PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH)
                },
                p3: {
                    x: Number(xy3[0]),
                    y: Number(xy3[1]),
                    z: (-PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH)
                },
                p4: {
                    x: Number(xy4[0]),
                    y: Number(xy4[1]),
                    z: (-PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH)
                }

            };
        } else if (type === 'outlet') {
            formattedBorder = {
                p1: {
                    x: Number(xy1[0]),
                    y: Number(xy1[1]),
                    z: -PoolSettings.LENGTH_TOTAL
                },
                p2: {
                    x: Number(xy2[0]),
                    y: Number(xy2[1]),
                    z: -PoolSettings.LENGTH_TOTAL
                },
                p3: {
                    x: Number(xy3[0]),
                    y: Number(xy3[1]),
                    z: -PoolSettings.LENGTH_TOTAL
                },
                p4: {
                    x: Number(xy4[0]),
                    y: Number(xy4[1]),
                    z: -PoolSettings.LENGTH_TOTAL
                }

            };
        } else if (type === 'right wall') {
            formattedBorder = {
                p1: {
                    x: PoolSettings.WIDTH,
                    y: Number(xy1[1]),
                    z: -Number(xy1[0])
                },
                p2: {
                    x: PoolSettings.WIDTH,
                    y: Number(xy2[1]),
                    z: -Number(xy2[0])
                },
                p3: {
                    x: PoolSettings.WIDTH,
                    y: Number(xy3[1]),
                    z: -Number(xy3[0])
                },
                p4: {
                    x: PoolSettings.WIDTH,
                    y: Number(xy4[1]),
                    z: -Number(xy4[0])
                }

            };
        } else if (type === 'left wall') {
            formattedBorder = {
                p1: {
                    x: 0,
                    y: Number(xy1[1]),
                    z: -Number(xy1[0])
                },
                p2: {
                    x: 0,
                    y: Number(xy2[1]),
                    z: -Number(xy2[0])
                },
                p3: {
                    x: 0,
                    y: Number(xy3[1]),
                    z: -Number(xy3[0])
                },
                p4: {
                    x: 0,
                    y: Number(xy4[1]),
                    z: -Number(xy4[0])
                }

            };
        } else if (type === 'floor') {
            formattedBorder = {
                p1: {
                    x: Number(xy1[0]),
                    y: 0,
                    z: -Number(xy1[1])
                },
                p2: {
                    x: Number(xy2[0]),
                    y: 0,
                    z: -Number(xy2[1])
                },
                p3: {
                    x: Number(xy3[0]),
                    y: 0,
                    z: -Number(xy3[1])
                },
                p4: {
                    x: Number(xy4[0]),
                    y: 0,
                    z: -Number(xy4[1])
                }

            };
        }

        return formattedBorder;
    }

    getRandomColor() {
        let color = getRandomVal(0, 360),
            lightness = getRandomVal(30, 70);
        return [
            new THREE.Color('hsl(' + color.toString() + ', 40%, ' + lightness.toString() + '%)'), // Face color
            new THREE.Color('hsl(' + color.toString() + ', 40%, ' + (lightness - 15).toString() + '%)') // Font color
        ];
    }
}

export default Site;
