import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import PropTypes from 'prop-types';
import { Playback } from '../ThreeScene/componenets/OperationalValues';

import { styles } from './PlaybackContainerStyles';

class PlaybackContainer extends React.Component {

    static propTypes = {
        handleSliderChange: PropTypes.func,
        onPlayButtonClick: PropTypes.func,
    };

    static defaultPropTypes = {
        handleSliderChange: null,
        onPlayButtonClick: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            playbackValue1: Playback.defaultValue1,
            playbackValue2: Playback.defaultValue2,
            isPlaying: false,
        };
    }

    _marks = [
        {
            value: 0,
            label: '00:00',
        },
        {
            value: 1,
            label: '01:00',
        },
        {
            value: 2,
            label: '02:00',
        },
        {
            value: 3,
            label: '03:00',
        },
        {
            value: 4,
            label: '04:00',
        },
        {
            value: 5,
            label: '05:00',
        },
        {
            value: 6,
            label: '06:00',
        },
        {
            value: 7,
            label: '07:00',
        },
        {
            value: 8,
            label: '08:00',
        },
        {
            value: 9,
            label: '09:00',
        },
        {
            value: 10,
            label: '10:00',
        },
        {
            value: 11,
            label: '11:00',
        },
        {
            value: 12,
            label: '12:00',
        },
        {
            value: 13,
            label: '13:00',
        },
        {
            value: 14,
            label: '14:00',
        },
        {
            value: 15,
            label: '15:00',
        },
        {
            value: 16,
            label: '16:00',
        },
        {
            value: 17,
            label: '17:00',
        },
        {
            value: 18,
            label: '18:00',
        },
        {
            value: 19,
            label: '19:00',
        },
        {
            value: 20,
            label: '20:00',
        },
        {
            value: 21,
            label: '21:00',
        },
        {
            value: 22,
            label: '22:00',
        },
        {
            value: 23,
            label: '23:00',
        },
        {
            value: 24,
            label: '23:59',
        },
    ];

    handleSliderChange = (event, value) => {
        if (value[0] !== this.state.playbackValue1 && value[0] < this.state.playbackValue2) {
            this.setState({
                playbackValue1: value[0]
            }, () => {
                this.props.handleSliderChange(value[0], this.state.playbackValue2);
            });
        }
        if (value[1] !== this.state.playbackValue2 && value[1] > this.state.playbackValue1) {
            this.setState({
                playbackValue2: value[1]
            }, () => {
                this.props.handleSliderChange(this.state.playbackValue1, value[1]);
            });
        }
    };

    handlePause = () => {
        this.setState({
            isPlaying: !this.state.isPlaying,
        }, () => {
            this.props.onPlayButtonClick(this.state.isPlaying);
        });
    };

    handlePlay = () => {
        this.setState({
            isPlaying: !this.state.isPlaying,
        }, () => {
            this.props.onPlayButtonClick(this.state.isPlaying);
        });
    };

    renderSlider() {
        return (
            <Slider
                defaultValue={[Playback.defaultValue1, Playback.defaultValue2]}
                value={[this.state.playbackValue1, this.state.playbackValue2]}
                min={0}
                max={24}
                step={1}
                onChange={this.handleSliderChange}
                marks={this._marks}
            />
        );
    }

    renderIconsPanel() {
        return (
            <div>
                <IconButton
                    onClick={this.state.isPlaying ? this.handlePause : this.handlePlay}
                >
                    <Icon>
                        {this.state.isPlaying ? 'stop' : 'play_arrow'}
                    </Icon>
                </IconButton>
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                {this.renderSlider()}
                {this.renderIconsPanel()}
            </div>
        );
    }
}

export default withStyles(styles)(PlaybackContainer);