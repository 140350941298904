import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import GridOffIcon from '@material-ui/icons/GridOff';
import GridOnIcon from '@material-ui/icons/GridOn';
import WaterIcon from '@material-ui/icons/Waves';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';

import { GridSettings, WaterSettings } from '../ThreeScene/componenets/OperationalValues';
import { styles } from './ControlMenuContainerStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class ControlMenuContainer extends React.Component {
    static propTypes = {
        onButtonCameraClick: PropTypes.func,
        onButtonGridClick: PropTypes.func,
        onButtonWaterClick: PropTypes.func,
        onGridAdjust: PropTypes.func,
        onToggleWaves: PropTypes.func
    };

    static defaultProps = {
        onButtonCameraClick: null,
        onButtonGridClick: null,
        onButtonWaterClick: null,
        onGridAdjust: null,
        onToggleWaves: null
    };

    constructor(props) {
        super(props);

        this.state = {
            showWaves: WaterSettings.WAVES_VISIBLE,
            showGrid: GridSettings.VISIBLE,
            showWater: WaterSettings.WATER_VISIBLE,
            gridSize: GridSettings.DISTANCE,
            gridSizeInput: GridSettings.DISTANCE
        }
    }

    handleButtonCameraClick = () => {
        if (this.props.onButtonCameraClick) {
            this.props.onButtonCameraClick();
        }
    };

    handleButtonGridClick = () => {
        this.setState({
            showGrid: !this.state.showGrid,
        }, () => {
            if (this.props.onButtonGridClick) {
                this.props.onButtonGridClick();
            }
        });
    };

    handleButtonWaterClick = () => {
        this.setState({
            showWater: !this.state.showWater,
        }, () => {
            if (this.props.onButtonWaterClick) {
                this.props.onButtonWaterClick();
            }
        });
    };

    handleGridValueChange = (event, value) => {
        this.setState({
            gridSize: value,
            gridSizeInput: value,
        }, () => {
            if (this.props.onGridAdjust) {
                this.props.onGridAdjust(value);
            }
        });
    };

    handleToggleWaves = () => {
        this.setState({
            showWaves: !this.state.showWaves,
        }, () => {
            if (this.props.onToggleWaves) {
                this.props.onToggleWaves();
            }
        });
    };

    onChangeGridsizeInputfield = (event) => {
        let input = event.target.value;
        if (input !== '') {
            input = input > 0 ? input : 1;
            input = input < 10 ? input : 10;
        }
        this.setState({
            gridSize: parseInt(input),
            gridSizeInput: input
        }, () => {
            if (this.props.onGridAdjust) {
                this.props.onGridAdjust(this.state.gridSize);
            }
        });
    };

    renderCameraButton() {
        return (
            <Tooltip
                title={<FormattedMessage id="CONTROLMENUCONTAINER.RESET_CAMERA_LABEL" />}
                placement="top"
            >
                <IconButton
                    onClick={this.handleButtonCameraClick}
                >
                    <CameraAltIcon />
                </IconButton>
            </Tooltip>
        );
    }

    renderGridButton() {
        return (
            <div>
                <Tooltip
                    title={this.state.showGrid ? <FormattedMessage id="CONTROLMENUCONTAINER.HIDE_GRID_LABEL" /> :
                        <FormattedMessage id="CONTROLMENUCONTAINER.SHOW_GRID_LABEL" />}
                    placement="top"
                >
                    <IconButton
                        onClick={this.handleButtonGridClick}
                    >
                        {this.state.showGrid ? <GridOffIcon /> : <GridOnIcon />}
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

    renderWaterButton() {
        const showWaterLabel = <FormattedMessage id="CONTROLMENUCONTAINER.SHOW_WATER_LABEL" />;
        const hideWaterLabel = <FormattedMessage id="CONTROLMENUCONTAINER.HIDE_WATER_LABEL" />;
        return (
            <Tooltip
                title={this.state.showWater ? hideWaterLabel : showWaterLabel}
                placement="top"
            >
                <IconButton
                    onClick={this.handleButtonWaterClick}
                >
                    <WaterIcon />
                </IconButton>
            </Tooltip>
        );
    }

    renderGridRange() {
        const { classes } = this.props;

        if (this.state.showGrid) {
            return (
                <div className={classes.alterGridPanel}>
                    <Typography><FormattedMessage id='CONTROLMENUCONTAINER.ALTER_GRID_SIZE' /></Typography>
                    <div
                        className={classes.gridRange}
                    >
                        <Slider
                            className={classes.gridSizeSlider}
                            value={this.state.gridSize}
                            aria-labelledby="discrete-slider"
                            onChange={this.handleGridValueChange}
                            step={1}
                            min={1}
                            max={10}
                        />
                        <div className={classes.gridInputContainer}>
                            <TextField
                                className={classes.gridInput}
                                value={this.state.gridSizeInput}
                                type="number"
                                onChange={this.onChangeGridsizeInputfield}
                            />
                            <Typography>m<sup>2</sup></Typography>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    renderWaveCheck() {
        return (
            <div>
                <FormControlLabel
                    control={<Checkbox checked={this.state.showWaves} onChange={this.handleToggleWaves} />}
                    label={<FormattedMessage id="CONTROLMENUCONTAINER.SHOW_WAVES_LABEL" />}
                />
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.sceneExpansionContainer}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography><FormattedMessage id="CONTROLMENUCONTAINER.CONTROL_MENU" /></Typography>
                    </AccordionSummary>
                    <div className={classes.buttonBar}>
                        <div className={classes.justAround}>
                            {this.renderCameraButton()}
                            {this.renderGridButton()}
                            {this.renderWaterButton()}
                            {WaterSettings.WATER_VISIBLE ? this.renderWaveCheck() : null}
                        </div>
                        {this.renderGridRange()}
                    </div>
                </Accordion>
            </div>
        );
    }
}

export default withStyles(styles)(ControlMenuContainer);