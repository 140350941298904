import React from 'react';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Avatar from '@material-ui/core/Avatar';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

import { routes } from '../../../config';
import { auth } from '../../../firebase/Firebase';
import { setFeedbackMessage, toggleFeedbackHidden } from '../../../redux/feedback/feedbackActions';
import { styles } from './LoginStyles';

import { selectCurrentUser } from '../../../redux/user/userSelector';

class LoginForm extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        loadingUser: PropTypes.bool,
    };

    static defaultProps = {
        loadingUser: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            clientId: '',
            password: '',
            showPassword: false,
        }
    }

    handleChange = (event) => {
        this.setState({
            clientId: event.target.value,
        });
    };

    handlePasswordChange = (event) => {
        this.setState({
            password: event.target.value,
        });
    };

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        });
    };

    handleRedirect = () => {
        if (this.props.currentUser) {
            const clientId = this.props.currentUser.id;
            sessionStorage.setItem('route', `/${clientId}` + routes.OVERVIEW);
            this.props.history.push(`/${clientId}` + routes.OVERVIEW);
        }
    };

    handleLoginClick = async event => {
        event.preventDefault();
        const { toggleFeedbackHidden, setFeedbackMessage } = this.props;
        const { clientId, password } = this.state;
        const email = clientId + '@netrobot.com';
        try {
            const res = await auth.signInWithEmailAndPassword(email, password);
            if (res.hasOwnProperty('user')) {
                this.props.history.push(`/${res.user.uid}` + routes.OVERVIEW);
            }
        } catch (error) {
            toggleFeedbackHidden();
            setFeedbackMessage(<FormattedMessage id="LOGIN.LOGIN_FAILED" />);
        }
    };

    renderButtonContent() {
        if (this.props.loadingUser) {
            return (
                <div className={this.props.classes.btnContainer}>
                    <CircularProgress
                        color="secondary"
                        size={28}
                    />
                    <FormattedMessage id="LOGIN.LOADING_USER" />
                </div>
            );
        } else {
            if (this.props.currentUser) {
                return <FormattedMessage id="LOGIN.REDIRECT" />;
            } else {
                return <FormattedMessage id="LOGIN.LOGIN" />;
            }
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Slide
                direction="left"
                in={true}
                mountOnEnter={true}
                unmountOnExit={true}
                timeout={500}
            >
                <div className={classes.root}>
                    <div className={classes.loginHeader}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <div>Log in</div>
                    </div>
                    <FormControl className={classes.formControl}>
                        <InputLabel
                            htmlFor="adornment-password"
                            className={classes.label}
                        >
                            <FormattedMessage id="LOGIN.CLIENT_ID" />
                        </InputLabel>
                        <Input
                            type="text"
                            value={this.state.clientId}
                            className={classes.input}
                            onChange={this.handleChange}
                            disabled={this.props.currentUser !== null}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel
                            htmlFor="adornment-password"
                            className={classes.label}
                        >
                            <FormattedMessage id="LOGIN.PASSWORD" />
                        </InputLabel>
                        <Input
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.password}
                            className={classes.input}
                            onChange={this.handlePasswordChange}
                            disabled={this.props.currentUser !== null}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        className={classes.input}
                                        aria-label="Toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        disabled={this.props.currentUser !== null}
                                    >
                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <div className={classes.loginButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            onClick={this.props.currentUser ? this.handleRedirect : this.handleLoginClick}
                        >
                            {this.renderButtonContent()}
                        </Button>
                    </div>
                </div>
            </Slide>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    toggleFeedbackHidden: () => dispatch(toggleFeedbackHidden()),
    setFeedbackMessage: message => dispatch(setFeedbackMessage(message)),
});
const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginForm)));
