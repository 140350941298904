import { robotActionTypes } from './robotTypes';

export const setCurrentRobot = robot => ({
    type: robotActionTypes.SET_CURRENT_ROBOT,
    payload: robot,
});

export const setRobots = robots => ({
    type: robotActionTypes.SET_ROBOTS,
    payload: robots,
});

export const setSimulationRobots = robots => ({
    type: robotActionTypes.SET_SIMULATION_ROBOTS,
    payload: robots,
});

export const selectRobot = robot => ({
    type: robotActionTypes.SELECT_ROBOT,
    payload: robot,
});


export const unselectRobot = robot => ({
    type: robotActionTypes.UNSELECT_ROBOT,
    payload: robot,
});

export const unselectAllRobots = () => ({
    type: robotActionTypes.UNSELECT_ALL_ROBOTS,
});


export const toggleSettingsDrawer = () => ({
    type: robotActionTypes.TOGGLE_CURRENT_ROBOT_DRAWER,
});

export const toggleShowRobot = robot => ({
    type: robotActionTypes.TOGGLE_CURRENT_ROBOT_SHOW_ROBOT,
    payload: robot,
});

export const toggleShowTrail = robot => ({
    type: robotActionTypes.TOGGLE_CURRENT_ROBOT_SHOW_TRAIL,
    payload: robot,
});

export const toggleShowPath = robot => ({
    type: robotActionTypes.TOGGLE_CURRENT_ROBOT_SHOW_PATH,
    payload: robot
});

export const toggleShowSection = robot => ({
    type: robotActionTypes.TOGGLE_CURRENT_ROBOT_SHOW_SECTION,
    payload: robot
});

export const toggleRobotMode = (isLiveMode) => ({
    type: robotActionTypes.TOGGLE_ROBOT_MODE,
    payload: isLiveMode
});

export const setLiveRobotSection = section => ({
    type: robotActionTypes.SET_LIVE_ROBOT_SECTION,
    payload: section
});

export const setSimulatedRobotSection = section => ({
    type: robotActionTypes.SET_SIMULATED_ROBOT_SECTION,
    payload: section
});

export const addSimulationRobotStreamer = robot => ({
    type: robotActionTypes.ADD_SIMULATION_ROBOT_STREAMER,
    payload: robot,
});

export const removeSimulationRobotStreamer = robot => ({
    type: robotActionTypes.REMOVE_SIMULATION_ROBOT_STREAMER,
    payload: robot,
});

export const updateRobotPathLength = update => ({
    type: robotActionTypes.UPDATE_ROBOT_PATH_LENGTH,
    payload: update,
});

export const updateRobotTrailLength = update => ({
    type: robotActionTypes.UPDATE_ROBOT_TRAIL_LENGTH,
    payload: update,
});

// TODO replace with something more sensible. only for demo
export const setRobotPath = path => ({
    type: robotActionTypes.SET_ROBOT_PATH,
    payload: path,
});
