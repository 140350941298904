import { modeActionTypes } from './modeTypes';
import { Playback } from '../../assets/components/ThreeScene/componenets/OperationalValues';


const INITIAL_STATE = {
    isLive: true,
    joyConnected: false,
    isSimulationPlaybackMode: false,
    fromTime: Playback.defaultValue1,
    toTime: Playback.defaultValue2,
};

const modeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case modeActionTypes.TOGGLE_IS_LIVE:
            return {
                ...state,
                isLive: !state.isLive
            };
        case modeActionTypes.TOGGLE_JOYSTICK_CONNECTED:
            return {
                ...state,
                joyConnected: !state.joyConnected,
            }
        case modeActionTypes.TOGGLE_SIMULATION_PLAYBACK:
            return {
                ...state,
                isSimulationPlaybackMode: !state.isSimulationPlaybackMode,
                fromTime: Playback.value1,
                toTime: Playback.value2,
            };
        default:
            return state
    }
};

export default modeReducer;