import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    selectCurrentlyDrivingRobot,
    selectCurrentPath,
    selectCurrentRobot,
    selectRobots,
    selectSelectedRobots,
    selectSimulationRobots,
    selectSimulationRobotStreamers
} from '../../../redux/robot/robotSelector';
import { selectCurrentSite } from '../../../redux/site/siteSelector';
import * as THREE from 'three';
import * as YUKA from 'yuka';
import PoolGrid from './componenets/PoolGrid';
import SectionTool from './componenets/SectionTool';
import ControlMenuContainer from '../ControlMenuContainer/ControlMenuContainer';
import * as HELPER from './Helpers';
import {
    GridSettings,
    Playback,
    PoolSettings,
    RobotCameraSettings,
    SimulationRobotSettings,
    WaterSettings
} from './componenets/OperationalValues';
import withStyles from '@material-ui/core/styles/withStyles';
import { styles } from './ThreeSceneStyles';
import {
    selectCanSend,
    selectCustomSectionName,
    selectIsClearing,
    selectIsDrawing,
    selectIsSendingSection
} from '../../../redux/section/sectionSelector';
import {
    resetCustomSectionName,
    toggleIsClearing,
    toggleIsDrawing,
    toggleSectionCanSend,
    toggleSendCustomSection
} from '../../../redux/section/sectionActions';
import { selectRobot, setRobots, setSimulationRobots } from '../../../redux/robot/robotActions';
import { setCurrentSite } from '../../../redux/site/siteActions';
import * as CommonUtils from './CommonUtils';
import { createSnackMessage } from './CommonUtils';
import InformationMenuContainer from '../InformationMenuContainer/InformationMenuContainer';
import SectionToolStatusTypes from './componenets/SectionToolStatusTypes';
import { FormattedMessage } from 'react-intl';
import { setFeedbackMessage, toggleFeedbackHidden } from '../../../redux/feedback/feedbackActions';
import { firestore } from '../../../firebase/Firebase';
import { selectCurrentUser } from '../../../redux/user/userSelector';
import {
    selectIsLive,
    selectPlaybackModeFrom,
    selectPlaybackModeTo,
    selectSimulationPlaybackMode
} from '../../../redux/mode/modeSelector';
import 'joypad.js/dist/joypad';

class SimulationThreeScene extends React.Component {
    constructor(props) {
        super(props);

        HELPER.constructSimulation(this);

        HELPER.bindSimulation(this);

        this.db = firestore;
    }

    componentDidMount() {
        CommonUtils.componentDidMount(this);

        this.scene.fog = new THREE.FogExp2(WaterSettings.COLOR_WATER, 0.000025);
        this.entityManager = new YUKA.EntityManager();

        // http://www.ardusub.com/getting-started/initial-setup.html
        // https://github.com/ArunMichaelDsouza/joypad.js
        window.joypad.set({
            axisMovementThreshold: 0.3,
            vibration: {
                startDelay: 100,
                duration: 500,
                weakMagnitude: 1,
                strongMagnitude: 1
            }
        });
        window.joypad.on('connect', e => {
            const id = e.gamepad.id.toString().toLowerCase();
            createSnackMessage(this, `${id} CONNECTED!`);
        });
        window.joypad.on('disconnect', e => {
            const id = e.gamepad.id.toString().toLowerCase();
            createSnackMessage(this, `${id} DISCONNECTED!`);
        });
        window.joypad.on('axis_move', e => {
            this.handleAxisMove(e);
        });
        window.joypad.on('button_press', e => {
            this.handleButtonPress(e.detail.buttonName, e.detail.button.value, true);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.theme !== this.props.theme && this.scene !== null) {
            this.scene.background = new THREE.Color(this.props.theme.customPalette.threeBackground);
        }

        if (this.props.isSimulationPlaybackMode !== prevProps.isSimulationPlaybackMode) {
            if (this.props.isSimulationPlaybackMode)
                HELPER.getAllPathsInInterval(this.props.fromTime, this.props.toTime, this);
            this.isHandlingPlayback = true;
            this.handleFollowRobotPathPlayback(this.props.isSimulationPlaybackMode)
        }

        if (!this.props.isSendingCustomSection) {
            this.props.simulationRobots.forEach(robot => {
                let prevRobot = prevProps.simulationRobots.filter(sr => sr.modelId === robot.modelId);
                if (prevRobot.length > 0) {
                    prevRobot = prevRobot[0];
                    if (prevRobot.section === null && robot.section !== null) {
                        let section = this.props.site.sections.filter(sec => sec.name === robot.section)[0];
                        let formattedBorder;
                        let isCustomSection = false;

                        if (section.name.toLowerCase() === 'inlet 1' || section.name.toLowerCase() === 'inlet 2') {
                            formattedBorder = CommonUtils.getFormattedBorder(section.border, 'inlet');
                        } else if (section.name.toLowerCase() === 'outlet 1' || section.name.toLowerCase() === 'outlet 2') {
                            formattedBorder = CommonUtils.getFormattedBorder(section.border, 'outlet');
                        } else if (section.name.toLowerCase() === 'right wall 1' || section.name.toLowerCase() === 'right wall 2') {
                            formattedBorder = CommonUtils.getFormattedBorder(section.border, 'right wall');
                        } else if (section.name.toLowerCase() === 'left wall 1' || section.name.toLowerCase() === 'left wall 2') {
                            formattedBorder = CommonUtils.getFormattedBorder(section.border, 'left wall');
                        } else if (section.name.toLowerCase() === 'floor 1' || section.name.toLowerCase() === 'floor 2') {
                            formattedBorder = CommonUtils.getFormattedBorder(section.border, 'floor');
                        }
                        //case of custom section
                        else {
                            formattedBorder = CommonUtils.getFormattedBorder(section.border, 'custom section');
                            isCustomSection = true;
                        }

                        let { p1, p2, p3, p4 } = formattedBorder;
                        p1 = new THREE.Vector3(parseFloat(p1.x), parseFloat(p1.y), parseFloat(p1.z));
                        p2 = new THREE.Vector3(parseFloat(p2.x), parseFloat(p2.y), parseFloat(p2.z));
                        p3 = new THREE.Vector3(parseFloat(p3.x), parseFloat(p3.y), parseFloat(p3.z));
                        p4 = new THREE.Vector3(parseFloat(p4.x), parseFloat(p4.y), parseFloat(p4.z));
                        isCustomSection
                            ? this.sectionTool.loadCustomSectionPath(p1, p2, p3, p4)
                            : this.sectionTool.loadCustomSectionPath(p2, p1, p3, p4);
                    }
                }

            });
        }

        if (prevProps.isDrawing !== this.props.isDrawing) {
            CommonUtils.onDrawClick(this);
        }
        if (prevProps.isClearing !== this.props.isClearing) {
            CommonUtils.onClearClick(this);
        }
        if (this.props.isSendingCustomSection) {
            CommonUtils.sendCustomSection(this);
            this.props.toggleSendCustomSection(null);
        }
        if (this.props.robots !== prevProps.robots) {
            this.robotUpdateQueue.push(this.props.robots);
        }
        if (this.props.site !== prevProps.site) {
            this.siteUpdateQueue.push(this.props.site);
        }

        if (this.props.selectedRobots.length === 0 || prevProps.selectedRobots.length === 0) {
            CommonUtils.onWindowResize(this);
        }

        if (this.props.simulationRobots !== prevProps.simulationRobots && this.props.simulationRobots.length > 0) {
            CommonUtils.updateRobotVisibility(this, this.props.simulationRobots, prevProps.simulationRobots);
        }

        if (prevProps.simulationRobotStreamers !== this.props.simulationRobotStreamers) {
            this.selectedRobotUpdateQueue.push(this.robotGroup);
        }

        if (prevProps.currentPath !== this.props.currentPath) {
            this.handleFollowRobotPathLive();
        }
    }

    componentWillUnmount() {
        CommonUtils.componentWillUnmount(this);
    }

    generateRobot(i, x, y, z, isPlaybackRobot) {
        let newRobot = isPlaybackRobot ? this.assetLoader.robotPlaybackPrototype[i] : this.assetLoader.robotPrototype[i];
        newRobot.name = {
            IP: isPlaybackRobot ? 'playback_' + SimulationRobotSettings.IP_ADDRESSES[i] : SimulationRobotSettings.IP_ADDRESSES[i],
            NAME: isPlaybackRobot ? 'playback_' + SimulationRobotSettings.NAMES[i] : SimulationRobotSettings.NAMES[i]
        };
        newRobot.position.set(x, y, z);
        newRobot.rotateY(Math.PI);
        newRobot.homeLook = { x: x, y: y, z: 0 };
        newRobot.homePos = { x: x, y: y, z: z };
        newRobot.isPlayBackRobot = isPlaybackRobot;

        newRobot.children[0].geometry.translate(0, 0, 0);
        newRobot.children[1].geometry.translate(0, 0, 0);
        newRobot.children[0].geometry.computeBoundingBox();
        newRobot.children[1].geometry.computeBoundingBox();

        let arrowGeo = new THREE.ConeBufferGeometry(0.35, 2.25, 15, 1, false);
        let arrowMat = new THREE.MeshBasicMaterial({
            color: isPlaybackRobot ? 0x888888 : 0x000000,
            transparent: isPlaybackRobot,
            opacity: Playback.OPACITY
        });
        let arrow = new THREE.Mesh(arrowGeo, arrowMat);
        arrow.name = 'robotArrow';
        newRobot.add(arrow);
        arrow.rotateX(Math.PI / 2);
        arrow.geometry.translate(0, 3.37, -1);

        this.robotGroup.add(newRobot);
    }

    generateRobotVisualization() {
        this.robotGroup = new THREE.Group();
        this.robotGroup.name = 'robotGroup';
        for (let i = 0, x = (PoolSettings.WIDTH / 2) + (SimulationRobotSettings.NO_OF_CUSTOMER_ROBOTS - 1);
            i < SimulationRobotSettings.NO_OF_CUSTOMER_ROBOTS;
            i++, x -= 2) {
            this.generateRobot(i, x, PoolSettings.HEIGHT + 0.5, -PoolSettings.LENGTH_TOTAL - 3, true);
        }
        for (let i = 0, x = (PoolSettings.WIDTH / 2) + (SimulationRobotSettings.NO_OF_CUSTOMER_ROBOTS - 1);
            i < SimulationRobotSettings.NO_OF_CUSTOMER_ROBOTS;
            i++, x -= 2) {
            this.generateRobot(i, x, PoolSettings.HEIGHT + 0.5, -PoolSettings.LENGTH_TOTAL - 1, false);
        }
        this.props.setSimulationRobots(this.robotGroup.children.map(child => {
            return {
                modelId: child.name.NAME,
                ipAddress: child.name.IP,
                isPlayBackRobot: child.isPlayBackRobot,
                section: null,
                showRobot: true,
                showTrail: true,
                showPath: true,
                showSection: true,
                pathLength: 100,
                trailLength: 50,
                isRovMode: false,
            }
        }));
        this.scene.add(this.robotGroup);
    }

    renderAnimation() {
        this.deltaTime = this.clock.getDelta();
        this.time += this.deltaTime;
        if (!this.state.isLoadingElements) {
            // Handle water-update
            if (WaterSettings.WATER_VISIBLE && WaterSettings.IS_LOCATION_SET) {
                if (WaterSettings.WAVES_VISIBLE) this.water.updateSurfaceWaves(this.deltaTime); // Wave animation
                if (Math.round(this.time) % WaterSettings.FREQUENCY === 0 && Math.round(this.time) > this.oldTime) {
                    // Get the latest water-level from kartverket, update based on timeinterval set in OperationalValues
                    this.oldTime = Math.round(this.time);
                    CommonUtils.updateWater(this);
                }
                if (this.water.topSide)
                    this.water.topSide.position.y =
                        this.water.obs + WaterSettings.AVG_DEPTH - 0.15 + (Math.sin(this.time) / 15);
            }

            if (!this.isHandlingSiteUpdate && this.siteUpdateQueue.length > 0) {
                this.isHandlingSiteUpdate = true;
                CommonUtils.handleSiteUpdate(this.siteUpdateQueue.shift(), this);
            }

            if (!this.isHandlingSelectedRobotUpdate && this.selectedRobotUpdateQueue.length > 0) {
                this.isHandlingSelectedRobotUpdate = true;
                this.handleSelectedRobotUpdate(this.selectedRobotUpdateQueue.shift());
            }

            if (process.env.NODE_ENV === 'development' && this.stats !== null) this.stats.update();

            // Paths/trails
            this.entityManager.entities.forEach(e => {
                if (e.steering.behaviors[0].path.finished()) {
                    let robot = this.scene.getObjectByName('robotGroup').children.filter(r => r.name.NAME === e.name)[0];
                    if (robot.isPlayBackRobot) {
                        robot.children[0].material.color = new THREE.Color(
                            Math.abs(127 / 255 * Math.cos(2.5 * this.time + (Math.PI / 2))),
                            1 - Math.abs(127 / 255 * Math.sin(2.5 * this.time)),
                            Math.abs(127 / 255 * Math.cos(2.5 * this.time + (Math.PI / 2)))
                        );
                        robot.children[2].material.color = new THREE.Color(
                            Math.abs(127 / 255 * Math.cos(2.5 * this.time + (Math.PI / 2))),
                            1 - Math.abs(127 / 255 * Math.sin(2.5 * this.time)),
                            Math.abs(127 / 255 * Math.cos(2.5 * this.time + (Math.PI / 2)))
                        );
                    } else {
                        robot.children[0].material.color = new THREE.Color(0, Math.abs(Math.sin(2.5 * this.time)), 0);
                        robot.children[2].material.color = new THREE.Color(0, Math.abs(Math.sin(2.5 * this.time)), 0);
                    }
                }
                const ePath = e.steering.behaviors[0].path;
                const pathLength = this.props.simulationRobots.filter(r => r.modelId === e.name)[0].pathLength / 100;
                const trailLength = this.props.simulationRobots.filter(r => r.modelId === e.name)[0].trailLength / 100;

                let trailStartIndex = Math.floor(
                    ePath._index - (ePath._index * trailLength)
                );
                trailStartIndex = trailStartIndex > 0 ? trailStartIndex : 0;
                if (e.name in this.robotTrailLines) {
                    let waypoints = ePath._waypoints.slice(trailStartIndex, ePath._index + 1);
                    waypoints.push(e.position);
                    let points = [];
                    waypoints.forEach(wp => {
                        points.push(wp.x);
                        points.push(wp.y);
                        points.push(wp.z);
                    });
                    this.robotTrailLines[e.name].geometry.setPositions(points);
                } else {
                    //Trail generated to fullsize for first frame update, as one cannot resize buffer of buffergeometry
                    let robotTrail = this.sectionTool.createLine(
                        ePath._waypoints.slice(0, ePath._waypoints.length),
                        e.name + '_trail',
                        e.name.includes('playback_') ? '#7f7f7f' : '#000000'
                    );
                    robotTrail.visible = this.props.simulationRobots.filter(r => r.modelId === e.name)[0].showTrail;
                    this.robotTrailLines[e.name] = robotTrail;
                    this.scene.add(robotTrail);
                }

                let pathEndIndex = Math.floor(
                    ePath._index + ((ePath._waypoints.length - ePath._index) * pathLength)
                );
                pathEndIndex = pathEndIndex > 0 ? pathEndIndex : 0;
                if (e.name in this.robotPathLines) {
                    let waypoints = ePath._waypoints.slice(ePath._index, pathEndIndex + 1);
                    waypoints.unshift(e.position);
                    let points = [];
                    waypoints.forEach(wp => {
                        points.push(wp.x);
                        points.push(wp.y);
                        points.push(wp.z);
                    });
                    this.robotPathLines[e.name].geometry.setPositions(points);
                } else {
                    //path generated to fullsize for first frame update, as one cannot resize buffer of buffergeometry
                    let robotPath = this.sectionTool
                        .createLine(
                            ePath._waypoints.slice(0, ePath._waypoints.length),
                            e.name + '_trail',
                            e.name.includes('playback_') ? '#7f7f7f' : '#000000'
                        );
                    robotPath.visible = this.props.simulationRobots.filter(r => r.modelId === e.name)[0].showPath;
                    this.robotPathLines[e.name] = robotPath;
                    this.scene.add(robotPath);
                }

                this.robotPathLines[e.name].geometry.verticesNeedUpdate = true;
                this.robotTrailLines[e.name].geometry.verticesNeedUpdate = true;
            });

            this.entityManager.update(this.deltaTime);

            // Update up-vector
            if (this.entityManager.entities.length > 0) {
                this.entityManager.entities.forEach((entity, i) => {
                    // 4, 5, 6 = the world x-, y-, and z-components of the robots local y-axis
                    // https://threejs.org/docs/#api/en/math/Matrix4.extractBasis
                    // eslint-disable-next-line default-case
                    switch (entity.sectionType) {
                        case 'left':
                            if (this.entityManager.getEntityByName(entity.name).worldMatrix.elements[4] < 0) {
                                this.entityManager.getEntityByName(entity.name).up.x *= -1;
                                this.entityManager.update(this.deltaTime);
                            }
                            break;
                        case 'right':
                            if (this.entityManager.getEntityByName(entity.name).worldMatrix.elements[4] > 0) {
                                this.entityManager.getEntityByName(entity.name).up.x *= -1;
                                this.entityManager.update(this.deltaTime);
                            }
                            break;
                        case 'inlet':
                            if (this.entityManager.getEntityByName(entity.name).worldMatrix.elements[6] > 0) {
                                this.entityManager.getEntityByName(entity.name).up.x *= -1;
                                this.entityManager.update(this.deltaTime);
                            }
                            break;
                        case 'outlet':
                            if (this.entityManager.getEntityByName(entity.name).worldMatrix.elements[6] < 0) {
                                this.entityManager.getEntityByName(entity.name).up.x *= -1;
                                this.entityManager.update(this.deltaTime);
                            }
                            break;
                    }
                });
            }

            // ROV/Xbox-controller
            if (window.joypad.instances[0]) {
                for (let i = 0; i < window.joypad.instances[0].buttons.length; i++) {
                    if (window.joypad.instances[0].buttons[i].pressed) {
                        if (this.lastGameButton['button_' + i]) {
                            if (this.time - this.lastGameButton['button_' + i].time >= 0.3) {
                                this.handleButtonPress('button_' + i, window.joypad.instances[0].buttons[i].value, false);
                            }
                        }
                    }
                }
            }

            this.scene.fog.density = 0.000001;
            this.renderer.render(this.scene, this.camera);

            // render robot cameras
            for (let robotName in this.robotRenderers) {
                if (this.robotRenderers.hasOwnProperty(robotName)) {
                    if (this.entityManager.getEntityByName(robotName)) {
                        this.robotCameras[robotName].position.copy(this.entityManager.getEntityByName(robotName).position);
                        this.robotCameras[robotName].translateZ(RobotCameraSettings.OFFSET_Z);
                        this.robotCameras[robotName].translateY(RobotCameraSettings.OFFSET_Y);
                        this.robotCameras[robotName].quaternion.copy(this.entityManager.getEntityByName(robotName).rotation);

                        if (WaterSettings.WATER_VISIBLE &&
                            this.entityManager.getEntityByName(robotName).position.y < this.water.obs + WaterSettings.AVG_DEPTH) {
                            this.scene.fog.density = 0.02;
                        }
                    } else {
                        this.robotCameras[robotName].position.copy(this.scene.getObjectById(this.robotCameras[robotName].parentRobotId).position);
                        this.robotCameras[robotName].translateZ(RobotCameraSettings.OFFSET_Z);
                        this.robotCameras[robotName].translateY(RobotCameraSettings.OFFSET_Y);
                        this.robotCameras[robotName].quaternion.copy(this.scene.getObjectById(this.robotCameras[robotName].parentRobotId).quaternion);
                        if (this.props.simulationRobots.filter(r => r.modelId === robotName)[0].isRovMode)
                            this.robotCameras[robotName].rotateX(this.rovCameraTilt);

                        if (WaterSettings.WATER_VISIBLE &&
                            this.scene.getObjectById(this.robotCameras[robotName].parentRobotId).position.y < this.water.obs + WaterSettings.AVG_DEPTH) {
                            this.scene.fog.density = 0.02;
                        }
                    }
                    this.scene.getObjectById(this.robotCameras[robotName].parentRobotId).children[2].visible = false;
                    this.robotCameras[robotName].rotateY(Math.PI);
                    this.robotRenderers[robotName].render(this.scene, this.robotCameras[robotName]);
                    this.scene.getObjectById(this.robotCameras[robotName].parentRobotId).children[2].visible = true;
                }
            }
        }
    }

    // TODO implement vibration if user hits pool
    // TODO Apply easing in/out?
    // https://easings.net/
    // https://gist.github.com/gre/1650294
    handleAxisMove(e) {
        if (!this.state.isLoadingElements) {
            const movementFactor = 0.02;

            let robotName = this.props.currentlyDrivingRobot === null ? null : this.props.currentlyDrivingRobot.modelId;
            if (robotName === null)
                return;

            let robot = this.scene.getObjectByName('robotGroup').children.filter(c => c.name.NAME === robotName)[0];

            switch (e.detail.stickMoved) {
                case 'left_stick':
                    handleLeftStick(e.detail.directionOfMovement, e.detail.axisMovementValue);
                    break;
                case 'right_stick':
                    handleRightStick(e.detail.directionOfMovement, e.detail.axisMovementValue);
                    break;
                default:
                    return;
            }

            function handleLeftStick(direction, value) {
                switch (direction) {
                    case 'top':
                    case 'bottom':
                        robot.translateZ(-value * movementFactor);
                        break;
                    case 'left':
                    case 'right':
                        robot.translateX(-value * movementFactor);
                        break;
                    default:
                        return;
                }
            }

            function handleRightStick(direction, value) {
                switch (direction) {
                    case 'top':
                    case 'bottom':
                        robot.translateY(-value * movementFactor);
                        break;
                    case 'left':
                    case 'right':
                        robot.rotateY(-value * movementFactor);
                        break;
                    default:
                        return;
                }
            }
        }
    }

    // TODO Apply easing in/out?
    // https://easings.net/
    // https://gist.github.com/gre/1650294
    handleButtonPress(name, value, updateTime) {
        if (!this.state.isLoadingElements) {
            const movementFactor = 0.01;

            let robotName = this.props.currentlyDrivingRobot === null ? null : this.props.currentlyDrivingRobot.modelId;
            if (robotName === null)
                return;

            let robot = this.scene.getObjectByName('robotGroup').children.filter(c => c.name.NAME === robotName)[0];

            // used in renderAnimation
            if (updateTime) {
                this.lastGameButton[name] = {
                    name: name,
                    time: this.time
                };
            }

            switch (name) {
                case 'button_0': // A (add/remove axesHelper on robot)
                    if (updateTime) {
                        if (robot.getObjectByName('rovAxesHelper')) {
                            robot.remove(robot.getObjectByName('rovAxesHelper'));
                        } else {
                            let helper = new THREE.AxesHelper(8);
                            helper.name = 'rovAxesHelper';
                            robot.add(helper);
                        }
                    }
                    break;
                case 'button_1': // B (reset camera tilt, robot rotation and set position to home)
                    if (updateTime) {
                        robot.rotation.set(0, 0, 0);
                        robot.position.set(robot.homePos.x, robot.homePos.y, robot.homePos.z);
                        this.rovCameraTilt = 0;
                    }
                    break;
                case 'button_4': //shoulder left front button (add camera helper)
                    if (updateTime && this.robotCameras[robot.name.NAME] && !this.scene.getObjectByName('rovCameraHelper')) {
                        let cameraHelper = new THREE.CameraHelper(this.robotCameras[robot.name.NAME]);
                        cameraHelper.name = 'rovCameraHelper';
                        this.scene.add(cameraHelper);
                        this.robotCameraHelpers[robot.name.NAME] = cameraHelper;
                    }
                    break;
                case 'button_5': //shoulder right front button (remove camera helper)
                    if (updateTime && this.scene.getObjectByName('rovCameraHelper')) {
                        this.scene.remove(this.scene.getObjectByName('rovCameraHelper'));
                        delete this.robotCameraHelpers[robot.name.NAME];
                    }
                    break;
                //https://bluerobotics.com/store/sensors-sonars-cameras/cameras/camera-tilt-mount/
                case 'button_6': //shoulder left back button (camera tilt up)
                    if (this.rovCameraTilt > -Math.PI * 0.25)
                        this.rovCameraTilt -= Math.PI * value * 0.0025;
                    break;
                case 'button_7': //shoulder right back button (camera tilt down)
                    if (this.rovCameraTilt < Math.PI * 0.25)
                        this.rovCameraTilt += Math.PI * value * 0.0025;
                    break;
                case 'button_8': //back (reset camera tilt)
                    if (updateTime)
                        this.rovCameraTilt = 0;
                    break;
                case 'button_9': //start (reset robot rotation)
                    if (updateTime)
                        robot.rotation.set(0, robot.rotation.y, 0);
                    break;
                case 'button_12': //up, left cluster (pitch)
                    robot.rotateX(value * movementFactor);
                    break;
                case 'button_13': //down, left cluster (pitch)
                    robot.rotateX(-value * movementFactor);
                    break;
                case 'button_14': //left, left cluster (roll)
                    robot.rotateZ(-value * movementFactor);
                    break;
                case 'button_15': //right, left cluster (roll)
                    robot.rotateZ(value * movementFactor);
                    break;
                default:
                    return;
            }
        }
    }

    sync(entity, renderComponent) {
        renderComponent.matrix.copy(entity.worldMatrix);
    }

    handleSelectedRobotUpdate(robots) {
        robots.children.forEach(robot => {
            let robotName = robot.name.NAME;
            let streamingRobotNames = this.props.simulationRobotStreamers.map(r => r.modelId);
            //add renderer and camera for streaming robots
            if ((!(robotName in this.robotRenderers)) && (streamingRobotNames.includes(robotName))) {
                const canvas = document.getElementById('stream-'.concat(robotName));
                canvas.style.width = '100%';
                canvas.style.height = '100%';
                canvas.width = canvas.parentElement.clientWidth;
                canvas.height = canvas.parentElement.clientHeight;

                const streamRenderer = new THREE.WebGLRenderer({
                    antialias: true,
                    canvas: canvas
                });
                streamRenderer.setClearColor(0xbfd1e5);
                this.robotRenderers[robotName] = streamRenderer;

                // TODO update horizontal fov when three.js r119 is released
                //https://github.com/mrdoob/three.js/pull/19619
                //https://bluerobotics.com/store/sensors-sonars-cameras/cameras/cam-usb-low-light-r1/
                //https://github.com/mrdoob/three.js/issues/15968
                let robotCamera = new THREE.PerspectiveCamera(64, canvas.width / canvas.height, 0.1, 50);

                robotCamera.position.copy(robot.position);
                robotCamera.quaternion.copy(robot.quaternion);
                robotCamera.parentRobotId = robot.id;
                robotCamera.rotateY(Math.PI);
                robotCamera.translateZ(RobotCameraSettings.OFFSET_Z);
                robotCamera.translateY(RobotCameraSettings.OFFSET_Y);
                this.robotCameras[robotName] = robotCamera;

                //remove renderer and camera for unselected robots
            } else if (robotName in this.robotRenderers && !(streamingRobotNames.includes(robotName))) {
                delete this.robotRenderers[robotName];
                robot.remove(this.robotCameras[robotName]);
                delete this.robotCameras[robotName];

                if (this.robotCameraHelpers[robotName]) {
                    this.scene.remove(this.robotCameraHelpers[robotName]);
                    delete this.robotCameraHelpers[robotName];
                }
            }
        });

        this.isHandlingSelectedRobotUpdate = false;
    }

    stopRobotPlayback() {
        // TODO implement
    }

    async handleFollowRobotPathPlayback(isPlayback) {
        if (isPlayback) {
            if (this.playbackPaths.length > 0) {
                for (let i = 0; i < this.playbackPaths.length; i++) {
                    let path = this.playbackPaths[i];
                    let points = path._waypoints;
                    let updateOrientation = path.updateOrientation;
                    let planePosition = path.planePosition;
                    let nextWayPointDistance = path.nextWaypointDistance;
                    let maxSpeed = path.maxSpeed;
                    let up = path.up;
                    let robot = this.robotGroup.children.filter(r => r.name.NAME === ('playback_' + path.robot))[0];
                    robot.children.forEach((c => {
                        c.scale.setScalar(SimulationRobotSettings.MODEL_SCALE);
                    }));
                    robot.matrixAutoUpdate = false;
                    robot.visible = true;

                    let vehicle = new YUKA.Vehicle();
                    vehicle.setRenderComponent(robot, this.sync);
                    vehicle.name = robot.name.NAME;
                    vehicle.sectionType = planePosition;
                    vehicle.up = up;
                    vehicle.prevUp = up;

                    const yukaPath = new YUKA.Path();
                    let j = 0;
                    while (j < points.length) {
                        if (j === points.length - 1) {
                            vehicle.goalPoint = {
                                x: points[j].x,
                                y: points[j].y,
                                z: points[j].z
                            };
                        }
                        let point = new YUKA.Vector3(points[j].x, points[j].y, points[j].z);
                        if (planePosition === 'outlet' || planePosition === 'inlet')
                            point.z += point.z > (-PoolSettings.WIDTH / 2) ? -0.1 : 0.1;
                        else if (planePosition === 'right' || planePosition === 'left')
                            point.x += point.x > (PoolSettings.LENGTH / 2) ? -0.1 : 0.1;
                        else if (planePosition === 'floor') point.y += 0.1;
                        yukaPath.add(point);
                        j++;
                    }
                    vehicle.position.copy(yukaPath.current());

                    let followPathBehavior = new YUKA.FollowPathBehavior(yukaPath, nextWayPointDistance);
                    followPathBehavior.weight = 1;
                    vehicle.steering.add(followPathBehavior);

                    let onPath = new YUKA.OnPathBehavior(yukaPath);
                    onPath.weight = 1;
                    vehicle.steering.add(onPath);

                    vehicle.maxSpeed = maxSpeed;
                    vehicle.updateOrientation = updateOrientation;

                    this.entityManager.add(vehicle);
                }
            } else
                createSnackMessage(this, 'Could not find any paths in this interval');
        } else {
            //Clear playback robots from stage
            this.playbackPaths = null;
        }
        this.isHandlingPlayback = false;
    }

    async handleFollowRobotPathLive() {
        let points = this.props.currentPath.slice();
        let updateOrientation = true;
        let planePosition = '';
        let nextWayPointDistance = 0.2;
        let maxSpeed = 0.2;
        let up = new YUKA.Vector3();
        let planeType = {
            yz: true,
            xz: true,
            xy: true,
        };

        for (let i = 3; i < points.length; i += 3) {
            if (points[i] !== points[i - 3]) {
                planeType.yz = false;
            }
            if (points[i + 1] !== points[i - 2]) {
                planeType.xz = false;
            }
            if (points[i + 2] !== points[i - 1]) {
                planeType.xy = false;
            }
        }

        if (planeType.yz) {
            planePosition = points[0] > PoolSettings.WIDTH / 2 ? 'right' : 'left';
            up.x = 1;
        } else if (planeType.xz) {
            planePosition = 'floor';
            up.y = 1;
        } else if (planeType.xy) {
            planePosition = points[2] < -PoolSettings.LENGTH_TOTAL / 2 ? 'outlet' : 'inlet';
            up.x = 1;
        }

        let robot = this.robotGroup.children.filter(r => r.name.NAME === this.props.currentRobot.modelId)[0];
        robot.children.forEach((c) => {
            c.scale.setScalar(SimulationRobotSettings.MODEL_SCALE);
        });
        robot.matrixAutoUpdate = false;

        let vehicle = new YUKA.Vehicle();
        vehicle.setRenderComponent(robot, this.sync);
        vehicle.name = this.props.currentRobot.modelId;
        vehicle.sectionType = planePosition;
        vehicle.up = up;
        vehicle.prevUp = up;

        const path = new YUKA.Path();
        let i = 0;
        while (i < this.props.currentPath.length) {
            if (i === this.props.currentPath.length - 3) {
                vehicle.goalPoint = {
                    x: this.props.currentPath[i],
                    y: this.props.currentPath[i + 1],
                    z: this.props.currentPath[i + 2]
                };
            }
            let point = new YUKA.Vector3(this.props.currentPath[i], this.props.currentPath[i + 1],
                this.props.currentPath[i + 2]);
            if (planePosition === 'outlet' || planePosition === 'inlet')
                point.z += point.z > (-PoolSettings.WIDTH / 2) ? -0.1 : 0.1;
            else if (planePosition === 'right' || planePosition === 'left')
                point.x += point.x > (PoolSettings.LENGTH / 2) ? -0.1 : 0.1;
            else if (planePosition === 'floor') point.y += 0.1;
            path.add(point);
            i += 3;
        }

        let pathDistance = 0;
        for (let i = 1; i < path._waypoints.length; i++) {
            pathDistance += Math.sqrt(
                Math.pow((path._waypoints[i].x - path._waypoints[i - 1].x), 2) +
                Math.pow((path._waypoints[i].y - path._waypoints[i - 1].y), 2) +
                Math.pow((path._waypoints[i].z - path._waypoints[i - 1].z), 2)
            );
        }
        path.distance = pathDistance;
        path.robot = this.props.currentRobot.modelId;
        path.startTime = Date.now();
        path.approxEndTime = Date.now() + (pathDistance / maxSpeed * 1000);
        vehicle.position.copy(path.current());

        let followPathBehavior = new YUKA.FollowPathBehavior(path, nextWayPointDistance);
        followPathBehavior.weight = 1;
        vehicle.steering.add(followPathBehavior);

        let onPath = new YUKA.OnPathBehavior(path);
        onPath.weight = 1;
        vehicle.steering.add(onPath);

        vehicle.maxSpeed = maxSpeed;
        vehicle.updateOrientation = updateOrientation;

        this.entityManager.add(vehicle);

        // Create new path, otherwise the YUKA.Path toJSON is used which doesn't take all the values into account
        let pathToBeSaved = {};
        pathToBeSaved.distance = path.distance;
        pathToBeSaved.robot = this.props.currentRobot.modelId;
        pathToBeSaved.startTime = path.startTime;
        pathToBeSaved.approxEndTime = path.approxEndTime;
        pathToBeSaved.loop = path.loop;
        pathToBeSaved._index = path._index;
        pathToBeSaved.planePosition = planePosition;
        pathToBeSaved._waypoints = path._waypoints;
        pathToBeSaved.up = up;
        pathToBeSaved.maxSpeed = maxSpeed;
        pathToBeSaved.updateOrientation = updateOrientation;
        pathToBeSaved.nextWaypointDistance = nextWayPointDistance;
        if (!HELPER.savePath(pathToBeSaved))
            createSnackMessage(this, 'Failed to save path for use in playback mode');
    }

    onDocumentMouseMove(event) {
        this.mouse.x = (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
        this.mouse.y = -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, this.camera);

        // if the user is drawing points, the position of the mouse is used to move a 'ghost point'
        if (this.props.isDrawing) {
            let intersectsPool = this.raycaster.intersectObjects(this.pool.poolIntersectRegions.children, true);
            if (intersectsPool.length > 0) {
                this.sectionTool.ghostPoint.visible = true;
                // 0.50 precision
                this.sectionTool.ghostPoint.position.set(
                    Math.round(intersectsPool[0].point.x * 2) / 2,
                    Math.round(intersectsPool[0].point.y * 2) / 2,
                    Math.round(intersectsPool[0].point.z * 2) / 2);
                if (this.sectionTool.index > 0)
                    this.sectionTool.validateCustomPointsFormat();
            } else
                this.sectionTool.ghostPoint.visible = false;
        }
    }

    onDocumentMouseClick(event) {
        this.mouse.x = (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
        this.mouse.y = -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, this.camera);

        // if a user is drawing points and the mouse has not moved during a click we place a point on the pool
        if (this.props.isDrawing && this.mouseDX === this.mouseUX && this.mouseDY === this.mouseUY) {
            //unless one has allready drawn sufficient amounts of points
            if (this.props.canSendSection) {
                CommonUtils.createSnackMessage(this, <FormattedMessage id='THREESCENE.ALLREADY_FOUR_POINTS' />);
            } else {
                let intersectsPool = this.raycaster.intersectObjects(this.pool.poolIntersectRegions.children, true);
                if (intersectsPool.length > 0) {
                    // 0.50 precision
                    let x = Math.round(intersectsPool[0].point.x * 2) / 2;
                    let y = Math.round(intersectsPool[0].point.y * 2) / 2;
                    let z = Math.round(intersectsPool[0].point.z * 2) / 2;

                    let alertResponse = this.sectionTool.createCustomPoint(x, y, z);
                    if (alertResponse === SectionToolStatusTypes.POLYGON_SENDABLE) {
                        this.props.toggleSectionCanSend();
                    }
                    if (alertResponse === SectionToolStatusTypes.SECTION_MUST_BE_RECTANGULAR_AND_MIN_80CM) {
                        createSnackMessage(this, "Section must be rectangular and minimum 80cm area");
                    }
                    this.secIndex++;
                }
            }
        } else {
            let robotIntersects = this.raycaster.intersectObjects(this.robotGroup.children, true);
            if (robotIntersects.length > 0 && robotIntersects[0].object.parent.visible) {
                // TODO remove robots that have finished path (not for playback?)
                let robot = robotIntersects[0].object.parent;
                this.props.selectRobot({
                    modelId: robot.name.NAME,
                    ipAddress: robot.IP,
                });
            }
        }
    }

    onWaveCheck() {
        if (!this.state.isLoadingElements && this.water) {
            WaterSettings.WAVES_VISIBLE = !WaterSettings.WAVES_VISIBLE;
            this.water.updateSurfaceWaves(this.deltaTime);
            this.setState({
                showWaves: WaterSettings.WAVES_VISIBLE
            });
        }
    }

    async onButtonWaterClick() {
        if (!this.state.isLoadingElements && WaterSettings.IS_LOCATION_SET) {
            if (WaterSettings.WATER_VISIBLE)
                this.scene.remove(this.scene.getObjectByName('water'));
            else {
                await this.water.generate();
                CommonUtils.updateLevels(this)
                this.scene.add(this.water.mesh);
            }

            WaterSettings.WATER_VISIBLE = !WaterSettings.WATER_VISIBLE;
            this.setState({ showWater: WaterSettings.WATER_VISIBLE });
        }
    }

    onButtonGridClick() {
        if (!this.state.isLoadingElements) {
            GridSettings.VISIBLE ? this.scene.remove(this.grid.mesh) : this.scene.add(this.grid.mesh);
            GridSettings.VISIBLE = !GridSettings.VISIBLE;
            this.setState({
                showGrid: GridSettings.VISIBLE
            });
        }
    }

    onGridAdjust(gridValue) {
        GridSettings.DISTANCE = gridValue;
        this.setState({
            gridSize: GridSettings.DISTANCE,
            gridSizeInput: GridSettings.DISTANCE
        });

        this.scene.remove(this.grid.mesh);
        this.grid = new PoolGrid();
        this.grid.generate();
        this.scene.add(this.grid.mesh);
    }

    onButtonCameraClick() {
        if (!this.state.isLoadingElements)
            this.controls.reset();
        this.isTracking = false;
    }

    createNewSectionTool() {
        this.sectionTool = new SectionTool(null, this.scene);
    }

    render() {
        const { classes } = this.props;

        return (
            <div
                id="scene"
                onFocus={() => {
                    document.documentElement.scrollTop -= 100;
                }}
                ref={mount => {
                    this.mount = mount;
                }}
            >
                <div className={classes.threeScene}>
                    <div className={classes.sceneToolbarTop}>
                        {this.state.showWater ? <InformationMenuContainer
                            averageLabel={this.state.averageLabel}
                            currentLabel={this.state.currentLabel}
                            highOccursAtLabel={this.state.highOccursAtLabel}
                            highTideLabel={this.state.highTideLabel}
                            lowOccursAtLabel={this.state.lowOccursAtLabel}
                            lowTideLabel={this.state.lowTideLabel}
                        /> : <div />}
                        <ControlMenuContainer
                            onButtonCameraClick={this.onButtonCameraClick}
                            onButtonGridClick={this.onButtonGridClick}
                            onButtonWaterClick={this.onButtonWaterClick}
                            onGridAdjust={this.onGridAdjust}
                            onToggleWaves={this.onWaveCheck}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    robots: selectRobots,
    site: selectCurrentSite,
    isDrawing: selectIsDrawing,
    isClearing: selectIsClearing,
    customSectionName: selectCustomSectionName,
    currentRobot: selectCurrentRobot,
    selectedRobots: selectSelectedRobots,
    currentPath: selectCurrentPath,
    canSendSection: selectCanSend,
    isSendingCustomSection: selectIsSendingSection,
    simulationRobotStreamers: selectSimulationRobotStreamers,
    simulationRobots: selectSimulationRobots,
    currentUser: selectCurrentUser,
    isLiveMode: selectIsLive,
    currentlyDrivingRobot: selectCurrentlyDrivingRobot,
    isSimulationPlaybackMode: selectSimulationPlaybackMode,
    fromTime: selectPlaybackModeFrom,
    toTime: selectPlaybackModeTo,
});

const mapDispatchToProps = dispatch => ({
    toggleIsDrawing: () => dispatch(toggleIsDrawing()),
    toggleIsClearing: () => dispatch(toggleIsClearing()),
    selectRobot: (robot) => dispatch(selectRobot(robot)),
    setSimulationRobots: (robots) => dispatch(setSimulationRobots(robots)),
    setCurrentSite: (site) => dispatch(setCurrentSite(site)),
    setRobots: (robot) => dispatch(setRobots(robot)),
    toggleFeedbackHidden: () => dispatch(toggleFeedbackHidden()),
    setFeedbackMessage: (text) => dispatch(setFeedbackMessage(text)),
    toggleSectionCanSend: () => dispatch(toggleSectionCanSend()),
    resetCustomSectionName: () => dispatch(resetCustomSectionName()),
    toggleSendCustomSection: (customSectionName) => dispatch(toggleSendCustomSection(customSectionName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(SimulationThreeScene));