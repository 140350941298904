const styles = {
    root: {
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        bottom: 10,
        right: 35,
        width: '90%',
        justifyContent: 'space-between'
    },
};

export {
    styles,
}
