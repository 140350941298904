import moment from 'moment';

require('moment-duration-format');

class Helper {

    /**
     * Format a duration, if seconds is 0 an empty string is returned
     *
     * @param {int} seconds The duration in seconds
     * @param {string} format The display format, default is 'm[m] s[s]' (2m 10s)
     * @param {string} stopTrim Smallest value to display, depends on format, default is 'm'
     * @return {string}
     */
    static durationFormatted(seconds, format = 'm[m] s[s]', stopTrim = 'm') {
        return moment.duration(seconds, 'seconds').format(format, { trim: 'large final', stopTrim: stopTrim });
    }
}

const durationFormatted = Helper.durationFormatted;

export {
    durationFormatted,
};