import { createSelector } from 'reselect';

const selectMode = state => state.mode;

export const selectIsLive = createSelector(
    [selectMode],
    mode => mode.isLive,
);

export const selectIsJoystickConnected = createSelector(
    [selectMode],
    mode => mode.joyConnected,
);

export const selectSimulationPlaybackMode = createSelector(
    [selectMode],
    mode => mode.isSimulationPlaybackMode,
);

export const selectPlaybackModeFrom = createSelector(
    [selectMode],
    mode => mode.fromTime,
);

export const selectPlaybackModeTo = createSelector(
    [selectMode],
    mode => mode.toTime,
);