export const updateShowRobotControlState = (robots, robotToEdit, state) => {
    const currentRobot = robots.find(robot => robot.modelId === robotToEdit.modelId);

    if (currentRobot) {
        switch (state) {
            case 'showRobot':
                return robots.map(robot =>
                    robot.modelId === robotToEdit.modelId ?
                        { ...robot, showRobot: !robot.showRobot } :
                        robot
                );
            case 'showPath':
                return robots.map(robot =>
                    robot.modelId === robotToEdit.modelId ?
                        { ...robot, showPath: !robot.showPath } :
                        robot
                );
            case 'showTrail':
                return robots.map(robot =>
                    robot.modelId === robotToEdit.modelId ?
                        { ...robot, showTrail: !robot.showTrail } :
                        robot
                );
            case 'showSection':
                return robots.map(robot =>
                    robot.modelId === robotToEdit.modelId ?
                        { ...robot, showSection: !robot.showSection } :
                        robot
                );
            case 'section':
                return robots.map(robot =>
                    robot.modelId === robotToEdit.modelId ?
                        { ...robot, section: !robot.showSection } :
                        robot
                );
            default:
                return robots;
        }
    }
};

export const updateCurrentRobotState = (currentRobot, state) => {
    switch (state) {
        case 'showRobot':
            return { ...currentRobot, showRobot: !currentRobot.showRobot };
        case 'showPath':
            return { ...currentRobot, showPath: !currentRobot.showPath };
        case 'showTrail':
            return { ...currentRobot, showTrail: !currentRobot.showTrail };
        case 'showSection':
            return { ...currentRobot, showSection: !currentRobot.showSection };
        case 'section':
            return { ...currentRobot, showSection: !currentRobot.showSection };
        default:
            return currentRobot;
    }
};

export const updateCurrentRobotSection = (currentRobot, section) => {
    return {
        ...currentRobot,
        section: section
    };
};

export const updateRobots = (robots, currentRobot, section) => {
    return robots.map(robot =>
        robot.modelId === currentRobot.modelId ?
            { ...robot, section: section } :
            robot
    );

};

export const updateRemoveSelectedRobot = (currentSelectedRobots, robotToRemove) => {
    let newSelectedRobots = currentSelectedRobots.slice();
    const removeIndex = newSelectedRobots.findIndex((robot) => {
        return robot.modelId === robotToRemove.modelId;
    });
    newSelectedRobots.splice(removeIndex, 1);
    return newSelectedRobots;
};

export const updateAddSelectedRobot = (currentSelectedRobots, newRobot) => {
    // change nothing if robot already selected, unless same robot had updated data, in that case change the same robot with the updated robot
    const robotIndex = currentSelectedRobots.findIndex(r => r.modelId === newRobot.modelId);
    if (robotIndex > -1) {
        if (currentSelectedRobots[robotIndex] === newRobot) {
            return currentSelectedRobots;
        } else {
            const newSelectedRobots = [].concat(currentSelectedRobots);
            newSelectedRobots.splice(robotIndex, 1, newRobot);
            return newSelectedRobots;
        }
    }
    let newSelectedRobots = currentSelectedRobots.slice();
    newSelectedRobots.unshift(newRobot);
    return newSelectedRobots;
};