module.exports = {
    locale: 'en-GB',
    messages: {
        'TOPMENU.MENU_ITEM_PROFILE': 'Profile',
        'TOPMENU.MENU_ITEM_LANGUAGE': 'Language',
        'TOPMENU.MENU_ITEM_NOTIFICATION': 'Notification',
        'TOPMENU.TOGGLE_THEME': 'Toggle light/dark theme',
        'TOPMENU.TITLE': 'NetRobot - Overview',
        'TOPMENU.USE_LIVE_MODE': 'Use live mode',
        'TOPMENU.USE_SIMULATION_MODE': 'Use simulation mode',
        'TOPMENU.LOG_OUT' : 'Log out',
        'TOPMENU.CHANGE_LANGUAGE' : 'Change language',
        'ROBOT_SIM_CONTROL_MENU.OPEN': 'Robotsim control menu',
        'ROBOT_SIM_CONTROL_MENU.MANAGE_ROBOTS': 'Manage robots',
        'ROBOT_SIM_CONTROL_MENU.STATUS': 'Status:',
        'ROBOT_SIM_CONTROL_MENU.CURRENTLY_NOT_USED': 'Currently not used',
        'ROBOT_SIM_CONTROL_MENU.ROBOT_IN_SIMULATION': 'Robot in simulation',
        'ROBOT_SIM_CONTROL_MENU.REMOVE': 'Remove',
        'ROBOT_SIM_CONTROL_MENU.SPAWN': 'Spawn',

    },
};
