const styles = {
    sceneExpansionContainer: {
        margin: 10,
        width: '300px',
    },
    waterLevelInfo: {
        flexDirection: 'column',
        padding: 20
    },
    infoContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        top: '50px',
        zIndex: '1',
        overflow: 'auto'
    },
    waterLevelColumns: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingLeft: 10,
    },
    waterLevelLabelsContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '20px'
    },
    flexRow100PercentWidth: {
        display: 'flex',
        flexDirection: 'row'
    },
    justBet: {
        justifyContent: 'space-between',
        marginBottom: 2
    },
    averageIcon: {
        transform: 'rotate(50deg)',
        color: '#159C01'
    },
    waterLevelLabel: {
        marginRight: '12px',
        marginLeft: '10px',
        width: '100px'
    },
    highTideIcon: {
        transform: 'rotate(50deg)',
        color: '#FF0000'
    },
    waterLevelLabelIndent: {
        marginLeft: '36px',
        fontStyle: 'italic',
        fontWeight: 600
    },
    lowTideIcon: {
        transform: 'rotate(50deg)',
        color: '#E2A920'
    },
    flexCol100PercentWidth: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    currentIcon: {
        transform: 'rotate(50deg)',
        color: '#0000FF'
    },
};

export {
    styles,
};
