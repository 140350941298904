class Environment {
	constructor(assets = null) {
		this.mesh = null;
		this.assets = assets;
		
		this.generate = this.generate.bind(this);
	}
	
	generate() {}
}

export default Environment;