import { RobotSettings, Playback } from './OperationalValues';
import * as THREE from 'three';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import * as YUKA from 'yuka';
import * as HELPER from '../Helpers';

class AssetLoader {

    constructor() {
        this.robotPrototype = [];
        this.robotPrototypeLive = null;
        this.robotPlaybackPrototype = [];
        this.environmentAssets = null;
        this.topSide = null;
        this.navmesh = null;
        this.navMeshHelper = null;

        this.loadRobotAssets = this.loadRobotAssets.bind(this);
        this.loadEnvironmentAssets = this.loadEnvironmentAssets.bind(this);
        this.loadAssets = this.loadAssets.bind(this);
    }

    // TODO edit this so it can load x amount of robots at a time
    async loadAssets(sources) {
        let loader;
        return await Promise.all(sources.map(source => {
            return new Promise(async resolve => {
                if (source instanceof Object) {
                    new MTLLoader().load(source['mtl'], materials => {
                        materials.preload();
                        new OBJLoader()
                            .setMaterials(materials)
                            .load(
                                source['obj'],
                                group => resolve(group)
                            );
                    });
                } else if (source.search('navmesh') >= 0) {
                    let navMeshes = {};
                    loader = new YUKA.NavMeshLoader();
                    const navMesh = await loader.load(source, {});

                    if (process.env.NODE_ENV === 'development') {
                        navMeshes.navHelper = HELPER.createConvexRegionHelper(navMesh);
                    }

                    navMeshes.navMesh = navMesh;
                    resolve(navMeshes);
                } else {
                    let isTexture = source.search('textures') >= 0;
                    if (isTexture || source.search('font') >= 0) {
                        loader = isTexture ? new THREE.TextureLoader() : new THREE.FontLoader();
                        loader.load(
                            source,
                            source => resolve(source)
                        );
                    }
                }
            }
            );
        }));
    }

    async loadEnvironmentAssets(isLiveMode) {
        let environmentAssets = [
            '/assets/font_helvetiker_bold.typeface.json',
            '/assets/textures/waterdudv.jpg',
            {
                'mtl': '/assets/models/topside/X11.mtl',
                'obj': '/assets/models/topside/X11.obj'
            }
        ];

        const assets = await this.loadAssets(environmentAssets);
        this.environmentAssets = assets;
        let topSide = assets[2];

        topSide.children[0].material.shininess = 1.5;
        topSide.children[0].material.reflectivity = 1;

        topSide.up = new THREE.Vector3(0, 0, 1);
        topSide.scale.setScalar(0.05);

        this.topSide = topSide;
    }

    async loadRobotAssets(isLiveMode) {
        if (isLiveMode) {
            let robotAssets = [{
                'mtl': '/assets/models/netrobot/netrobot_x2x.mtl',
                'obj': '/assets/models/netrobot/netrobot_x2x.obj'
            }];
            const robotObjects = await this.loadAssets(robotAssets);
            let robot = robotObjects[0];
            robot.children[0].material.shininess = 3;
            robot.children[1].material.shininess = 3;
            robot.scale.setScalar(RobotSettings.MODEL_SCALE);
            robot.rotation.set(0, Math.PI, 0);

            this.robotPrototypeLive = robot;
        } else {
            let robotAssets = [];
            for (let i = 0; i < RobotSettings.NO_OF_CUSTOMER_ROBOTS; i++) {
                robotAssets[i] = {
                    'mtl': '/assets/models/netrobot/netrobot_x2x.mtl',
                    'obj': '/assets/models/netrobot/netrobot_x2x.obj',
                }
            }

            const robotObjects = await this.loadAssets(robotAssets);
            for (let i = 0; i < robotObjects.length; i++) {
                let robot = robotObjects[i];
                robot.children[0].material.shininess = 3;
                robot.children[0].material.color = new THREE.Color(0, 0, 0);
                robot.children[1].material.shininess = 3;
                robot.scale.setScalar(RobotSettings.MODEL_SCALE);
                robot.rotation.set(0, Math.PI, 0);
                this.robotPrototype[i] = robot;
            }

            const pbRobotObjects = await this.loadAssets(robotAssets);
            for (let i = 0; i < pbRobotObjects.length; i++) {
                let pbRobot = pbRobotObjects[i];
                pbRobot.children[0].material.shininess = 3;
                pbRobot.children[0].material.color = new THREE.Color(0.5, 0.5, 0.5);
                pbRobot.children[0].material.transparent = Playback.TRANSPARENT;
                pbRobot.children[0].material.opacity = Playback.OPACITY;
                pbRobot.children[1].material.shininess = 3;
                pbRobot.children[1].material.transparent = Playback.TRANSPARENT;
                pbRobot.children[1].material.opacity = Playback.OPACITY;
                pbRobot.visible = Playback.VISIBLE;
                pbRobot.scale.setScalar(RobotSettings.MODEL_SCALE);
                pbRobot.rotation.set(0, Math.PI, 0);

                this.robotPlaybackPrototype[i] = pbRobot;
            }
        }
    }
}

export default AssetLoader;