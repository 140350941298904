import {modeActionTypes} from './modeTypes';

export const toggleUseLiveSceneMode = () => ({
    type: modeActionTypes.TOGGLE_IS_LIVE,
});

export const toggleUseSimulationPlaybackMode = () => ({
    type: modeActionTypes.TOGGLE_SIMULATION_PLAYBACK,
});

export const toggleJoystickConnected = () => ({
    type: modeActionTypes.TOGGLE_JOYSTICK_CONNECTED,
});
