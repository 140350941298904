module.exports = {
    locale: 'nb-NO',
    messages: {
        'TOPMENU.MENU_ITEM_PROFILE': 'Profil',
        'TOPMENU.MENU_ITEM_LANGUAGE': 'Språk',
        'TOPMENU.MENU_ITEM_NOTIFICATION': 'Varsler',
        'TOPMENU.TOGGLE_THEME': 'Veksle lyst/mørkt tema',
        'TOPMENU.TITLE': 'NetRobot - Oversikt',
        'TOPMENU.USE_LIVE_MODE': 'Bruk livemodus',
        'TOPMENU.USE_SIMULATION_MODE': 'Bruk simuleringsmodus',
        'TOPMENU.LOG_OUT' : 'Logg ut',
        'TOPMENU.CHANGE_LANGUAGE' : 'Endre språk',
        'ROBOT_SIM_CONTROL_MENU.OPEN': 'Robotsim kontrollmeny',
        'ROBOT_SIM_CONTROL_MENU.MANAGE_ROBOTS': 'Kontroller roboter',
        'ROBOT_SIM_CONTROL_MENU.STATUS': 'Status:',
        'ROBOT_SIM_CONTROL_MENU.CURRENTLY_NOT_USED': 'Ikke i bruk',
        'ROBOT_SIM_CONTROL_MENU.ROBOT_IN_SIMULATION': 'Aktiv i simuleringen',
        'ROBOT_SIM_CONTROL_MENU.REMOVE': 'Fjern',
        'ROBOT_SIM_CONTROL_MENU.SPAWN': 'Opprett',
    },
};
