import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import { firestore } from '../../../firebase/Firebase';

import { selectIsLive } from '../../../redux/mode/modeSelector';
import { selectCurrentUser } from '../../../redux/user/userSelector';
import { selectCurrentSite } from '../../../redux/site/siteSelector';
import { selectCurrentRobot, selectRobots, selectSimulationRobots } from '../../../redux/robot/robotSelector';
import { setLiveRobotSection, setSimulatedRobotSection } from '../../../redux/robot/robotActions';
import { setCurrentSite } from '../../../redux/site/siteActions';
import { setFeedbackMessage, toggleFeedbackHidden } from '../../../redux/feedback/feedbackActions';

import { NO_OF_DEFAULT_SECTIONS } from '../../../config';
import CustomSectionTool from './CustomSectionTool';

import { styles } from './SettingsDrawerStyles.js';
import Axios from 'axios';

class SectionTool extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sectionName: this.props.currentRobot.section !== null ? this.props.currentRobot.section : '',
            resolution: null,
        }

        this.db = firestore;
    }

    handleRemoveCustomSection = index => async () => {
        let customerRef = null;

        const querySnapshot = await this.db.collection('customers').where('id', '==', this.props.currentUser.id).get()

        querySnapshot.forEach((doc) => {
            customerRef = this.db.collection('customers').doc(doc.id);
        });

        if (customerRef) {
            const doc = await customerRef.get()
            const sites = doc.data().sites;
            const site = sites.filter(site => site.name === this.props.currentSite.name)[0];
            site.sections.splice(index, 1);

            await customerRef.update({
                sites: [].concat(site),
            })

            this.setState({
                sectionName: '',
            }, () => {
                this.props.setCurrentSite({
                    ...this.props.currentSite,
                    sections: site.sections,
                });
            });
        }
    };

    renderItems() {
        const noneValue = (
            <MenuItem
                key="none"
                value=""
            >
                <div className={this.props.classes.menuItem}>
                    <em>None</em>
                    <IconButton
                        disabled={true}
                    >
                        <Icon>delete</Icon>
                    </IconButton>
                </div>
            </MenuItem>
        );

        const items = this.props.currentSite.sections.map((section, index) => {
            return (
                <MenuItem
                    key={section.name}
                    value={section.name}
                >
                    <div className={this.props.classes.menuItem}>
                        {section.name}
                        <IconButton
                            disabled={index <= NO_OF_DEFAULT_SECTIONS - 1}
                            onClick={index <= NO_OF_DEFAULT_SECTIONS - 1 ? null : this.handleRemoveCustomSection(index)}
                        >
                            <Icon className={this.props.classes.deleteIcon}>delete</Icon>
                        </IconButton>
                    </div>
                </MenuItem>
            );
        });

        items.unshift(noneValue);

        return items;
    }

    handleSelectSection = (event) => {
        this.setState({
            sectionName: event.target.value
        });
    };

    handleSendSection = () => {
        let feedbackMessage;
        this.props.isLiveMode ? this.updateRobotSectionInFirestore() : this.updateRobotSectionInSimulation();
        feedbackMessage = (
            <FormattedMessage
                id='SETTINGSDRAWER.SECTION_SET_SUCCESSFULLY'
                values={{
                    robotName: this.props.currentRobot.modelId,
                    sectionName: this.state.sectionName,
                }}
            />
        );
        this.props.toggleFeedbackHidden();
        this.props.setFeedbackMessage(feedbackMessage);
    }

    isSelectedSectionTaken() {
        const robots = this.props.isLiveMode ? this.props.liveRobots : this.props.simulationRobots;
        for (let i = 0; i < robots.length; i++) {
            if (robots[i].section === this.state.sectionName)
                return true;
        }
        return false;
    }

    updateRobotSectionInSimulation() {
        this.props.setSimulatedRobotSection(this.state.sectionName);
    }

    async updateRobotSectionInFirestore() {
        this.props.setLiveRobotSection(this.state.sectionName);

        const customerId = this.props.currentRobot.customerId;
        const customerRef = this.db.collection('customers').doc(customerId);

        const doc = await customerRef.get()
        const robots = doc.data().robots;
        const robotSection = doc.data().sites[0].sections.filter(section => section.name === this.state.sectionName)[0];

        const section = {
            name: robotSection.name,
            section: robotSection.border,
        };

        for (const robot of robots) {
            if (robot.modelId === this.props.currentRobot.modelId) {
                robot.section = this.props.currentRobot.section;
                const response = await Axios({
                    method: 'post',
                    url: `https://${robot.ipAddress}/api/v1/set_section`,
                    data: {
                        section: section,
                    },
                    headers: {
                        Authorization: 'Bearer ' + this.props.currentUser.token,
                    },
                })

                this.setState({
                    resolution: response.data.resolution,
                });
            }
        }
        robots.forEach(robot => {

        });

        customerRef.update({
            robots: [].concat(robots),
        });
    }

    render() {
        return (
            <div>
                <div>
                    <InputLabel id="selectPredefSectionCard">
                        <FormattedMessage id="SETTINGSDRAWER.SECTION_LABEL" />
                    </InputLabel>
                    <Select
                        labelId="selectPredefSectionCard"
                        name="sections"
                        size="1"
                        style={{ width: '100%' }}
                        value={this.state.sectionName}
                        onChange={this.handleSelectSection}
                        displayEmpty={true}
                    >
                        {this.renderItems()}
                    </Select>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSendSection}
                >
                    <FormattedMessage id="SETTINGSDRAWER.BTN_PREDEFINED" />
                </Button>
                <CustomSectionTool />
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    currentSite: selectCurrentSite,
    currentRobot: selectCurrentRobot,
    currentUser: selectCurrentUser,
    isLiveMode: selectIsLive,
    simulationRobots: selectSimulationRobots,
    liveRobots: selectRobots,
});

const mapDispatchToProps = dispatch => ({
    setLiveRobotSection: section => dispatch(setLiveRobotSection(section)),
    setSimulatedRobotSection: section => dispatch(setSimulatedRobotSection(section)),
    toggleFeedbackHidden: () => dispatch(toggleFeedbackHidden()),
    setFeedbackMessage: message => dispatch(setFeedbackMessage(message)),
    setCurrentSite: site => dispatch(setCurrentSite(site)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SectionTool));
