import { messagesEnGb as ControlMenuContainer } from '../assets/components/ControlMenuContainer/language';
import { messagesEnGb as InformationMenuContainer } from '../assets/components/InformationMenuContainer/language';
import { messagesEnGb as LoginForm } from '../assets/components/LoginForm/language';
import { messagesEnGb as OverviewContainer } from '../assets/components/OverviewContainer/language';
import { messagesEnGb as SettingsDrawer } from '../assets/components/SettingsDrawer/language';
import { messagesEnGb as ThreeScene } from '../assets/components/ThreeScene/language';
import { messagesEnGb as TopMenu } from '../assets/components/TopMenu/language';

const sideMenu = {
    'MENU.HOME': 'Home',
    'MENU.NAVIGATION': 'Weather',
    'MENU.OPERATION': 'Operation',
    'MENU.OPERATION_SETTINGS': 'Planning',
    'MENU.OPERATION_DRIFT': 'Drift',
    'MENU.SERVICE': 'Service',
    'MENU.SERVICE_MAIN': 'Connection',
    'MENU.SERVICE_PARAMETERS': 'Parameters',
    'MENU.SERVICE_CONTROLLER': 'Controller tuning',
    'MENU.DARK_MODE': 'Dark mode',
    'MENU.LIGHT_MODE': 'Light mode',
    'MENU.LOG_OUT': 'Log out',
    'MENU.ADMIN_DASHBOARD': 'Dashboard',
    'MENU.CONTACT': 'Contact us',
};

const globalMessages = {
    'ERROR.UNAUTHORIZED': 'Access denied, please refresh the page or log in again',
};

export default {
    locale: 'en-GB',
    messages: Object.assign(
        {},
        ControlMenuContainer,
        InformationMenuContainer,
        LoginForm,
        OverviewContainer,
        SettingsDrawer,
        ThreeScene,
        TopMenu,

        globalMessages,
        sideMenu,
    ),
};