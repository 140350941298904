import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedMessage } from 'react-intl';
import SettingsIcon from '@material-ui/icons/Settings';
import Card from '@material-ui/core/Card';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { WiThermometer } from 'react-icons/wi';
import { FaLevelDownAlt, FaLevelUpAlt } from 'react-icons/fa';
import { BiRotateRight } from 'react-icons/bi';
import { GrGamepad } from 'react-icons/gr';
import Grow from '@material-ui/core/Zoom';
import classnames from 'classnames';
import {
    addSimulationRobotStreamer,
    removeSimulationRobotStreamer,
    setCurrentRobot,
    toggleSettingsDrawer
} from '../../../../../redux/robot/robotActions';
import { selectCurrentlyDrivingRobot, selectCurrentRobot, selectRobots, selectSimulationRobots } from '../../../../../redux/robot/robotSelector';

import './MaterialUiIcons.css';
import { styles } from './RobotCardStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { selectIsLive } from '../../../../../redux/mode/modeSelector';
import Axios from 'axios';
import { selectCurrentUser } from '../../../../../redux/user/userSelector';
import whiteNoise from '../../../../static/images/white_noise.gif';
import { durationFormatted } from '../../../../../utils/Helper';
import { operationMode, statusCode } from '../../../../../config';
import { setFeedbackMessage, toggleFeedbackHidden } from '../../../../../redux/feedback/feedbackActions';
import AltitudeInstrument from './AltitudeInstrument';
import HeadingInstrument from './HeadingInstrument';


class RobotCard extends React.Component {

    static propTypes = {
        robot: PropTypes.object,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        robot: null,
        onClose: null
    };

    constructor(props) {
        super(props);

        this.canStreamTimeoutId = null;

        this.state = {
            isStreaming: true,
            canStream: false,
            showComponent: true,
            tempWeather: 0.0,
            tempRov: 0.0,
            droneSpeed: 0.1,
            operationTime: '2h 19m 13s',
            rovCount: 0,
            direction: 1,
            depth: 0,
            validToken: false,
            isInControl: false,
        };
    }

    componentDidMount() {
        this.props.isLiveMode ? this.startLiveCameraStream() : this.startSimulatedCameraStream();
        this.getJoystickLock();
    }

    componentWillUnmount() {
        if (this.state.isStreaming)
            this.props.isLiveMode ? this.stopLiveCameraStream() : this.stopSimulatedCameraStream();

        if (this.canStreamTimeoutId !== null)
            clearTimeout(this.canStreamTimeoutId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.isLiveMode && !prevState.canStream && this.state.canStream)
            this.props.addSimulationRobotStreamer(this.props.robot);
        if (prevProps.robot.isRovMode !== this.props.robot.isRovMode) {
            this.getJoystickLock();
        }
    }

    handleClose = () => {
        //plays the close animation
        this.setState({ showComponent: false });
        setTimeout(() => {
            //actually removes component from dom
            this.props.onClose(this.props.robot);
        }, 200);
    };

    handleSettingsClick = () => {
        const currentRobot = this.props.isLiveMode ?
            this.props.robots.filter(robot => robot.modelId === this.props.robot.modelId)[0] :
            this.props.simulationRobots.filter(robot => robot.modelId === this.props.robot.modelId)[0];


        this.props.setCurrentRobot(currentRobot);
        this.props.toggleSettingsDrawer();
    };

    handleToggleStreaming = () => {
        if (this.state.isStreaming) {
            this.setState({
                isStreaming: false,
            }, () => {
                this.props.isLiveMode ? this.stopLiveCameraStream() : this.stopSimulatedCameraStream();
            });
        } else {
            this.setState({
                isStreaming: true,
            }, () => {
                this.props.isLiveMode ? this.startLiveCameraStream() : this.startSimulatedCameraStream();
            });
        }

    };

    startLiveCameraStream = async () => {
        const { currentUser } = this.props;

        try {
            await Axios({
                method: 'GET',
                url: `https://${this.props.robot.ipAddress}/api/v1/startstream`,
                headers: {
                    Authorization: 'Bearer ' + currentUser.token,
                },
            })

            this.setState({
                canStream: true,
            });
        } catch (error) {
            console.error(error)

            this.setState({
                canStream: false,
                isStreaming: false,
            });
        }
    };

    startSimulatedCameraStream = () => {
        this.canStreamTimeoutId = setTimeout(() => {
            this.setState({
                canStream: true
            });
        }, 600);
    };

    stopLiveCameraStream = async () => {
        const { currentUser } = this.props;

        if (this.state.canStream) {
            try {
                await Axios({
                    method: 'GET',
                    url: `https://${this.props.robot.ipAddress}/api/v1/stopstream`,
                    headers: {
                        Authorization: 'Bearer ' + currentUser.token,
                    },
                })

                this.setState({
                    canStream: false,
                });
            } catch (error) {
                console.error(error)
            }
        }
    };

    stopSimulatedCameraStream = () => {
        this.setState({
            canStream: false,
        });
        this.props.removeSimulationRobotStreamers(this.props.robot);
    };

    convertCounterToReadableTime(time) {
        if (time > 0) {
            return durationFormatted(
                time,
                this.props.intl.formatMessage({ id: 'ROBOTCARD.RUN_COUNTER' }),
                's'
            );
        }
        return 0;
    }

    renderRunCount() {
        const { classes } = this.props;
        return (
            <Tooltip
                title={<FormattedMessage id="ROBOTCARD.ROV_RUN_COUNT" />}
                placement="right"
            >
                <div className={classes.widget}>
                    <Icon>import_export</Icon>
                    <span>{this.state.rovCount}</span>
                </div>
            </Tooltip>
        );
    }

    renderSpeed() {
        if (this.props.isLiveMode) {
            return (
                <Tooltip
                    title={<FormattedMessage id="ROBOTCARD.DRONE_SPEED" />}
                    placement="right"
                >
                    <div className={classnames(this.props.classes.widget, this.props.classes.velocityWidget)}>
                        <div className={this.props.classes.velocityWidgetSingle}>
                            <Icon>speed</Icon> x: {this.props.robot.hasOwnProperty('velocity') ? this.props.robot.velocity.x : this.state.droneSpeed} m/s
                        </div>
                        <div className={this.props.classes.velocityWidgetSingle}>
                            <Icon>speed</Icon>y: {this.props.robot.hasOwnProperty('velocity') ? this.props.robot.velocity.y : this.state.droneSpeed} m/s
                        </div>
                        <div className={this.props.classes.velocityWidgetSingle}>
                            <Icon>speed</Icon>z: {this.props.robot.hasOwnProperty('velocity') ? this.props.robot.velocity.z : this.state.droneSpeed} m/s
                        </div>
                    </div>
                </Tooltip>
            );
        } else {
            return (
                <Tooltip
                    title={<FormattedMessage id="ROBOTCARD.DRONE_SPEED" />}
                    placement="right"
                >
                    <div className={this.props.classes.widget}>
                        <Icon>speed</Icon>
                        <span>{this.state.droneSpeed}</span>
                        <span>m/s</span>
                    </div>
                </Tooltip>
            );
        }
    }

    renderInfoColumn() {
        const { classes } = this.props;

        return (
            <div className={classes.robotInfoContainer}>
                <Tooltip
                    title={<FormattedMessage id="ROBOTCARD.DEPTH_TO_SURFACE" />}
                    placement="right"
                >
                    <div className={classes.widget}>
                        {this.state.direction === 1 ? <FaLevelDownAlt /> : <FaLevelUpAlt />}
                        <span>{this.props.isLiveMode && this.props.robot.hasOwnProperty('depth') ? this.props.robot.depth.toFixed(2) : this.state.depth}</span>
                        <span>&nbsp; m</span>
                    </div>
                </Tooltip>
                <Tooltip
                    title={<FormattedMessage id="ROBOTCARD.ROV_TEMPERATURE" />}
                    placement="right"
                >
                    <div className={classes.widget}>
                        <WiThermometer style={{ fontSize: '1.72em' }} />
                        <span>{this.props.isLiveMode ? this.props.robot.temp_robot : this.state.tempRov}</span>
                        {'\xB0 C'}
                    </div>
                </Tooltip>
                <Tooltip
                    title={<FormattedMessage id="ROBOTCARD.WEATHER_TEMPERATURE" />}
                    placement="right"
                >
                    <div className={classes.widget}>
                        <WiThermometer style={{ fontSize: '1.72em' }} />
                        <span>{this.props.isLiveMode ? this.props.robot.temp_water : this.state.tempWeather}</span>
                        {'\xB0 C'}
                    </div>
                </Tooltip>
                <Tooltip
                    title={<FormattedMessage id="ROBOTCARD.TETHER_TURN" />}
                    placement="right"
                >
                    <div className={classes.widget}>
                        <BiRotateRight style={{ fontSize: '1.72em' }} />
                        <FormattedMessage id="ROBOTCARD.TETHER_TURN_CLOCKWISE" values={{ turns: this.props.isLiveMode ? this.props.robot.tether_turn : 0.0 }} />
                    </div>
                </Tooltip>
                <Tooltip
                    title={<FormattedMessage id="ROBOTCARD.JOY_GAIN" />}
                    placement="right"
                >
                    <div className={classes.widget}>
                        <GrGamepad style={{ fontSize: '1.72em' }} />
                        <span>&nbsp;&nbsp;</span>
                        <span>{this.props.isLiveMode ? Math.ceil(this.props.robot.joystick_gain * 100) || 0 : 0.5}</span>
                        <span>&nbsp; %</span>
                    </div>
                </Tooltip>
                <Tooltip
                    title={<FormattedMessage id="ROBOTCARD.OPERATION_TIME" />}
                    placement="right"
                >
                    <div className={classes.widget}>
                        <Icon>timelapse</Icon>
                        <span>{this.props.isLiveMode ? this.convertCounterToReadableTime(this.props.robot.runTime) : this.state.operationTime}</span>
                    </div>
                </Tooltip>
            </div>
        );
    }

    renderHeaderButtons() {
        const { classes } = this.props;
        return (
            <div className={classes.headerButtonsContainer}>
                <div className={classes.headerButton}>
                    <IconButton
                        variant="contained"
                        color="inherit"
                        aria-label="close"
                        onClick={this.handleToggleStreaming}
                    >
                        {this.state.isStreaming
                            ? <VideocamIcon />
                            : <VideocamOffIcon />}
                    </IconButton>
                </div>
                <div className={classes.headerButton}>
                    <Tooltip
                        title={<FormattedMessage id="ROBOTCARD.SETTINGS_DRAWER" />}
                        placement="bottom"
                    >
                        <IconButton
                            variant="contained"
                            color='inherit'
                            aria-label="close"
                            onClick={this.handleSettingsClick}
                        >
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={classes.headerButton}>
                    <div>
                        <IconButton
                            variant="contained"
                            color='inherit'
                            aria-label="close"
                            onClick={this.handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
        );
    }

    renderLiveStream() {
        const { classes, robot } = this.props;

        const videoStreamUrl = `http://${robot.ipAddress.split(':')[0]}:${robot.streamPort}/?action=stream/`;

        let liveStream = (
            <img
                className={classes.robotStream}
                src={this.state.canStream ? videoStreamUrl : whiteNoise}
                alt='Live stream'
            />
        );

        if (!this.state.canStream) {
            this.canStreamTimeoutId = setTimeout(() => {
                if (!this.state.canStream) {
                    this.setState({
                        isStreaming: false,
                        canStream: false,
                    });
                }
            }, 2000);
        }

        return (
            <div className={classes.robotStream}>
                {liveStream}
            </div>
        );
    }

    renderSimulationStream() {
        const { classes } = this.props;

        const simStreamer = (
            <canvas
                id={'stream-'.concat(this.props.robot.modelId)}
                className={classes.robotStream}
            />
        );

        const whiteNoiseStream = (
            <img
                className={classes.robotStream}
                src={whiteNoise}
                alt='white-noise'
            />
        );

        const currentStream = () => {
            if (this.state.canStream) {
                return simStreamer;
            } else {
                return whiteNoiseStream;
            }
        };

        return (
            <div className={classes.robotStream}>
                {currentStream()}
            </div>
        );
    }

    renderInstruments() {
        return (
            <div className={this.props.classes.instruments}>
                <AltitudeInstrument
                    pitch={this.props.robot.hasOwnProperty('pitch') ? parseFloat(this.props.robot.pitch.toFixed(1)) : 0.0}
                    roll={this.props.robot.hasOwnProperty('roll') ? parseFloat(this.props.robot.roll.toFixed(1)) : 0.0}
                    textColor={this.props.theme.palette.primary.contrastText}
                    textSize={24}
                    scaleFactor={0.5}
                    width={115}
                    height={115}
                />
                <HeadingInstrument
                    heading={this.props.robot.hasOwnProperty('yaw') ? parseFloat(this.props.robot.yaw.toFixed(1)) : 0.0}
                    textColor={this.props.theme.palette.primary.contrastText}
                    textSize={24}
                    scaleFactor={0.5}
                    width={115}
                    height={115}
                />
            </div>
        );
    }

    renderArmState() {
        const { classes } = this.props;

        if (this.props.robot.armed) {
            return <div className={classes.armedText}><FormattedMessage id='ROBOTCARD.ARMED' /></div>
        } else {
            return <div className={classes.disarmedText}><FormattedMessage id='ROBOTCARD.DISARMED' /></div>
        }
    }

    renderOperationState() {
        switch (this.props.robot.operationMode) {
            case operationMode.MANUAL_MODE:
                return 'Manual';
            case operationMode.STABILIZE_MODE:
                return 'Stabilize';
            case operationMode.ALT_HOLD_MODE:
                return 'Depth';
            case operationMode.POS_HOLD_MODE:
                return 'Position';
            default:
                return 'NA';
        }
    }

    renderCardHeader() {
        const { classes } = this.props;
        return (
            <div className={classes.cardHeader}>
                <Typography
                    className={classes.cardTitle}
                    variant="h4"
                >
                    {this.props.robot.modelId}
                </Typography>
                <div className={classes.widget}>
                    <span>{this.props.robot.hasOwnProperty('armed') ? this.renderArmState() : 'NA'}</span>
                </div>
                <div className={classes.widget}>
                    <span>{this.props.robot.hasOwnProperty('operationMode') ? this.renderOperationState() : 'NA'}</span>
                </div>
                {this.renderHeaderButtons()}
            </div>
        );
    }

    renderCardBody() {
        const { classes } = this.props;

        let body = (
            <div className={classes.noVideo}>
                <Typography>
                    <FormattedMessage id='ROBOTCARD.NO_VIDEO_CONNECTION' />
                </Typography>
            </div>
        );

        if (this.state.isStreaming) {
            body = (
                <div>
                    {this.props.isLiveMode ? this.renderLiveStream() : this.renderSimulationStream()}
                </div>
            );
        }

        return (
            <div className={classes.cardBody}>
                {body}
                {this.renderInfoColumn()}
                {this.renderInstruments()}
            </div>
        );
    }

    getJoystickLock = async () => {
        const { currentUser, robot } = this.props;

        try {
            const response = await Axios({
                method: 'get',
                url: `https://${robot.ipAddress}/api/v1/get_jtoken`,
                headers: {
                    Authorization: 'Bearer ' + currentUser.token,
                },
                params: {
                    jToken: localStorage.getItem('jToken'),
                },
            })

            this.setState({
                validToken: localStorage.getItem('jToken') === response.data.jToken && response.data.jToken !== 'None',
                isInControl: response.data.hasOwnProperty('jToken') && response.data.jToken !== 'None',
            });
        } catch (error) {
            this.setState({ validToken: false }, () => {
                if (error.response && error.response.status === statusCode.UNAUTHORIZED) {
                    this.props.toggleFeedbackHidden();
                    this.props.setFeedbackMessage(<FormattedMessage id="ERROR.UNAUTHORIZED" />);
                }
            });
        }
    };

    renderRobotCard() {
        const { classes } = this.props;

        return (
            <Card
                id="robot_card_container"
                className={classes.root}
                variant="outlined"
                raised={true}
            >
                <div className={classnames(classes.robotCard, {
                    [classes.noStreamingRobotCardHeader]: !this.state.isStreaming,
                    [classes.rovMode]: (this.props.robot.hasOwnProperty('isRovMode') && this.props.robot.isRovMode) || this.state.validToken,
                    [classes.isInControl]: !this.state.validToken && this.state.isInControl,
                })}
                >
                    {this.renderCardHeader()}
                    {this.renderCardBody()}
                </div>
            </Card>
        );
    }

    render() {
        return (
            <Grow in={this.state.showComponent}>
                {this.renderRobotCard()}
            </Grow>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    robots: selectRobots,
    simulationRobots: selectSimulationRobots,
    isLiveMode: selectIsLive,
    currentUser: selectCurrentUser,
    currentlyDrivingRobot: selectCurrentlyDrivingRobot,
    currentRobot: selectCurrentRobot,
});

const mapDispatchToProps = dispatch => ({
    setCurrentRobot: (robot) => dispatch(setCurrentRobot(robot)),
    toggleSettingsDrawer: (robot) => dispatch(toggleSettingsDrawer(robot)),
    addSimulationRobotStreamer: (robot) => dispatch(addSimulationRobotStreamer(robot)),
    removeSimulationRobotStreamers: (robot) => dispatch(removeSimulationRobotStreamer(robot)),
    toggleFeedbackHidden: () => dispatch(toggleFeedbackHidden()),
    setFeedbackMessage: message => dispatch(setFeedbackMessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles, { withTheme: true })(RobotCard)));
