import {createSelector} from "reselect";

const selectSection = state => state.section;

export const selectIsDrawing = createSelector(
    [selectSection],
    section => section.isDrawing,
);

export const selectIsClearing = createSelector(
    [selectSection],
    section => section.isClearing,
);

export const selectCustomSectionName = createSelector(
    [selectSection],
    section => section.customSectionName,
);

export const selectCanSend = createSelector(
    [selectSection],
    section => section.canSend,
);

export const selectIsSendingSection = createSelector(
    [selectSection],
    section => section.isSending,
);
