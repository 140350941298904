import * as THREE from 'three';
import Environment from './Environment';
import { GridSettings, PoolSettings } from './OperationalValues';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';

class PoolGrid extends Environment {

    constructor(assets = null) {
        super(assets);
        this.mesh = null;
    }

    generate() {
        this.mesh = new THREE.Group();
        this.mesh.name = 'grid';
        this.generateGrid();
        this.generateCenterLines();
    }

    generateGrid() {
        let gridMaterial = new THREE.LineBasicMaterial(
            { color: GridSettings.COLOR_GRID, transparent: GridSettings.TRANSPARENT, opacity: GridSettings.OPACITY });

        // Horizontal lines on wall
        let wallPath = new THREE.Path();
        wallPath.lineTo(0, 0);
        wallPath.lineTo(0, -PoolSettings.LENGTH + PoolSettings.RADIUS);
        wallPath.absarc(PoolSettings.RADIUS, -PoolSettings.LENGTH +
            PoolSettings.RADIUS, PoolSettings.RADIUS, Math.PI,
            Math.PI + Math.PI / 2, false);
        wallPath.lineTo(PoolSettings.WIDTH - PoolSettings.RADIUS, -PoolSettings.LENGTH);
        wallPath.absarc(PoolSettings.WIDTH - PoolSettings.RADIUS,
            -PoolSettings.LENGTH + PoolSettings.RADIUS, PoolSettings.RADIUS,
            Math.PI + Math.PI / 2, 2 * Math.PI, false);
        wallPath.lineTo(PoolSettings.WIDTH, 0);
        wallPath.lineTo(0, 0);

        let wallPoints = wallPath.getPoints();
        let wallGeometry = new THREE.BufferGeometry().setFromPoints(wallPoints);
        wallGeometry.rotateX(Math.PI / 2);
        wallGeometry.translate(0, PoolSettings.RADIUS, -PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH);
        let wallLine = new THREE.Line(wallGeometry, gridMaterial);
        for (let i = 0; i < PoolSettings.HEIGHT; i += GridSettings.DISTANCE) {
            wallLine.position.y = i;
            this.mesh.add(wallLine.clone());
        }
        if (wallLine.position.y < PoolSettings.HEIGHT) {
            wallLine.position.y = PoolSettings.HEIGHT - PoolSettings.RADIUS;
            this.mesh.add(wallLine);
        }

        // Lines along length
        let lengthPath = new THREE.Path();
        lengthPath.lineTo(0, PoolSettings.HEIGHT);
        lengthPath.lineTo(0, 0);
        lengthPath.lineTo(PoolSettings.LENGTH - PoolSettings.RADIUS, 0);
        lengthPath.absarc(PoolSettings.LENGTH - PoolSettings.RADIUS,
            PoolSettings.RADIUS, PoolSettings.RADIUS,
            Math.PI + Math.PI / 2, 2 * Math.PI, false);
        lengthPath.lineTo(PoolSettings.LENGTH, PoolSettings.HEIGHT);

        let lengthPoints = lengthPath.getPoints();
        let lengthGeometry = new THREE.BufferGeometry().setFromPoints(lengthPoints);
        lengthGeometry.rotateY(Math.PI / 2);
        lengthGeometry.translate(0, 0, -PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH);
        let lengthLine = new THREE.Line(lengthGeometry, gridMaterial);
        for (let i = PoolSettings.WIDTH / 2 + GridSettings.DISTANCE, j = PoolSettings.WIDTH / 2 - GridSettings.DISTANCE; i <= PoolSettings.WIDTH - PoolSettings.RADIUS && j >= PoolSettings.RADIUS; i += GridSettings.DISTANCE, j -= GridSettings.DISTANCE) {
            lengthLine.position.x = i;
            this.mesh.add(lengthLine.clone());
            lengthLine.position.x = j;
            this.mesh.add(lengthLine.clone());
        }
        if (lengthLine.position.x < PoolSettings.WIDTH - PoolSettings.RADIUS) {
            lengthLine.position.x = PoolSettings.WIDTH - PoolSettings.RADIUS;
            this.mesh.add(lengthLine.clone());
            lengthLine.position.x = PoolSettings.RADIUS;
            this.mesh.add(lengthLine.clone());
        }

        // Lines along width
        let widthPath = new THREE.Path();
        widthPath.moveTo(0, PoolSettings.HEIGHT);
        widthPath.lineTo(0, PoolSettings.RADIUS);
        widthPath.absarc(PoolSettings.RADIUS, PoolSettings.RADIUS, PoolSettings.RADIUS,
            Math.PI, Math.PI + Math.PI / 2, false);
        widthPath.lineTo(PoolSettings.WIDTH - PoolSettings.RADIUS, 0);
        widthPath.absarc(PoolSettings.WIDTH - PoolSettings.RADIUS, PoolSettings.RADIUS, PoolSettings.RADIUS,
            Math.PI + Math.PI / 2, 2 * Math.PI, false);
        widthPath.lineTo(PoolSettings.WIDTH, PoolSettings.HEIGHT);

        let widthPoints = widthPath.getPoints();
        let widthGeometry = new THREE.BufferGeometry().setFromPoints(widthPoints);
        widthGeometry.translate(0, 0,
            -PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH);
        let widthLine = new THREE.Line(widthGeometry, gridMaterial);
        for (let i = PoolSettings.LENGTH / 2 + GridSettings.DISTANCE, j = PoolSettings.LENGTH / 2 - GridSettings.DISTANCE; i < PoolSettings.LENGTH - PoolSettings.RADIUS && j >= 0; i += GridSettings.DISTANCE, j -= GridSettings.DISTANCE) {
            if (i < PoolSettings.LENGTH - PoolSettings.RADIUS) {
                widthLine.position.z = -i;
                this.mesh.add(widthLine.clone());
            }
            if (j >= 0) {
                widthLine.position.z = -j;
                this.mesh.add(widthLine.clone());
            }
        }
        widthLine.position.z = -PoolSettings.LENGTH + PoolSettings.RADIUS;
        this.mesh.add(widthLine.clone());
        widthLine.position.z = 0;
        this.mesh.add(widthLine.clone());
    }

    generateCenterLines() {
        let material = new LineMaterial({
            color: GridSettings.COLOR_CENTER,
            linewidth: GridSettings.CENTER_LINE_DIM,
            dashed: false
        });

        let widthPath2D = new THREE.Path();
        widthPath2D.moveTo(0, PoolSettings.HEIGHT);
        widthPath2D.lineTo(0, PoolSettings.RADIUS);
        widthPath2D.absarc(PoolSettings.RADIUS, PoolSettings.RADIUS, PoolSettings.RADIUS,
            Math.PI, Math.PI + Math.PI / 2, false);
        widthPath2D.lineTo(PoolSettings.WIDTH - PoolSettings.RADIUS, 0);
        widthPath2D.absarc(PoolSettings.WIDTH - PoolSettings.RADIUS, PoolSettings.RADIUS, PoolSettings.RADIUS,
            Math.PI + Math.PI / 2, 2 * Math.PI, false);
        widthPath2D.lineTo(PoolSettings.WIDTH, PoolSettings.HEIGHT);

        let widthPath3D = [];
        for (let point in widthPath2D.getPoints())
            widthPath3D.push(widthPath2D.getPoints()[point].x, widthPath2D.getPoints()[point].y,
                -PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH - PoolSettings.LENGTH / 2);
        let widthGeo = new LineGeometry();
        widthGeo.setPositions(widthPath3D);

        let widthLine = new Line2(widthGeo, material);
        widthLine.computeLineDistances();
        widthLine.scale.set(1, 1, 1);
        this.mesh.add(widthLine);

        let lengthPath2D = new THREE.Path();
        lengthPath2D.moveTo(PoolSettings.LENGTH_TOTAL - PoolSettings.LENGTH, PoolSettings.HEIGHT);
        lengthPath2D.lineTo(PoolSettings.LENGTH_TOTAL - PoolSettings.LENGTH, 0);
        lengthPath2D.lineTo(PoolSettings.LENGTH_TOTAL - PoolSettings.RADIUS, 0);
        lengthPath2D.absarc(PoolSettings.LENGTH_TOTAL - PoolSettings.RADIUS,
            PoolSettings.RADIUS, PoolSettings.RADIUS,
            Math.PI + Math.PI / 2, 2 * Math.PI, false);
        lengthPath2D.lineTo(PoolSettings.LENGTH_TOTAL, PoolSettings.HEIGHT);

        let lengthPath3D = [];
        for (let point in lengthPath2D.getPoints())
            lengthPath3D.push(PoolSettings.WIDTH /
                2, lengthPath2D.getPoints()[point].y, -lengthPath2D.getPoints()[point].x);
        let lengthGeo = new LineGeometry();
        lengthGeo.setPositions(lengthPath3D);

        let lengthLine = new Line2(lengthGeo, material);
        lengthLine.computeLineDistances();
        lengthLine.scale.set(1, 1, 1);
        this.mesh.add(lengthLine);
    }

}

export default PoolGrid;