import { WaterSettings } from './componenets/OperationalValues';

const decimalToHashString = (dec) => {
    let hashString = dec.toString(16);
    //necessary because js produces strings on form #ff when css requires them on form #0000ff
    while (hashString.length < 6) {
        hashString = '0'.concat(hashString);
    }
    return '#'.concat(hashString);
};

const styles = theme => ({
    threeScene: {
        display: 'flex',
        position: 'absolute',
        width: '100%'
    },
    alterGridPanel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    gridRange: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    gridSizeSlider: {
        width: '70%'
    },
    gridInputContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    gridInput: {
        paddingLeft: '20px',
        width: '60px'
    },
    waterLevelLabelsContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '20px'
    },
    flexRow100PercentWidth: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexRow100PercentWidthJustBet: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    flexCol100PercentWidth: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    justBet: {
        justifyContent: 'space-between',
        marginBottom: 2
    },
    waterLevelLabelContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    waterLevelLabel: {
        marginRight: '12px',
        marginLeft: '10px',
        width: '100px'
    },
    waterLevelLabelIndent: {
        marginLeft: '36px',
        fontStyle: 'italic',
        fontWeight: 600
    },
    waterLevelColumns: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingLeft: '20px'
    },
    sceneExpansionContainer: {
        margin: 10,
        width: '400px'
    },
    infoContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        top: '50px',
        zIndex: '1',
        overflow: 'auto'
    },
    waterLevelInfo: {
        flexDirection: 'column',
        padding: 20
    },
    buttonBar: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '2px',
        paddingBottom: '2px',
        display: 'flex',
        position: 'relative',
        borderTopWidth: '5px'
    },
    justAround: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    sceneToolbarTop: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    currentIcon: {
        transform: 'rotate(50deg)',
        color: decimalToHashString(WaterSettings.COLOR_NOW),
    },
    averageIcon: {
        transform: 'rotate(50deg)',
        color: decimalToHashString(WaterSettings.COLOR_AVG),
    },
    highTideIcon: {
        transform: 'rotate(50deg)',
        color: decimalToHashString(WaterSettings.COLOR_HIGH),
    },
    lowTideIcon: {
        transform: 'rotate(50deg)',
        color: decimalToHashString(WaterSettings.COLOR_LOW),
    }
});

export {
    styles,
};
