import React from 'react';
import PropTypes from 'prop-types';
import { Stage, Layer, Image, Text } from 'react-konva';
import headingYaw from '../../../../static/images/heading_yaw.svg';
import headingMechanics from '../../../../static/images/heading_mechanics_vessel.svg';

class HeadingInstrument extends React.Component {
    static propTypes = {
        heading: PropTypes.number,
        textColor: PropTypes.string,
        textSize: PropTypes.number,
        scaleFactor: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
    };

    static defaultProps = {
        heading: 0,
        textColor: '#000',
        textSize: 14,
        scaleFactor: 1.0,
        width: 200,
        height: 200,
    };

    state = {
        headingYaw: null,
        headingMechanics: null,
    };

    componentDidMount() {
        this.loadImages();
    }

    componentWillUnmount() {
        this.headingYaw.removeEventListener('load', this.handleLoadYaw);
        this.headingMechanics.removeEventListener('load', this.handleLoadMechanics);
    }

    loadImages() {
        this.headingYaw = new window.Image();
        this.headingYaw.src = headingYaw;
        this.headingYaw.addEventListener('load', this.handleLoadYaw);

        this.headingMechanics = new window.Image();
        this.headingMechanics.src = headingMechanics;
        this.headingMechanics.addEventListener('load', this.handleLoadMechanics);
    }

    handleLoadYaw = () => {
        this.setState({
            headingYaw: this.headingYaw,
        });
    };

    handleLoadMechanics = () => {
        this.setState({
            headingMechanics: this.headingMechanics,
        });
    };

    render() {
        if (this.state.headingYaw !== null && this.state.headingMechanics !== null) {
            return (
                <Stage
                    width={this.props.width}
                    height={this.props.height}
                    scale={{
                        x: this.props.scaleFactor,
                        y: this.props.scaleFactor,
                    }}
                >
                    <Layer>
                        <Text
                            text={'Yaw: ' + this.props.heading + '\xB0'}
                            fontSize={this.props.textSize}
                            fontFamily={'Lato, sans-serif'}
                            x={85}
                            y={185}
                            fill={this.props.textColor}
                        />
                        <Image
                            x={this.state.headingYaw.x + this.state.headingYaw.width / 2}
                            y={this.state.headingYaw.y + this.state.headingYaw.height / 2}
                            offsetX={this.state.headingYaw.width / 2}
                            offsetY={this.state.headingYaw.height / 2}
                            image={this.state.headingYaw}
                            rotation={this.props.heading}
                        />
                        <Image
                            x={this.state.headingMechanics.x + this.state.headingMechanics.width / 2}
                            y={this.state.headingMechanics.y + this.state.headingMechanics.height / 2}
                            offsetX={this.state.headingMechanics.width / 2}
                            offsetY={this.state.headingMechanics.height / 2}
                            image={this.state.headingMechanics}
                            rotation={0}
                        />
                    </Layer>
                </Stage>
            );
        }
        return null;
    }
}

export default HeadingInstrument;
