module.exports = {
    locale: 'nb-NO',
    messages: {
        'ROBOTCARD.ROV_RUN_COUNT': 'Rov rundeteller',
        'ROBOTCARD.DEPTH_TO_SURFACE': 'Dybde: på vei til overflaten',
        'ROBOTCARD.ROV_TEMPERATURE': 'Rov temperatur',
        'ROBOTCARD.WEATHER_TEMPERATURE': 'Vann temperature',
        'ROBOTCARD.DRONE_SPEED': 'Rov hastighet',
        'ROBOTCARD.OPERATION_TIME': 'Operasjonstid',
        'ROBOTCARD.SETTINGS_DRAWER': 'Åpne settings drawer',
        'ROBOTCARD.NO_VIDEO_CONNECTION': 'Ingen videoforbinnelse',
        'ROBOTCARD.ARMED': 'Armed',
        'ROBOTCARD.DISARMED': 'Disarmed',
        'OVERVIEW_CONTAINER.SIMULATION_MODE_BANNER': 'Simulatormodus',
        'ROBOTCARD.TETHER_TURN': 'Kabelsving',
        'ROBOTCARD.TETHER_TURN_CLOCKWISE': '{turns} med urviseren',
        'ROBOTCARD.JOY_GAIN': 'Joystick gain',

        'ROBOTCARD.RUN_COUNTER': 'd[days] h[h] m[m] s[s]',
    },
};
