import React from 'react';
import { FormattedMessage } from 'react-intl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { createStructuredSelector } from 'reselect';
import { selectRobots, selectCurrentRobot } from '../../../redux/robot/robotSelector';
import { setCurrentRobot, toggleSettingsDrawer } from '../../../redux/robot/robotActions';
import { connect } from 'react-redux';
import {
    resetCustomSectionName,
    toggleIsClearing,
    toggleIsDrawing,
    toggleSendCustomSection
} from '../../../redux/section/sectionActions';
import { selectCurrentSite } from '../../../redux/site/siteSelector';
import {
    selectCanSend,
    selectCustomSectionName,
    selectIsClearing,
    selectIsDrawing
} from '../../../redux/section/sectionSelector';

import { styles } from './SettingsDrawerStyles'

class CustomSectionTool extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customSectionName: '',
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.globalCustomSectionName.length !== 0 && this.props.globalCustomSectionName.length === 0) {
            this.setState({
                customSectionName: '',
            });
        }
    }

    handleDrawClick = () => {
        if (this.props.isDrawing) {
            this.setState({
                customSectionName: '',
            });
            this.props.resetCustomSectionName();
        }

        this.props.toggleIsDrawing();
    }

    handleClearClick = () => {
        if(this.props.isDrawing === true){
            this.props.toggleIsClearing();
        }
    }

    handleChangeCustomSectionName = (event) => {
        this.setState({
            customSectionName : event.target.value,
        });
    }

    handleSendCustomSection = () => {
        this.props.toggleSendCustomSection(this.state.customSectionName);
    }

    render() {
        const { classes } = this.props;

        const inputCustomSectionName = (
            <div className={'selector1'}>
                <TextField
                    id='inputCustomSectionNameCard'
                    label={<FormattedMessage id='CUSTOMSECTIONTOOL.SECTION_NAME' />}
                    className={classes.customSectionNameInput}
                    variant='outlined'
                    onChange={this.handleChangeCustomSectionName}
                    value={this.state.customSectionName}
                    helperText={<FormattedMessage id='CUSTOMSECTIONTOOL.SECTION_NAME_HELPER_TEXT' />}
                />
            </div>
        );

        const drawClick = (
            <Button
                onClick={this.handleDrawClick}
                variant='contained'
                className={'selector2'}
                disabled={this.state.customSectionName.length < 3}
                color="primary"
            >
                {this.props.isDrawing ?
                    <FormattedMessage id='CUSTOMSECTIONTOOL.QUIT_DRAWING' />
                    : <FormattedMessage id='CUSTOMSECTIONTOOL.DRAW' />}
            </Button>
        );

        const clearSelection = (
            <Button
                onClick={this.handleClearClick}
                variant='contained'
                disabled={!this.props.isDrawing}
                color="primary"
            >
                <FormattedMessage id='CUSTOMSECTIONTOOL.CLEAR_SECTION' />
            </Button>
        );

        const sendCustomSection = (
            <Button
                onClick={this.handleSendCustomSection}
                variant='contained'
                className={'selector3'}
                disabled={!this.props.canSend}
                color="primary"
            >
                <FormattedMessage id='CUSTOMSECTIONTOOL.CREATE_CUSTOM_SECTION' />
            </Button>
        );

        return (
            <div className={classes.customSectionTool}>
                <InputLabel>
                    <FormattedMessage id='CUSTOMSECTIONTOOL.CREATE_CUSTOM_SECTION' />
                </InputLabel>
                {inputCustomSectionName}
                <div className={classes.customSectionButtonRow}>
                    {drawClick}
                    {sendCustomSection}
                    {clearSelection}
                </div>
            </div>
        );
    }

}

const mapStateToProps = createStructuredSelector({
    robots: selectRobots,
    isDrawing: selectIsDrawing,
    isClearing: selectIsClearing,
    canSend: selectCanSend,
    globalCustomSectionName: selectCustomSectionName,
    currentRobot: selectCurrentRobot,
    currentSite: selectCurrentSite,
});


const mapDispatchToProps = dispatch => ({
    setCurrentRobot: (robot) => dispatch(setCurrentRobot(robot)),
    toggleSettingsDrawer: (robot) => dispatch(toggleSettingsDrawer(robot)),
    toggleIsDrawing: () => dispatch(toggleIsDrawing()),
    toggleIsClearing: () => dispatch(toggleIsClearing()),
    toggleSendCustomSection: (customSectionName) => dispatch(toggleSendCustomSection(customSectionName)),
    resetCustomSectionName: () => dispatch(resetCustomSectionName()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomSectionTool));