const styles = theme => ({
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(),
        paddingRight: theme.spacing(),
        paddingBottom: theme.spacing(),
        paddingLeft: theme.spacing(10),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    infoIcon: {
        color: '#ffae42',
    },
    avatar: {
        width: 24,
        height: 24,
    },
    connectionStatusIcon: {
        padding: 12,
        color: theme.customPalette.darkGreen,
    },
    disconnectionStatusIcon: {
        padding: 12,
        color: theme.customPalette.red,
    },
    tooltipContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: '1.1em',
    },
    tooltipDisconnect: {
        fontStyle: 'italic',
        fontWeight: 600,
    },
    robotListItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: '20px',
        paddingLeft: '30px'
    },
    spawnButton: {
        color: 'green',
        width: '90px',
    },
    removeButton: {
        color: 'red',
        width: '90px',
    },
    robotControlDialog: {
        width: '600px',
    },
    robotTypographyRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '270px',
    },
});

export {
    styles,
};
