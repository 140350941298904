module.exports = {
	locale: 'en-GB',
	messages: {
		'THREESCENE.RESET_CAMERA_LABEL': 'Reset camera',
		'THREESCENE.SHOW_WATER_LABEL': 'Show water',
		'THREESCENE.HIDE_WATER_LABEL': 'Hide water',
		'THREESCENE.SHOW_GRID_LABEL': 'Show grid',
		'THREESCENE.HIDE_GRID_LABEL': 'Hide grid',
		'THREESCENE.SHOW_WAVES_LABEL': 'Show waves',
		'THREESCENE.INFORMATION_MENU_LABEL': 'Information',
		'THREESCENE.WATER_LEVELS_LABEL': 'Water levels',
		'THREESCENE.CURRENT_LABEL': 'Now:',
		'THREESCENE.AVERAGE_LABEL': 'Average:',
		'THREESCENE.HIGH_TIDE_LABEL': 'High tide:',
		'THREESCENE.OCCURS_AT_LABEL': 'Occurs at:',
		'THREESCENE.LOW_TIDE_LABEL': 'Low tide:',
		'THREESCENE.SWITCH_TO_PLAYMODE': 'Switch to play mode',
		'THREESCENE.SWITCH_TO_LIVEMODE': 'Switch to live mode',
		'THREESCENE.CONTROL_MENU': 'Control menu',
		'THREESCENE.ALTER_GRID_SIZE': 'Alter grid size:',
		'THREESCENE.FLOOR': 'Floor',
		'THREESCENE.INLET': 'Inlet',
		'THREESCENE.OUTLET': 'Outlet',
		'THREESCENE.LEFT_WALL': 'Left Wall',
		'THREESCENE.RIGHT_WALL': 'Right Wall',
		'THREESCENE.SECTION_MUST_BE_2D': 'Section must be a 2D shape i.e. on one side of the pool',
		'THREESCENE.ALLREADY_FOUR_POINTS': 'You already have 4 points, press send or clear selection to start over',
		'THREESCENE.SECTION_NAME_ALLREADY_IN_USE': 'You already have a section with that name',
		'THREESCENE.LOCAL_STORAGE_NOT_SUPPORTED': 'Error: could not save section because localStorage is not supported.\n' +
			'https://caniuse.com/#feat=mdn-api_window_localstorage\n' +
			'https://www.whatismybrowser.com/detect/?utm_source=whatismybrowsercom&utm_medium=internal&utm_campaign=breadcrumbs',
		'THREESCENE.SUCCESSFULLY_CREATED_CUSTOM_SECTION': 'Successfully created section "{sectionName}" and added it to the list of predefined sections',
		'THREESCENE.SUCCESSFULLY_SENT_PREDEFINED_SECTION': 'Successfully sent section "{sectionName}" to robot "{robotId}"',
	}
};
