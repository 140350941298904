import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import userReducer from './user/userReducer';
import robotReducer from './robot/robotReducer';
import feedbackReducer from './feedback/feedbackReducer';
import siteReducer from './site/siteReducer';
import sectionReducer from "./section/sectionReducer";
import modeReducer from "./mode/modeReducer";

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['robot', 'site'],
};

const rootReducer = combineReducers({
    user: userReducer,
    robot: robotReducer,
    feedback: feedbackReducer,
    site: siteReducer,
    section: sectionReducer,
    mode: modeReducer,
});

export default persistReducer(persistConfig, rootReducer);
