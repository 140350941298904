import { createMuiTheme } from '@material-ui/core/styles';

const darkBackground = '#454545';
const lightBackground = '#ffffff';

const dayTheme = createMuiTheme({
    mediaMaxWidthMenu: '@media (max-width: 350px)',
    mediaMaxWidthSmall: '@media (max-width: 360px)',
    mediaMaxWidthMedium: '@media (max-width: 740px)',
    mediaMaxWidthLarge: '@media (max-width: 1024px)',
    customPalette: {
        white: '#FFFFFF',
        lightGreen: '#71EA72',
        green: '#8BD88C',
        darkGreen: '#67AF68',
        blue: '#51AFE3',
        darkBlue: '#00456E',
        greyE4: '#E4E4E4',
        greyC7: '#C7C7C7',
        greyC0: '#C0C0C0',
        grey95: '#959595',
        grey66: '#666666',
        grey59: '#595959',
        black: '#000000',
        backdropBlack: 'rgba(0, 0, 0, 0.8)',
        yellow: '#FFD783',
        orange: '#FAA060',
        darkOrange: '#FF8C33',
        red: '#E17275',
        threeBackground: '#BFD1E5',
        borderColor: 'rgba(0, 0, 0, 0.12)'
    },
    palette: {
        type: 'light',
        background: {
            default: lightBackground,
        },
        primary: {
            main: '#DBE7EA',
            contrastText: '#454545',
        },
        secondary: {
            main: '#009688',
        },
    },
    typography: {
        fontFamily: 'Lato, sans-serif',
        useNextVariants: true,
        fontSize: 14,
        htmlFontSize: 14,
        title: {
            fontSize: '2.3rem',
            color: '#000000',
            fontWeight: 'bold',
            '@media (max-width: 740px)': {
                fontSize: '1.8rem',
            },
        },
        subheading: {
            fontSize: '0.8rem',
            color: 'inherit',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            lineHeight: '1.2em',
        },
        headline: {
            fontSize: '1rem',
            color: '#959595',
            textTransform: 'uppercase',
            '@media (max-width: 740px)': {
                fontSize: '0.8rem',
            },
        },
        body1: {
            color: '#000000',
        },
        body2: {
            color: '#FFFFFF',
        },
        caption: {
            fontSize: '0.8rem',
            color: 'inherit',
        },
        display1: {
            fontSize: '1.5rem',
            color: 'inherit',
            '@media (max-width: 740px)': {
                fontSize: '1rem',
            },
        },
        display2: {
            color: '#FFFFFF',
            fontSize: '1rem',
            fontWeight: 'bold',
            marginTop: 5,
        },
        display3: {
            color: 'inherit',
            zIndex: 0,
        },
        button: {
            textTransform: 'none',
        }
    },
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: lightBackground,
            },
        },
        MuiSnackbarContent: {
            root: {
                '@media (min-width: 960px)': {
                    maxWidth: 'unset',
                },
            },
        },
        MuiMenu: {
            list: {
                display: 'flex',
                flexDirection: 'column',
            },
        },
        MuiFormHelperText: {
            root: {
                color: darkBackground,
            },
        },
        MuiMenuItem: {
            root: {
                minHeight: 36,
            },
        },
        MuiSwitch: {
            root: {
                width: 38,
                height: 20,
                padding: 0,
                display: 'inline-flex',
            },
            switchBase: {
                padding: 2,
                color: {},
                '&$checked': {
                    transform: 'translateX(18px)',
                    color: '#5ea135',
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: darkBackground,
                        borderColor: darkBackground,
                    },
                },
            },
            thumb: {
                width: 16,
                height: 16,
                boxShadow: 'none',
            },
            track: {
                border: `1px solid ${darkBackground}`,
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: '#fff',
            },
            checked: {},
        },
        MuiButton: {
            textPrimary: {
                color: 'initial',
            },
        },
        MuiTableCell: {
            root: {
                padding: 5,
            },
        },
        MuiSlider: {
            thumb: {
                color: "#2185d0",
            },
            track: {
                color: "#2185d0"
            },
            rail: {
                color: "#2185d0"
            }
        },
    },
});

const darkTheme = createMuiTheme({
    mediaMaxWidthMenu: '@media (max-width: 350px)',
    mediaMaxWidthSmall: '@media (max-width: 360px)',
    mediaMaxWidthMedium: '@media (max-width: 740px)',
    mediaMaxWidthLarge: '@media (max-width: 1024px)',
    customPalette: {
        white: '#FFFFFF',
        lightGreen: '#71EA72',
        green: '#8BD88C',
        darkGreen: '#67AF68',
        blue: '#51AFE3',
        darkBlue: '#51AFE3',
        greyE4: '#E4E4E4',
        greyC7: '#C7C7C7',
        greyC0: '#C0C0C0',
        grey95: '#959595',
        grey66: '#666666',
        grey59: '#595959',
        black: '#000000',
        backdropBlack: 'rgba(0, 0, 0, 0.8)',
        yellow: '#FFD783',
        orange: '#FAA060',
        darkOrange: '#FF8C33',
        red: '#E17275',
        threeBackground: '#414852',
        borderColor: 'rgba(255, 255, 255, 0.12)'
    },
    palette: {
        type: 'dark',
        background: {
            default: darkBackground,
        },
        primary: {
            main: '#37474f',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#263238',
        },
    },
    typography: {
        fontFamily: 'Lato, sans-serif',
        useNextVariants: true,
        fontSize: 14,
        htmlFontSize: 14,
        title: {
            fontSize: '2.3rem',
            color: '#FFFFFF',
            fontWeight: 'bold',
            '@media (max-width: 740px)': {
                fontSize: '1.8rem',
            },
        },
        subheading: {
            fontSize: '0.8rem',
            color: '#FFFFFF',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            lineHeight: '1.2em',
        },
        headline: {
            fontSize: '1rem',
            color: '#959595',
            textTransform: 'uppercase',
            '@media (max-width: 740px)': {
                fontSize: '0.8rem',
            },
        },
        body1: {
            color: '#FFFFFF',
        },
        body2: {
            color: '#FFFFFF',
        },
        caption: {
            fontSize: '0.8rem',
            color: 'inherit',
        },
        display1: {
            fontSize: '1.5rem',
            color: 'inherit',
            '@media (max-width: 740px)': {
                fontSize: '1rem',
            },
        },
        display2: {
            color: '#000000',
            fontSize: '1rem',
            fontWeight: 'bold',
            marginTop: 5,
        },
        display3: {
            color: 'inherit',
            zIndex: 0,
        },
        button: {
            textTransform: 'none',
        }
    },
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: darkBackground,
            },
        },
        MuiSnackbarContent: {
            root: {
                '@media (min-width: 960px)': {
                    maxWidth: 'unset',
                },
            },
        },
        MuiMenu: {
            list: {
                display: 'flex',
                flexDirection: 'column',
            },
        },
        MuiFormHelperText: {
            root: {
                color: lightBackground,
            },
        },
        MuiMenuItem: {
            root: {
                minHeight: 36,
            },
        },
        MuiSwitch: {
            root: {
                width: 38,
                height: 20,
                padding: 0,
                display: 'inline-flex',
            },
            switchBase: {
                padding: 2,
                color: {},
                '&$checked': {
                    transform: 'translateX(18px)',
                    color: '#5ea135',
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: '#666',
                        borderColor: '#666',
                    },
                },
            },
            thumb: {
                width: 16,
                height: 16,
                boxShadow: 'none',
            },
            track: {
                border: `1px solid ${lightBackground}`,
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: '#666',
            },
            checked: {},
        },
        MuiButton: {
            textPrimary: {
                color: 'initial',
            },
        },
        MuiTableCell: {
            root: {
                padding: 5,
            },
        },
        MuiSlider: {
            thumb: {
                color: "#d6d6d6",
            },
            track: {
                color: "#d6d6d6"
            },
            rail: {
                color: "#d6d6d6"
            }
        },
    },
});

export {
    dayTheme,
    darkTheme,
};
