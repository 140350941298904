import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import withStyles from '@material-ui/core/styles/withStyles';
import openSocket from 'socket.io-client';
import Split from 'react-split';
import classnames from 'classnames';

import { firestore } from '../../../firebase/Firebase';
import { selectRobot, setRobots, unselectAllRobots, unselectRobot } from '../../../redux/robot/robotActions';
import { setCurrentSite } from '../../../redux/site/siteActions';
import { selectCurrentlyDrivingRobot, selectRobots, selectSelectedRobots } from '../../../redux/robot/robotSelector';
import RobotCard from './componenets/RobotCard/RobotCard';

import './OverviewContainer.css';
import { styles } from './OverviewContainerStyles';
import SimulationThreeScene from '../ThreeScene/SimulationThreeScene';
import LiveThreeScene from '../ThreeScene/LiveThreeScene';
import { selectIsJoystickConnected, selectIsLive } from '../../../redux/mode/modeSelector';
import 'github-fork-ribbon-css/gh-fork-ribbon.css';
import { injectIntl } from 'react-intl';
import { joystickButtons, operationMode } from '../../../config';
import { setFeedbackMessage, toggleFeedbackHidden } from '../../../redux/feedback/feedbackActions';
import { toggleJoystickConnected } from '../../../redux/mode/modeActions';
import PlaybackContainer from '../PlaybackContainer';
import { Playback } from '../ThreeScene/componenets/OperationalValues';
import { toggleUseSimulationPlaybackMode } from '../../../redux/mode/modeActions';

const DEADZONE = 0.08;

class OverviewContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notification: [],
            gamepad: null,
            joyConnected: false,
            addDeadZone: true,
            joystickLX: 0.0,
            joystickLY: 0.0,
            joystickRX: 0.0,
            joystickRY: 0.0,
        };
        this.sockets = [];

        this.db = firestore;
        const currentClient = this.props.match.params.clientId;
        this.getSiteAndRobots(currentClient);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.isLiveMode) {
            this.joystickLoop();
        }

        window.joypad.on('connect', e => {
            this.props.toggleJoystickConnected();

        });
        window.joypad.on('disconnect', e => {
            this.props.toggleJoystickConnected();
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isLiveMode !== prevProps.isLiveMode) {
            if (!this.props.isLiveMode && this.sockets.length > 0) {
                this.stopSocketConnection();
            } else {
                this.startSocketConnection();
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.sockets.length > 0) {
            this.stopSocketConnection();
        }

        //clear robots and selected robots
        if (this.props.isLiveMode) {
            this.props.setRobots([]);
        } else {
            this.props.setSimulationRobots([]);
        }
        this.props.unselectAllRobots();
    }

    joystickLoop = () => {
        if (this._isMounted) {
            const gamepad = this.pollGamePads();

            if (gamepad === null) {
                this.setState({
                    gamepad: null,
                    joyConnected: false,
                }, () => {
                    if (this.props.isJoystickConnected) {
                        this.props.toggleJoystickConnected();
                    }
                });
            } else {
                this.setState({
                    gamepad: gamepad,
                    joyConnected: true,
                }, () => {
                    if (!this.props.isJoystickConnected) {
                        this.props.toggleJoystickConnected();
                    }
                });
            }
            if (this.state.joyConnected) {
                this.readJoystickValues();
            }
            window.requestAnimationFrame(() => this.joystickLoop());
        }
    };

    deadZone = (v) => {
        if (Math.abs(v) < DEADZONE) {
            v = 0;
        } else {
            v = v - Math.sign(v) * DEADZONE;
            v /= (1.0 - DEADZONE);
        }
        return v;
    };

    readJoystickValues = () => {
        if (this.state.gamepad) {
            let buttons = this.state.gamepad.buttons;

            let leftX = this.state.gamepad.axes[0];
            let leftY = this.state.gamepad.axes[1];
            let rightX = this.state.gamepad.axes[2];
            let rightY = this.state.gamepad.axes[3];
            let mode = null;
            let armCommand = null;
            let disarmCommand = null;
            let tiltUpCommand = false;
            let tiltDownCommand = false;
            let gainUpCommand = false;
            let gainDownCommand = false;
            let lightsDownCommand = false;
            let lightsUpCommand = false;
            let shiftBtn = false;

            if (this.state.addDeadZone) {
                leftX = this.deadZone(leftX);
                leftY = this.deadZone(leftY);
                rightX = this.deadZone(rightX);
                rightY = this.deadZone(rightY);
            }

            if (buttons[joystickButtons.GAIN_DOWN].pressed && buttons[joystickButtons.SHIFT].pressed) {
            } else if ((buttons[joystickButtons.GAIN_DOWN].pressed)) {
                gainDownCommand = true;
            }
            if (buttons[joystickButtons.GAIN_UP].pressed && buttons[joystickButtons.SHIFT].pressed) {
            } else if ((buttons[joystickButtons.GAIN_UP].pressed)) {
                gainUpCommand = true;
            }
            if (buttons[joystickButtons.CAMERA_TILT_UP].pressed) {
                tiltUpCommand = true;
            }
            if (buttons[joystickButtons.CAMERA_TILT_DOWN].pressed) {
                tiltDownCommand = true;
            }
            if (buttons[joystickButtons.LIGHTS_UP].pressed) {
                lightsUpCommand = true;
            }
            if (buttons[joystickButtons.LIGHTS_DOWN].pressed) {
                lightsDownCommand = true;
            }

            if (buttons[joystickButtons.MAN_MODE].pressed) {
                mode = operationMode.MANUAL_MODE;
            }

            if (buttons[joystickButtons.STABILIZE_MODE].pressed) {
                mode = operationMode.STABILIZE_MODE;
            }

            if (buttons[joystickButtons.ALT_HOLD_MODE].pressed) {
                mode = operationMode.ALT_HOLD_MODE;
            }

            if (buttons[joystickButtons.ARM].pressed) {
                armCommand = true;
            }

            if (buttons[joystickButtons.DISARM].pressed) {
                disarmCommand = true;
            }

            if (buttons[joystickButtons.SHIFT].pressed) {
                shiftBtn = true;
            }

            this.setState({
                joystickLX: leftX * 100,
                joystickLY: leftY * 100,
                joystickRX: rightX * 100,
                joystickRY: rightY * 100,
            }, () => {
                if (this.sockets.length > 0 && this.props.currentlyDrivingRobot !== null && this.state.gamepad !== null) {
                    const cmd = {
                        'forward': this.state.joystickLY,
                        'lateral': this.state.joystickLX,
                        'up_down': -this.state.joystickRY,
                        'yaw': this.state.joystickRX,
                        'mode': mode,
                        'arm': armCommand,
                        'disarm': disarmCommand,
                        'tilt_up': tiltUpCommand,
                        'tilt_down': tiltDownCommand,
                        'gain_up': gainUpCommand,
                        'gain_down': gainDownCommand,
                        'lights_up': lightsUpCommand,
                        'lights_down': lightsDownCommand,
                        'j_token': localStorage.getItem('jToken'),
                        'shift': shiftBtn,
                    };
                    if (this.props.isLiveMode) {
                        this.handleJoystickChange(cmd);
                    }
                }
            });
        }
    };

    pollGamePads = () => {
        let gamepads = navigator.getGamepads();
        return gamepads === null ? null : gamepads[0];
    };

    handleRemoveRobotCard = (robot) => {
        this.props.unselectRobot(robot);
    };

    getSiteAndRobots = async (clientId, index = 0) => {
        const customerRef = this.db.collection('customers');

        if (clientId === 'AC0Cq5d537U0UOpJRuznQs53wBo1' || clientId === 'Xhm1CxqduWcT44kywBGOJVsdk7y2') {
            const querySnapshot = await customerRef.get()

            querySnapshot.forEach((customer) => {
                console.log('logged in as service or admin');
            });
        } else {
            let robots = [];
            const querySnapshot = await customerRef.where('id', '==', clientId).get()

            querySnapshot.forEach((doc) => {
                const client = doc.data();
                client.robots.forEach(robot => {
                    const newRobot = {
                        ...robot,
                        showRobot: true,
                        showTrail: true,
                        showPath: true,
                        showSection: true,
                        trailLength: 100,
                    };
                    robots.push(newRobot);
                });

                this.props.setRobots(robots);
                if (client.hasOwnProperty('sites') && client.sites.length > 0) {
                    const site = client.sites[0];
                    this.props.setCurrentSite(site);
                }
                if (this.props.isLiveMode) {
                    this.startSocketConnection();
                }
            });
        }
    };

    startSocketConnection = () => {
        this.props.robots.forEach((robot, index) => {
            if (robot.hasOwnProperty('ipAddress') && robot.ipAddress !== null) {
                this.sockets[index] = openSocket(`https://${robot.ipAddress}/drift`, { transports: ['websocket'] });
                this.sockets[index].on('drift_values', (msg) => {
                    this.updateRobotValues(robot, index, msg);
                });
                this.sockets[index].on('drift_message', (msg) => {
                    this.props.toggleFeedbackHidden();
                    this.props.setFeedbackMessage(msg.message);
                });
                this.sockets[index].on('joystick_message', (msg) => {
                    console.log(msg);
                });
            }
        });
    };

    stopSocketConnection = () => {
        this.sockets.forEach(socket => {
            if (socket !== null) {
                socket.disconnect();
            }
        });
    }

    updateRobotValues = (robot, index, msg) => {
        if (this.props.robots.length > 0) {
            const robotIndex = this.props.robots.findIndex(r => r.ipAddress === robot.ipAddress);

            if (robotIndex > -1) {
                const updatedRobot = {
                    ...this.props.robots[robotIndex],
                    roll: msg.roll,
                    pitch: msg.pitch,
                    yaw: msg.yaw,
                    depth: msg.depth,
                    runTime: msg.drift_time,
                    temp_robot: msg.temp_robot,
                    temp_water: msg.temp_water,
                    tether_turn: msg.tether_turn,
                    joystick_gain: msg.joystick_gain,
                    position: {
                        x: msg.position.x,
                        y: msg.position.y,
                        z: msg.position.z,
                    },
                    armed: msg.armed,
                    operationMode: msg.mode,
                };
                const newRobots = [].concat(this.props.robots);
                newRobots.splice(robotIndex, 1, updatedRobot);
                this.props.setRobots(newRobots);
            }
        }
    };

    getCorrectSocket = (ipAddress) => {
        if (ipAddress.includes(':')) {
            const port = ipAddress.includes(':') ? ipAddress.split(':')[1] : ipAddress;
            return this.sockets.filter(socket => socket.io.opts.port === port)[0]
        } else {
            return this.sockets.filter(socket => socket.io.opts.hostname === ipAddress)[0]
        }
    };

    handleJoystickChange = (joystick) => {
        const socket = this.getCorrectSocket(this.props.currentlyDrivingRobot.ipAddress);
        socket.emit('joy_cmd', joystick);
    }

    renderRobotCards() {
        if (this.props.selectedRobots.length > 0) {
            return this.props.selectedRobots.map((robot) => {
                let robotCard = robot;
                if (this.props.isLiveMode) {
                    const robotIndex = this.props.robots.findIndex(r => r.ipAddress === robot.ipAddress);
                    robotCard = robotIndex === -1 ? robot : this.props.robots[robotIndex];
                }

                return (
                    <RobotCard
                        key={robotCard.modelId}
                        robot={robotCard}
                        onClose={this.handleRemoveRobotCard}
                    />
                );
            });
        }
        return null;
    }

    handleSliderChange = (value1, value2) => {
        Playback.value1 = value1;
        Playback.value2 = value2;
        this.setState({
            playbackValue1: Playback.value1,
            PlaybackValue2: Playback.value2
        });
    };

    handlePlaying = (action) => {
        if (action) {
            this.props.toggleUseSimulationPlaybackMode();
        } else {
            this.props.toggleUseSimulationPlaybackMode();
        }
    }

    renderSimulationBanner = () => {
        return !this.props.isLiveMode
            ? <span className={'github-fork-ribbon left-bottom'} data-ribbon={this.props.intl.formatMessage({ id: 'OVERVIEW_CONTAINER.SIMULATION_MODE_BANNER' })} />
            : null;
    };

    renderPlayBackContainer() {
        return (
            <PlaybackContainer
                handleSliderChange={this.handleSliderChange}
                onPlayButtonClick={this.handlePlaying}
            />
        );
    }

    render() {
        const { classes, selectedRobots } = this.props;
        const scene = this.props.isLiveMode ?
            <LiveThreeScene /> :
            <SimulationThreeScene />;

        return (
            <Split
                className={classes.contentHorizontal}
                sizes={selectedRobots.length > 0 ? [65, 35] : [100, 0]}
                minSize={0}
                gutterSize={selectedRobots.length > 0 ? 10 : 0}
            >
                <div className={classes.split}>
                    {this.renderSimulationBanner()}
                    {scene}
                </div>
                <div className={classnames(classes.split, classes.robotCardContainer)}>
                    <div className={classes.robotCardRoot}>
                        {this.renderRobotCards()}
                    </div>
                </div>
            </Split>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    robots: selectRobots,
    selectedRobots: selectSelectedRobots,
    currentlyDrivingRobot: selectCurrentlyDrivingRobot,
    isLiveMode: selectIsLive,
    isJoystickConnected: selectIsJoystickConnected,
});

const mapDispatchToProps = dispatch => ({
    toggleUseSimulationPlaybackMode: () => dispatch(toggleUseSimulationPlaybackMode()),
    setRobots: robots => dispatch(setRobots(robots)),
    setSimulationRobots: robots => dispatch(setRobots(robots)),
    setCurrentSite: site => dispatch(setCurrentSite(site)),
    selectRobot: robot => dispatch(selectRobot(robot)),
    unselectRobot: robot => dispatch(unselectRobot(robot)),
    unselectAllRobots: () => dispatch(unselectAllRobots()),
    toggleFeedbackHidden: () => dispatch(toggleFeedbackHidden()),
    setFeedbackMessage: message => dispatch(setFeedbackMessage(message)),
    toggleJoystickConnected: () => dispatch(toggleJoystickConnected()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(OverviewContainer)));
