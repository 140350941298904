module.exports = {
    locale: 'en-GB',
    messages: {
        'CONTROLMENUCONTAINER.CONTROL_MENU': 'Control menu',
        'CONTROLMENUCONTAINER.RESET_CAMERA_LABEL': 'Reset camera',
        'CONTROLMENUCONTAINER.SHOW_WATER_LABEL': 'Show water',
        'CONTROLMENUCONTAINER.HIDE_WATER_LABEL': 'Hide water',
        'CONTROLMENUCONTAINER.SHOW_GRID_LABEL': 'Show grid',
        'CONTROLMENUCONTAINER.HIDE_GRID_LABEL': 'Hide grid',
        'CONTROLMENUCONTAINER.ALTER_GRID_SIZE': 'Alter grid size:',
        'CONTROLMENUCONTAINER.SHOW_WAVES_LABEL': 'Show waves',
    },
};