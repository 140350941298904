import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    GridSettings,
    Operational,
    PoolSettings,
    WaterSettings
} from './componenets/OperationalValues';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Site from './componenets/Site';
import AssetLoader from './componenets/AssetLoader';
import * as HELPER from './Helpers';
import Pool from './componenets/Pool';
import PoolGrid from './componenets/PoolGrid';
import SectionTool from './componenets/SectionTool';
import PoolWater from './componenets/PoolWater';
import SectionToolStatusTypes from './componenets/SectionToolStatusTypes';

const INCLUDE_WALLS = true;

export function sendCustomSection(that) {
    let alertResponse;
    if (that.props.site.sections.filter(s => s.name.toLowerCase().trim() === that.props.customSectionName.toLowerCase().trim()).length > 0) {
        alertResponse = SectionToolStatusTypes.SECTION_NAME_ALLREADY_IN_USE;
    } else {
        alertResponse = that.sectionTool.saveAndSendCustomSectionToPlanningTool(
            that.props.customSectionName,
            that.props.currentRobot.modelId
        );
    }

    switch (alertResponse) {

        case SectionToolStatusTypes.SECTION_MUST_BE_2D:
            createSnackMessage(that, <FormattedMessage id='THREESCENE.SECTION_MUST_BE_2D' />);
            break;
        case SectionToolStatusTypes.SECTION_NAME_ALLREADY_IN_USE:
            createSnackMessage(that, <FormattedMessage id='THREESCENE.SECTION_NAME_ALLREADY_IN_USE' />);
            break;
        case SectionToolStatusTypes.SUCCESSFULLY_CREATED_CUSTOM_SECTION:
            saveCustomSection(that);
            break;
        default:
            return;
    }
}

export function addCustomSectionToScene(sections, that) {
    // TODO maybe necessary to restructure the way sections are stored. There is a lot of converting between
    //strings and floats
    let newSections = that.props.site.sections.map(x => x);
    sections.forEach(s => {
        let borderPoints = [];
        s.positions.forEach(p => {
            borderPoints.push(''.concat(p.x, ', ', p.y, ', ', p.z));
        });

        newSections.push({
            name: s.name,
            border: {
                p1: borderPoints[0],
                p2: borderPoints[1],
                p3: borderPoints[2],
                p4: borderPoints[3]
            },
            color: s.color
        });
    });

    that.props.setCurrentSite({
        ...that.props.site,
        sections: newSections
    });
}

export async function updateWater(that) {
    if (WaterSettings.WATER_VISIBLE) {
        if (that.scene.getObjectByName('water'))
            that.scene.remove(that.scene.getObjectByName('water'));
        await that.water.generate();
        updateLevels(that)
        that.scene.add(that.water.mesh);
    }
}

export function updateLevels(that) {
    if (that.water.highTide && that.water.lowTide && that.water.obs) {
        that.setState({
            currentLabel: (that.water.obs + WaterSettings.AVG_DEPTH).toFixed(3) + ' m',
            averageLabel: WaterSettings.AVG_DEPTH.toFixed(3) + ' m',
            highTideLabel: (that.water.highTide + WaterSettings.AVG_DEPTH).toFixed(3) + ' m',
            highOccursAtLabel: that.water.highTime.substring(that.water.highTime.indexOf('T') +
                1, that.water.highTime.indexOf('+')),
            lowTideLabel: (that.water.lowTide + WaterSettings.AVG_DEPTH).toFixed(3) + ' m',
            lowOccursAtLabel: that.water.lowTime.substring(that.water.lowTime.indexOf('T') +
                1, that.water.lowTime.indexOf('+'))
        });
    }
}

export function handleSiteUpdate(update, that) {
    if (update === null) {
        that.isHandlingSiteUpdate = false;
        return;
    }
    WaterSettings.IS_LOCATION_SET = false;
    if (that.scene.getObjectByName('predefinedSections'))
        that.scene.remove(that.scene.getObjectByName('predefinedSections'));
    that.site = new Site(null, that.font, update);
    that.site.generate();
    if (INCLUDE_WALLS) {
        that.scene.add(that.site.mesh);
    }
    if (WaterSettings.WATER_VISIBLE) {
        if (that.water.mesh === null) updateWater(that);
        else that.water.addNewUpdate(update);
    }
    that.isHandlingSiteUpdate = false;
}

export async function componentDidMount(that) {
    // Init renderer
    that.renderer = new THREE.WebGLRenderer({ antialias: true });
    that.renderer.setClearColor(0xbfd1e5);
    that.renderer.setPixelRatio(window.devicePixelRatio);

    // Init container
    let container = document.getElementById('scene');
    let containerWidth = container.clientWidth;
    that.renderer.setSize(containerWidth, window.innerHeight);
    let canvas = that.renderer.domElement;
    container.appendChild(canvas);

    // Init camera
    that.camera = new THREE.PerspectiveCamera(Operational.Camera.FOV, containerWidth / window.innerHeight,
        Operational.Camera.NEAR, Operational.Camera.FAR);
    that.camera.position.set(Operational.Camera.X, Operational.Camera.Y, Operational.Camera.Z);
    that.camera.up = new THREE.Vector3(0, 1, 0);

    // Init scene
    that.scene = new THREE.Scene();
    that.scene.background = new THREE.Color(that.props.theme.customPalette.threeBackground);

    let ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    that.scene.add(ambientLight);

    that.pointLight = new THREE.PointLight(0xffffff, 0.6, 0, 2);
    that.pointLight.decay = 0;
    that.pointLight.shadow = null;
    that.scene.add(that.pointLight);
    onControlsChange(that);

    // Init clock
    that.clock = new THREE.Clock();
    that.time = 0;
    that.runningTime = 0;
    that.oldTime = 0;

    // Init raycaster
    that.raycaster = new THREE.Raycaster();
    that.mouse = new THREE.Vector2();

    // Init controls
    generateControls(that);

    // Start animation
    animate(that);

    // Add elements to scene
    that.assetLoader = new AssetLoader();
    await that.assetLoader.loadEnvironmentAssets(that.state.isLiveMode);
    await that.assetLoader.loadRobotAssets(that.state.isLiveMode);
    await generateVisualization(that);

    that.setState({ isLoadingElements: false }, () => {
        window.addEventListener('resize', function () {
            onWindowResize(that);
        });
        document.addEventListener('visibilitychange', () => handleVisibilityChange(that.clock), false);
        canvas.addEventListener('click', that.onDocumentMouseClick, false);
        canvas.addEventListener('mousemove', that.onDocumentMouseMove, false);
        canvas.addEventListener('mouseup', function (e) {
            onDocumentMouseUp(e, that);
        });
        canvas.addEventListener('mousedown', function (e) {
            onDocumentMouseDown(e, that);
        });
    });

    // TODO remove when element loading works again - that code lets the window resize to the correct size
    setTimeout(() => {
        // if a similar error occurs: TypeError: Cannot read property 'clientWidth' of null
        // check that "import joypad from 'joypad.js/dist/joypad';" is added to imports
        // in SimulationThreeScene
        onWindowResize(that);
    }, 20);
}

function handleVisibilityChange(clock) {
    if (document['hidden']) {
        clock.stop();
    } else {
        clock.start();
    }
}

export function generateEnvironmentVisualization(that) {
    that.font = that.assetLoader.environmentAssets[0];

    that.pool = new Pool(null, true);
    that.pool.generate();
    that.pool.mesh.forEach((mesh) => {
        mesh.name = 'pool';
        that.scene.add(mesh)
    });
    that.scene.add(that.pool.poolIntersectRegions);

    that.grid = new PoolGrid();
    that.grid.generate();
    if (GridSettings.VISIBLE) that.scene.add(that.grid.mesh);

    that.sectionTool = new SectionTool(null, that.scene);
    that.sectionTool.generate();
    if (that.props.site !== null && that.props.site.hasOwnProperty('sections')) {
        addCustomSectionToScene(that.sectionTool.getCustomSections(), that);
    }

    that.water = new PoolWater(that.assetLoader.environmentAssets[1], that.assetLoader.topSide);
}

export async function generateVisualization(that) {
    if (process.env.NODE_ENV === 'development')
        await HELPER.addCoordinateSystem().forEach((mesh => that.scene.add(mesh)));

    generateEnvironmentVisualization(that);
    that.generateRobotVisualization();

    that.robotUpdateQueue.push(that.props.robots);
    that.siteUpdateQueue.push(that.props.site);
}

export function componentWillUnmount(that) {
    cancelAnimationFrame(that.frameId);
    that.mount.removeChild(that.renderer.domElement);
}

export function renderRobotInfo() {
    return (
        <div
            className="hover-info"
            id="robotInfo"
        >
            <div className="flex-col just-around" style={{ paddingLeft: 12 }}>
                <div className="just-bet flex-row">
                    <label>Name: </label>
                    <label id="robName" />
                </div>
                <div className="just-bet flex-row">
                    <label>X-position: </label>
                    <label id="robX" />
                </div>
                <div className="just-bet flex-row">
                    <label>Y-position: </label>
                    <label id="robY" />
                </div>
                <div className="just-bet flex-row">
                    <label>Z-position: </label>
                    <label id="robZ" />
                </div>
            </div>
        </div>
    );
}

export function onClearClick(that) {
    if (that.props.isClearing) {
        if (that.props.canSendSection)
            that.props.toggleSectionCanSend();
        that.sectionTool.removeCustomPoints(that.scene);
        that.secIndex = 0;
        that.props.toggleIsClearing();
    }
}

export function onDrawClick(that) {
    if (that.props.isDrawing) {
        that.scene.add(that.sectionTool.ghostPoint);
    } else {
        if (that.props.canSendSection)
            that.props.toggleSectionCanSend();
        that.sectionTool.resetSectionTool(that.scene);
    }
    that.secIndex = 0;
}

export function onWindowResize(that) {
    let container = document.getElementById('scene');

    if (container) {
        let containerWidth = container.clientWidth;

        that.camera.aspect = containerWidth / window.innerHeight;
        that.camera.updateProjectionMatrix();
        that.renderer.setSize(containerWidth, window.innerHeight);
    }

}

function onDocumentMouseUp(event, that) {
    that.mouseUX = event.clientX;
    that.mouseUY = event.clientY;
}

function onDocumentMouseDown(event, that) {
    that.mouseDX = event.clientX;
    that.mouseDY = event.clientY;
}

function onControlsChange(that) {
    that.pointLight.position.copy(that.camera.position);
    that.pointLight.rotation.copy(that.camera.rotation);
}

function animate(that) {
    that.renderer.setAnimationLoop(that.renderAnimation);
}

function generateControls(that) {
    that.controls = new OrbitControls(that.camera, that.renderer.domElement);
    that.controls.target.set(Operational.Controls.X, Operational.Controls.Y, Operational.Controls.Z);
    that.controls.minDistance = Operational.Controls.MIN_DIST;
    that.controls.maxDistance = Operational.Controls.MAX_DIST;
    that.controls.saveState();
    that.controls.update();
    that.controls.addEventListener('change',
        function () {
            onControlsChange(that);
        });
}

export function createSnackMessage(that, textMessage) {
    setTimeout(() => {
        that.props.toggleFeedbackHidden();
        that.props.setFeedbackMessage(textMessage);
    }, 20);
}

export function updateRobotVisibility(that, robots, prevRobots) {
    if (that.robotGroup === null)
        return;

    robots.forEach(robot => {
        let sceneRobot = that.robotGroup.children.filter(sr => sr.name.NAME === robot.modelId);
        sceneRobot = sceneRobot.length > 0 ? sceneRobot[0] : null;
        if (sceneRobot === null)
            return;

        sceneRobot.children.forEach(robotChild => {
            robotChild.visible = robot.showRobot;
        });

        let prevRobot = prevRobots.filter(pr => pr.modelId === robot.modelId);
        prevRobot = prevRobot.length > 0 ? prevRobot[0] : null;
        if (prevRobot === null)
            return

        if (robot.showSection !== prevRobot.showSection) {
            toggleSectionVisibility(that, robot);
        }

        if (robot.showPath !== prevRobot.showPath) {
            togglePathVisibility(that, robot);
        }

        if (robot.showTrail !== prevRobot.showTrail) {
            toggleTrailVisibility(that, robot);
        }
    })
}

function toggleSectionVisibility(that, robot) {
    try {
        let sectionGroup = that.scene.children
            .filter(child => child.name === 'predefinedSections')[0].children
            .filter(child => child.name === robot.section)[0];
        sectionGroup.visible = !sectionGroup.visible;
    } catch (e) {
        console.log(e.toString());
    }

}

function togglePathVisibility(that, robot) {
    if (robot.modelId in that.robotPathLines)
        that.robotPathLines[robot.modelId].visible = !that.robotPathLines[robot.modelId].visible;
}

function toggleTrailVisibility(that, robot) {
    if (robot.modelId in that.robotTrailLines)
        that.robotTrailLines[robot.modelId].visible = !that.robotTrailLines[robot.modelId].visible;
}

async function saveCustomSection(that) {
    let newSections = [].concat(that.props.site.sections);
    let borderPoints = that.sectionTool.mesh.map(mesh => {
        return ''.concat(mesh.position.x, ', ', mesh.position.y, ', ', mesh.position.z);
    });
    const sectionName = that.props.customSectionName;
    const currentSite = that.props.site;
    let customerRef = null;

    newSections.push({
        name: sectionName,
        border: {
            p1: borderPoints[0],
            p2: borderPoints[1],
            p3: borderPoints[2],
            p4: borderPoints[3],
        },
        color: that.sectionTool.color,
    });

    const querySnapshot = await that.db.collection('customers').where('id', '==', that.props.currentUser.id).get();

    querySnapshot.forEach((doc) => {
        customerRef = that.db.collection('customers').doc(doc.id);
    });

    if (customerRef) {
        const doc = await customerRef.get();

        const sites = doc.data().sites;
        const site = sites.filter(site => site.name === currentSite.name)[0];

        const newSection = {
            name: sectionName,
            border: {
                p1: borderPoints[0],
                p2: borderPoints[1],
                p3: borderPoints[2],
                p4: borderPoints[3],
            },
            color: {
                b: that.sectionTool.color.b,
                g: that.sectionTool.color.g,
                r: that.sectionTool.color.r,
            },
        };

        site.sections.push(newSection);

        await customerRef.update({
            sites: [].concat(site),
        })

        that.props.setCurrentSite({
            ...currentSite,
            sections: newSections,
            customSectionColor: that.sectionTool.color,
        });

        createSnackMessage(that,
            <FormattedMessage
                id='THREESCENE.SUCCESSFULLY_CREATED_CUSTOM_SECTION'
                values={{
                    sectionName: that.props.customSectionName,
                }}
            />
        );

        that.props.resetCustomSectionName();
    }

    //reset section tool
    that.props.toggleSectionCanSend();
    that.sectionTool.resetSectionTool(that.scene);
    that.createNewSectionTool();
    that.sectionTool.generate();
    that.props.toggleIsDrawing();
}

//  p3------p4
//  |       |
//  |       |
//  p1------p2
export function getFormattedBorder(border, type) {
    let formattedBorder;
    let xy1 = border.p1.split(', '),
        xy2 = border.p2.split(', '),
        xy3 = border.p3.split(', '),
        xy4 = border.p4.split(', ');

    //the case of custom sections
    if (type === 'custom section') {
        formattedBorder = {
            p1: {
                x: xy1[0],
                y: xy1[1],
                z: xy1[2]
            },
            p2: {
                x: xy2[0],
                y: xy2[1],
                z: xy2[2]
            },
            p3: {
                x: xy3[0],
                y: xy3[1],
                z: xy3[2]
            },
            p4: {
                x: xy4[0],
                y: xy4[1],
                z: xy4[2]
            }
        };
    } else if (type === 'inlet') {
        formattedBorder = {
            p1: {
                x: Number(xy1[0]),
                y: Number(xy1[1]),
                z: (-PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH)
            },
            p2: {
                x: Number(xy2[0]),
                y: Number(xy2[1]),
                z: (-PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH)
            },
            p3: {
                x: Number(xy3[0]),
                y: Number(xy3[1]),
                z: (-PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH)
            },
            p4: {
                x: Number(xy4[0]),
                y: Number(xy4[1]),
                z: (-PoolSettings.LENGTH_TOTAL + PoolSettings.LENGTH)
            }

        };
    } else if (type === 'outlet') {
        formattedBorder = {
            p1: {
                x: Number(xy1[0]),
                y: Number(xy1[1]),
                z: -PoolSettings.LENGTH_TOTAL
            },
            p2: {
                x: Number(xy2[0]),
                y: Number(xy2[1]),
                z: -PoolSettings.LENGTH_TOTAL
            },
            p3: {
                x: Number(xy3[0]),
                y: Number(xy3[1]),
                z: -PoolSettings.LENGTH_TOTAL
            },
            p4: {
                x: Number(xy4[0]),
                y: Number(xy4[1]),
                z: -PoolSettings.LENGTH_TOTAL
            }

        };
    } else if (type === 'right wall') {
        formattedBorder = {
            p1: {
                x: PoolSettings.WIDTH,
                y: Number(xy1[1]),
                z: -Number(xy1[0])
            },
            p2: {
                x: PoolSettings.WIDTH,
                y: Number(xy2[1]),
                z: -Number(xy2[0])
            },
            p3: {
                x: PoolSettings.WIDTH,
                y: Number(xy3[1]),
                z: -Number(xy3[0])
            },
            p4: {
                x: PoolSettings.WIDTH,
                y: Number(xy4[1]),
                z: -Number(xy4[0])
            }

        };
    } else if (type === 'left wall') {
        formattedBorder = {
            p1: {
                x: 0,
                y: Number(xy1[1]),
                z: -Number(xy1[0])
            },
            p2: {
                x: 0,
                y: Number(xy2[1]),
                z: -Number(xy2[0])
            },
            p3: {
                x: 0,
                y: Number(xy3[1]),
                z: -Number(xy3[0])
            },
            p4: {
                x: 0,
                y: Number(xy4[1]),
                z: -Number(xy4[0])
            }

        };
    } else if (type === 'floor') {
        formattedBorder = {
            p1: {
                x: Number(xy1[0]),
                y: 0,
                z: -Number(xy1[1])
            },
            p2: {
                x: Number(xy2[0]),
                y: 0,
                z: -Number(xy2[1])
            },
            p3: {
                x: Number(xy3[0]),
                y: 0,
                z: -Number(xy3[1])
            },
            p4: {
                x: Number(xy4[0]),
                y: 0,
                z: -Number(xy4[1])
            }

        };
    }

    return formattedBorder;
}