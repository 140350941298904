import { createSelector } from 'reselect';

const selectRobot = state => state.robot;

export const selectRobots = createSelector(
    [selectRobot],
    robot => robot.robots,
);

export const selectSimulationRobots = createSelector(
    [selectRobot],
    robot => robot.simulationRobots,
);

export const selectSelectedRobots = createSelector(
    [selectRobot],
    robot => robot.selectedRobots,
);

export const selectCurrentRobot = createSelector(
    [selectRobot],
    robot => robot.currentRobot,
);

export const selectSettingsDrawer = createSelector(
    [selectRobot],
    robot => robot.showSettingDrawer,
);

export const selectShowRobot = createSelector(
    [selectCurrentRobot],
    robot => robot.showRobot,
);

export const selectShowTrail = createSelector(
    [selectCurrentRobot],
    robot => robot.showTrail,
);

export const selectShowPath = createSelector(
    [selectCurrentRobot],
    robot => robot.showPath
);

export const selectShowSection = createSelector(
    [selectCurrentRobot],
    robot => robot.showSection
);

export const selectRobotSection = createSelector(
    [selectCurrentRobot],
    robot => robot.section
);

export const selectSimulationRobotStreamers = createSelector(
    [selectRobot],
    robot => robot.simulationRobotStreamers,
);

export const selectCurrentlyDrivingRobot = createSelector(
    [selectRobot],
    robot => robot.currentlyDrivingRobot,
)

// TODO replace with something more sensible. only for demo
export const selectCurrentPath = createSelector(
    [selectRobot],
    robot => robot.path
);
