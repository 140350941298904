import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classnames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

import Axios from 'axios';

import RobotControl from './RobotControl';
import SectionTool from './SectionTool';
import { selectCurrentlyDrivingRobot, selectCurrentRobot, selectRobots, selectSettingsDrawer } from '../../../redux/robot/robotSelector';
import { selectIsDrawing } from '../../../redux/section/sectionSelector';
import { toggleIsDrawing } from '../../../redux/section/sectionActions';
import { setCurrentRobot, setRobots, toggleRobotMode } from '../../../redux/robot/robotActions';
import { selectIsLive } from '../../../redux/mode/modeSelector';
import { selectCurrentUser } from '../../../redux/user/userSelector';
import { setFeedbackMessage, toggleFeedbackHidden } from '../../../redux/feedback/feedbackActions';
import { setCurrentUser } from '../../../redux/user/userActions';

import { styles } from './SettingsDrawerStyles';
import { statusCode } from '../../../config';

class SettingsDrawer extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        open: PropTypes.bool,
        toggleDrawer: PropTypes.func
    };

    static defaultProps = {
        open: false,
        toggleDrawer: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            enableHelper: false,
            isRovMode: props.currentRobot !== null ? props.currentRobot.isRovMode : false,
            allowControl: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isSettingsDrawerOpen && this.props.isSettingsDrawerOpen) {
            this.getJoystickLock();
        }
    }

    handleToggleDrawer = () => {
        if (this.props.isDrawing) {
            this.props.toggleIsDrawing();
        }
        this.props.toggleDrawer();
    };

    handleToggleRobotMode = () => {
        this.setState({
            isRovMode: !this.state.isRovMode,
        }, () => {
            if (this.props.isLiveMode) {
                this.setJoystickLock();
            } else {
                this.props.toggleRobotMode(this.props.isLiveMode);
            }
        });
    }

    getJoystickLock = async () => {
        const { currentRobot, currentUser } = this.props;

        try {
            const response = await Axios({
                method: 'get',
                url: `https://${currentRobot.ipAddress}/api/v1/get_jtoken`,
                headers: {
                    Authorization: 'Bearer ' + currentUser.token,
                },
                params: {
                    jToken: localStorage.getItem('jToken'),
                },
            })

            this.setState({
                allowControl: localStorage.getItem('jToken') === response.data.jToken || response.data.jToken === 'None',
                isRovMode: !(response.data.jToken === null || response.data.jToken === 'None'),
            }, () => {
                this.props.setCurrentRobot({ ...currentRobot, isRovMode: this.state.isRovMode });
                this.updateRobots();
            });
        } catch (error) {
            console.error(error)

            if (error.response && error.response.status === statusCode.UNAUTHORIZED) {
                this.props.toggleFeedbackHidden();
                this.props.setFeedbackMessage(<FormattedMessage id="ERROR.UNAUTHORIZED" />);
            }
        }
    }

    setJoystickLock = async () => {
        const { toggleFeedbackHidden, setFeedbackMessage, currentRobot, currentUser } = this.props;

        try {
            const response = await Axios({
                method: 'post',
                url: `https://${currentRobot.ipAddress}/api/v1/connect_joystick`,
                data: {
                    connect: this.state.isRovMode,
                    jToken: localStorage.getItem('jToken'),
                },
                headers: {
                    Authorization: 'Bearer ' + currentUser.token,
                },
            });

            let msg;
            if (response.data.jToken && response.data.jToken !== 'None') {
                msg = (
                    <FormattedMessage
                        id="SETTINGSDRAWER.JOYSICK_ACTIVE"
                        values={{
                            robot: currentRobot.modelId,
                        }}
                    />
                );
            } else {
                msg = (
                    <FormattedMessage
                        id="SETTINGSDRAWER.JOYSICK_NOT_ACTIVE"
                        values={{
                            robot: currentRobot.modelId,
                        }}
                    />
                );
            }
            localStorage.setItem('jToken', response.data.jToken);

            this.props.setCurrentRobot({ ...currentRobot, isRovMode: this.state.isRovMode });
            this.props.toggleRobotMode(this.props.isLiveMode);
            this.updateRobots();
            this.setState({
                allowControl: true,
            });

            toggleFeedbackHidden();
            setFeedbackMessage(msg);
        } catch (error) {
            console.error(error)

            toggleFeedbackHidden();
            setFeedbackMessage(<FormattedMessage id="SETTINGSDRAWER.JOYSICK_CONNECT_ERROR" values={{ robot: currentRobot.modelId }} />);
            this.setState({
                isRovMode: false,
            });
        }
    };

    updateRobots = () => {
        const newRobots = this.props.robots.map(robot => robot.modelId === this.props.currentRobot.modelId ? { ...robot, isRovMode: this.state.isRovMode } : robot);
        this.props.setRobots(newRobots);
    };

    handleHelper = () => {
        this.setState({
            enableHelper: !this.state.enableHelper,
        }, () => {
            if (this.state.enableHelper) {
                document.getElementById('robot_card_container').style.display = 'none';
            } else {
                document.getElementById('robot_card_container').style.display = 'initial';
            }
        });
    };

    handleExitHelper = () => {
        this.setState({
            enableHelper: false,
        }, () => {
            document.getElementById('robot_card_container').style.display = 'initial';
        });
    }

    renderTop() {
        const { classes, currentRobot } = this.props;
        const robot = currentRobot === null ? '' : currentRobot.modelId;
        return (
            <AppBar
                position={'static'}
            >
                <Toolbar className={classes.topBar}>
                    <Typography variant={'h6'}>
                        <FormattedMessage
                            id="SETTINGSDRAWER.TITLE"
                            values={{ robot: robot }}
                        />
                    </Typography>
                    <div>
                        <IconButton onClick={this.handleToggleDrawer}>
                            <Icon>
                                close
                            </Icon>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
        );
    }

    renderContent() {
        const { currentRobot } = this.props;

        const rovModeSettings = (
            <div>

            </div>
        );

        if (currentRobot !== null) {
            const robotModeSettings = currentRobot.isPlayBackRobot ?
                (
                    <div>
                        <RobotControl />
                    </div>
                ) : (
                    <div>
                        <RobotControl />
                        <SectionTool />
                    </div>
                );

            return (
                <div className={this.props.classes.body}>
                    {currentRobot.isPlayBackRobot ?
                        <div />
                        :
                        <ToggleButtonGroup
                            value={this.state.isRovMode}
                            exclusive={true}
                            onChange={this.handleToggleRobotMode}
                        >
                            <ToggleButton
                                value={true}
                                disabled={!this.state.allowControl}
                            >
                                <FormattedMessage id='SETTINGSDRAWER.ROV_MODE' />
                            </ToggleButton>
                            <ToggleButton
                                value={false}
                                disabled={!this.state.allowControl}
                            >
                                <FormattedMessage id='SETTINGSDRAWER.ROBOT_MODE' />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    }
                    {this.state.isRovMode ? rovModeSettings : robotModeSettings}
                </div>
            );
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Drawer
                    open={this.props.isDrawing ? true : this.props.open}
                    anchor={'right'}
                    onClose={this.props.isDrawing ? null : this.handleToggleDrawer}
                    hideBackdrop={!!this.props.isDrawing}
                    className={this.props.isDrawing ? classes.isDrawingContainer : null}
                >
                    <div className={classnames(classes.root, classes.settingsDrawerContent)}>
                        {this.renderTop()}
                        {this.renderContent()}
                    </div>
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    currentRobot: selectCurrentRobot,
    currentlyDrivingRobot: selectCurrentlyDrivingRobot,
    currentUser: selectCurrentUser,
    isDrawing: selectIsDrawing,
    isSettingsDrawerOpen: selectSettingsDrawer,
    isLiveMode: selectIsLive,
    robots: selectRobots,
});

const mapDispatchToProps = dispatch => ({
    toggleIsDrawing: () => dispatch(toggleIsDrawing()),
    toggleRobotMode: (isLiveMode) => dispatch(toggleRobotMode(isLiveMode)),
    toggleFeedbackHidden: () => dispatch(toggleFeedbackHidden()),
    setFeedbackMessage: message => dispatch(setFeedbackMessage(message)),
    setCurrentUser: user => dispatch(setCurrentUser(user)),
    setCurrentRobot: robot => dispatch(setCurrentRobot(robot)),
    setRobots: robots => dispatch(setRobots(robots)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles, { withTheme: true })(SettingsDrawer)));