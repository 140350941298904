import * as THREE from 'three';

export let Operational = {
    IS_RUNNING: false,
    SPEED: 1,
    HISTORY_TIME: 20,
    FUTURE_TIME: 10,
    ID: 1,

    Camera: {
        FOV: 60,
        NEAR: 1,
        FAR: 300,
        X: -25,
        Y: 50,
        Z: 40
    },
    Controls: {
        X: 10,
        Y: 0,
        Z: 0,
        MAX_DIST: 150,
        MIN_DIST: 0
    }
};

export let SiteSettings = {
    SIDE: THREE.DoubleSide,
    TRANSPARENT: true,
    OPACITY: 0.5,
    WIREFRAME: false,
    GAP_FACE: 0.005,
    GAP_OUTLET: 0.01,
    GAP_WALL: 0.015,
    GAP_FLOOR: 0.025,
    // TODO store colors in database so it will be easy to add and remove sections?
    COLOR: [
        { h: 0, l: 50 },
        { h: 128, l: 41 },
        { h: 24, l: 66 },
        { h: 243, l: 66 },
        { h: 34, l: 39 },
        { h: 8, l: 65 },
        { h: 211, l: 44 },
        { h: 214, l: 61 },
        { h: 271, l: 48 },
        { h: 191, l: 49 }
    ]
};

export let PoolSettings = {
    WIDTH: 40,
    HEIGHT: 20,
    LENGTH: 43,
    LENGTH_TOTAL: 45,
    RADIUS: 1,
    SMOOTHNESS: 10,
    COLOR_DARK: 0x000000,
    COLOR_LIGHT: 0xFFFFFF,
    TRANSPARENT: true,
    OPACITY: 0.5,
    WIREFRAME: false,
    SIDE: THREE.DoubleSide
};

export let GridSettings = {
    NAME: 'Grid',
    COLOR_GRID: 0x404443,
    COLOR_CENTER: 0xFF0000,
    CENTER_LINE_DIM: 0.0015,
    TRANSPARENT: false,
    OPACITY: 0.7,
    ID: 1,
    DISTANCE: 1,
    VISIBLE: false
};

export let WaterSettings = {
    NAME: 'Water',
    COLOR_WATER: 0x55A7C8,
    COLOR_AVG: 0x159C01,
    COLOR_NOW: 0x0000FF,
    COLOR_LOW: 0xE2A920,
    COLOR_HIGH: 0xFF0000,
    COLOR_ARROW: 0x000000,
    ARROW_LENGTH: 6,
    ARROW_HEAD_LENGTH: 3,
    ARROW_HEAD_WIDTH: 1,
    ARROW_DIR: new THREE.Vector3(0, 0, -1),
    GAP: 0.03,
    LINE_DIM: 0.002,
    SURFACE_RESOLUTION: 2048,
    WAVE_STRENGTH: '0.1',
    WAVE_SPEED: '0.4',
    UW_TRANSPARENT: true,
    UW_OPACITY: 0.5,
    UW_SIDE: THREE.DoubleSide,
    DATA_TYPE: 'msl',
    ID: 1,
    WATER_VISIBLE: true,
    WAVES_VISIBLE: true,
    AVG_DEPTH: 17.5,
    LATITUDE: '',
    LONGITUDE: '',
    IS_LOCATION_SET: false,
    FREQUENCY: 600,
    INTERVAL: '10'
};

export let SectionToolSettings = {
    POINT_RADIUS: 0.4,
    POINT_SEGMENTS: 15,
    GHOST_OPACITY: 0.5,
    GHOST_TRANSPARENT: true
};

export let Playback = {
    defaultValue1: 8,
    defaultValue2: 16,
    value1: 8,
    value2: 16,
    TRANSPARENT: true,
    OPACITY: 0.5,
    VISIBLE: false
};

export let SectionSettings = {
    LENGTH_OFFSET: PoolSettings.LENGTH_TOTAL - PoolSettings.LENGTH,
    GAP: 0.01,
    OPACITY: 0.4,
    TRANSPARENT: true,
    SIDE: THREE.FrontSide
};

export let RobotCameraSettings = {
    OFFSET_Z: -0.37,
    OFFSET_Y: 0.1
};

export let RobotSettings = {
    NO_OF_CUSTOMER_ROBOTS: 5,
    IP_ADDRESSES: [
        // TODO insert real ipAddresses or other unique ID
        '192.168.0.180',
        '192.168.0.181',
        '192.168.0.182',
        '192.168.0.183',
        '192.168.0.184'
    ],
    MODEL_SCALE: 0.2,
    MODEL_DISTANCE: 10
};

export let SimulationRobotSettings = {
    POS_OFFSET: -0.208,
    NO_OF_CUSTOMER_ROBOTS: RobotSettings.NO_OF_CUSTOMER_ROBOTS,
    IP_ADDRESSES: [
        '192.168.0.180',
        '192.168.0.181',
        '192.168.0.182',
        '192.168.0.183',
        '192.168.0.184'
    ],
    NAMES: [
        'X3-TEST-1',
        'X3-TEST-2',
        'X3-TEST-3',
        'X3-TEST-4',
        'X3-TEST-5'
    ],
    MODEL_SCALE: 0.2,
    MODEL_DISTANCE: 10
};