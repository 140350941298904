export const robotActionTypes = {
    SET_CURRENT_ROBOT: 'SET_CURRENT_ROBOT',
    SELECT_ROBOT: 'SELECT_ROBOT',
    UNSELECT_ROBOT: 'UNSELECT_ROBOT',
    UNSELECT_ALL_ROBOTS: 'UNSELECT_ALL_ROBOTS',
    SET_ROBOTS: 'SET_ROBOTS',
    SET_SIMULATION_ROBOTS: 'SET_SIMULATION_ROBOTS',
    TOGGLE_CURRENT_ROBOT_DRAWER: 'TOGGLE_CURRENT_ROBOT_DRAWER',
    TOGGLE_CURRENT_ROBOT_SHOW_ROBOT: 'TOGGLE_CURRENT_ROBOT_SHOW_ROBOT',
    TOGGLE_CURRENT_ROBOT_SHOW_TRAIL: 'TOGGLE_CURRENT_ROBOT_SHOW_TRAIL',
    TOGGLE_CURRENT_ROBOT_SHOW_PATH: 'TOGGLE_CURRENT_ROBOT_SHOW_PATH',
    TOGGLE_CURRENT_ROBOT_SHOW_SECTION: 'TOGGLE_CURRENT_ROBOT_SHOW_SECTION',
    TOGGLE_ROBOT_MODE: 'TOGGLE_ROBOT_MODE',
    SET_LIVE_ROBOT_SECTION: 'SET_LIVE_ROBOT_SECTION',
    SET_SIMULATED_ROBOT_SECTION: 'SET_SIMULATED_ROBOT_SECTION',
    ADD_SIMULATION_ROBOT_STREAMER: 'ADD_SIMULATION_ROBOT_STREAMER',
    REMOVE_SIMULATION_ROBOT_STREAMER: 'REMOVE_SIMULATION_ROBOT_STREAMER',
    UPDATE_ROBOT_PATH_LENGTH: 'UPDATE_ROBOT_PATH_LENGTH',
    UPDATE_ROBOT_TRAIL_LENGTH: 'UPDATE_ROBOT_TRAIL_LENGTH',
    // TODO replace with something more sensible. only for demo
    SET_ROBOT_PATH: 'SET_ROBOT_PATH',
    SET_ROBOT_ROV_MODE: 'SET_ROBOT_ROV_MODE',
};
