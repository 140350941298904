import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import WaterLevelIcon from '@material-ui/icons/Crop32Sharp';

import { styles } from './InformationMenuContainerStyles';

class InformationMenuContainer extends React.Component {
    static propTypes = {
        currentLabel: PropTypes.string,
        averageLabel: PropTypes.string,
        highTideLabel: PropTypes.string,
        highOccursAtLabel: PropTypes.string,
        lowTideLabel: PropTypes.string,
        lowOccursAtLabel: PropTypes.string,
    };

    static defaultProps = {
        currentLabel: '',
        averageLabel: '',
        highTideLabel: '',
        highOccursAtLabel: '',
        lowTideLabel: '',
        lowOccursAtLabel: '',
    };

    renderWaterLevelLabels() {
        const { classes } = this.props;

        return (
            <div className={classes.waterLevelLabelsContainer}>
                <div className={classnames(classes.flexRow100PercentWidth, classes.justBet)}>
                    <WaterLevelIcon className={classes.currentIcon} />
                    <Typography className={classes.waterLevelLabel}><FormattedMessage
                        id="INFORMATIONMENUCONTAINER.CURRENT_LABEL" /></Typography>
                </div>
                <div className={classnames(classes.flexRow100PercentWidth, classes.justBet)}>
                    <WaterLevelIcon className={classes.averageIcon} />
                    <Typography className={classes.waterLevelLabel}><FormattedMessage
                        id="INFORMATIONMENUCONTAINER.AVERAGE_LABEL" /></Typography>
                </div>
                <div className={classnames(classes.flexRow100PercentWidth, classes.justBet)}>
                    <WaterLevelIcon className={classes.highTideIcon} />
                    <Typography className={classes.waterLevelLabel}><FormattedMessage
                        id="INFORMATIONMENUCONTAINER.HIGH_TIDE_LABEL" /></Typography>
                </div>

                <Typography className={classes.waterLevelLabelIndent}><FormattedMessage
                    id="INFORMATIONMENUCONTAINER.OCCURS_AT_LABEL" /></Typography>

                <div className={classnames(classes.flexRow100PercentWidth, classes.justBet)}>
                    <WaterLevelIcon className={classes.lowTideIcon} />
                    <Typography className={classes.waterLevelLabel}><FormattedMessage
                        id="INFORMATIONMENUCONTAINER.LOW_TIDE_LABEL" /></Typography>
                </div>

                <Typography className={classes.waterLevelLabelIndent}><FormattedMessage
                    id="INFORMATIONMENUCONTAINER.OCCURS_AT_LABEL" /></Typography>

            </div>
        );
    }

    renderWaterLevels = () => {
        const { classes } = this.props;

        return (
            <div className={classes.flexCol100PercentWidth}>
                <Typography>{this.props.currentLabel}</Typography>
                <Typography>{this.props.averageLabel}</Typography>
                <Typography>{this.props.highTideLabel}</Typography>
                <Typography>{this.props.highOccursAtLabel}</Typography>
                <Typography>{this.props.lowTideLabel}</Typography>
                <Typography>{this.props.lowOccursAtLabel}</Typography>
            </div>
        );
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.sceneExpansionContainer}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography><FormattedMessage id="THREESCENE.WATER_LEVELS_LABEL" /></Typography>
                    </AccordionSummary>
                    <div className={classes.infoContent}>
                        <div className={classes.waterLevelInfo}>
                            <div className={classes.waterLevelColumns}>
                                {this.renderWaterLevelLabels()}
                                {this.renderWaterLevels()}
                            </div>
                        </div>
                    </div>
                </Accordion>
            </div>
        );
    }
}

export default withStyles(styles)(InformationMenuContainer);