import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentRobot, selectRobots, selectSelectedRobots } from '../../../redux/robot/robotSelector';
import { selectCurrentSite } from '../../../redux/site/siteSelector';
import {
    selectCanSend,
    selectCustomSectionName,
    selectIsClearing,
    selectIsDrawing, selectIsSendingSection
} from '../../../redux/section/sectionSelector';
import {
    resetCustomSectionName, toggleIsClearing,
    toggleIsDrawing,
    toggleSectionCanSend,
    toggleSendCustomSection
} from '../../../redux/section/sectionActions';
import { setCurrentSite } from '../../../redux/site/siteActions';

import * as THREE from 'three';
import PoolGrid from './componenets/PoolGrid';
import SectionTool from './componenets/SectionTool';
import * as HELPER from './Helpers';
import { GridSettings, PoolSettings, SimulationRobotSettings, WaterSettings } from './componenets/OperationalValues';
import withStyles from '@material-ui/core/styles/withStyles';
import { styles } from './ThreeSceneStyles';
import { selectRobot } from '../../../redux/robot/robotActions';
import * as CommonUtils from './CommonUtils';
import ControlMenuContainer from '../ControlMenuContainer/ControlMenuContainer';
import InformationMenuContainer from '../InformationMenuContainer/InformationMenuContainer';
import SectionToolStatusTypes from './componenets/SectionToolStatusTypes';
import { FormattedMessage } from 'react-intl';
import { setFeedbackMessage, toggleFeedbackHidden } from '../../../redux/feedback/feedbackActions';

import { firestore } from '../../../firebase/Firebase';
import { selectCurrentUser } from '../../../redux/user/userSelector';

class LiveThreeScene extends React.Component {
    static propTypes = {
        onJoystickChange: PropTypes.func,
    };

    static defaultProps = {
        onJoystickChange: null,
    };

    constructor(props) {
        super(props);

        HELPER.constructLive(this);
        HELPER.bindLive(this);

        this.db = firestore;
    }

    componentDidMount() {
        CommonUtils.componentDidMount(this);
        this.trailMaterial = new THREE.LineBasicMaterial({ color: 0x000000 });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.theme !== this.props.theme && this.scene !== null) {
            this.scene.background = new THREE.Color(this.props.theme.customPalette.threeBackground);
        }
        if (prevProps.isDrawing !== this.props.isDrawing) {
            CommonUtils.onDrawClick(this);
        }
        if (prevProps.isClearing !== this.props.isClearing) {
            CommonUtils.onClearClick(this);
        }
        if (this.props.isSendingCustomSection) {
            CommonUtils.sendCustomSection(this);
            this.props.toggleSendCustomSection(null);
        }
        if (this.props.robots !== prevProps.robots && this.props.robots.length > 0) {
            this.robotUpdateQueue.push(this.props.robots);
            CommonUtils.updateRobotVisibility(this, this.props.robots, prevProps.robots);
        }

        if (this.props.site !== prevProps.site) this.siteUpdateQueue.push(this.props.site);

        if (this.props.selectedRobots !== prevProps.selectedRobots) {
            if (this.props.selectedRobots.length === 0 || prevProps.selectedRobots.length === 0) {
                CommonUtils.onWindowResize(this);
            }
        }
    }

    componentWillUnmount() {
        CommonUtils.componentWillUnmount(this);
    }

    generateRobot(robotData, x, y, z) {
        let robotPrototype = this.assetLoader.robotPrototypeLive;
        robotPrototype.name = robotData.ipAddress;
        let newRobot = robotPrototype.clone();
        newRobot.position.set(x, y, z);
        newRobot.rotateY(Math.PI);
        newRobot.children[0].geometry.translate(0, SimulationRobotSettings.POS_OFFSET, 0);
        newRobot.children[1].geometry.translate(0, SimulationRobotSettings.POS_OFFSET, 0);
        newRobot.children[0].geometry.computeBoundingBox();
        newRobot.children[1].geometry.computeBoundingBox();
        newRobot.homePos = { x: x, y: y, z: z };
        newRobot.pointsArray = [];
        let trailG = new THREE.BufferGeometry();
        let trail = new THREE.Line(trailG, this.trailMaterial);
        trail.name = 'trailLine_' + newRobot.name;
        trail.visible = false;
        this.scene.add(trail);
        newRobot.add(new THREE.ArrowHelper(new THREE.Vector3(0, 0, 1),
            new THREE.Vector3(0, 0, 0), 4.5,
            0x000000, 1.5, 0.8));
        if (process.env.NODE_ENV === 'development') newRobot.add(new THREE.AxesHelper(8));
        this.robotGroup.add(newRobot);
    }

    generateRobotVisualization() {
        this.robotGroup = new THREE.Group();
        this.robotGroup.name = 'robotGroup';
        for (let i = 0, x = (PoolSettings.WIDTH / 2) + (this.props.robots.length - 1);
            i < this.props.robots.length;
            i++, x -= 2) {
            this.generateRobot(this.props.robots[i], x, PoolSettings.HEIGHT + 0.5, -PoolSettings.LENGTH_TOTAL - 1);
        }
        this.scene.add(this.robotGroup);
    }

    renderAnimation() {
        this.deltaTime = this.clock.getDelta();
        this.time += this.deltaTime;
        if (!this.state.isLoadingElements) {
            if (this.isTracking) {
                this.controls.target.set(this.trackedRobot.position.x, this.trackedRobot.position.y,
                    this.trackedRobot.position.z);
                this.controls.update();
            }

            if (WaterSettings.WATER_VISIBLE && WaterSettings.IS_LOCATION_SET) {
                if (WaterSettings.WAVES_VISIBLE) this.water.updateSurfaceWaves(this.deltaTime); // Wave animation
                if (Math.round(this.time) % WaterSettings.FREQUENCY === 0 && Math.round(this.time) > this.oldTime) {
                    // Get the latest water-level from kartverket, update based on timeinterval set in OperationalValues
                    this.oldTime = Math.round(this.time);
                    CommonUtils.updateWater(this);
                }
                if (this.water.topSide)
                    this.water.topSide.position.y =
                        this.water.obs + WaterSettings.AVG_DEPTH - 0.15 + (Math.sin(this.time) / 15);
            }

            if (!this.isHandlingRobotUpdate && this.robotUpdateQueue.length > 0) {
                this.isHandlingRobotUpdate = true;
                this.handleRobotUpdate(this.robotUpdateQueue.shift());
            }

            if (!this.isHandlingSiteUpdate && this.siteUpdateQueue.length > 0) {
                this.isHandlingSiteUpdate = true;
                CommonUtils.handleSiteUpdate(this.siteUpdateQueue.shift(), this);
            }

            this.updateRobotPositionAndOrientation();
        }

        if (process.env.NODE_ENV === 'development' && this.stats !== null) this.stats.update();

        this.renderer.render(this.scene, this.camera);
    }

    updateRobotPositionAndOrientation() {
        this.props.robots.forEach((robot) => {
            let sceneRobot = this.scene.getObjectByName('robotGroup').children.filter(c => c.name === robot.ipAddress)[0];
            if (robot.hasOwnProperty('position')) {
                if (sceneRobot.position.x !== robot.position.x || sceneRobot.position.y !== robot.position.y
                    || sceneRobot.position.z !== robot.position.z) {
                    let isHome = sceneRobot.position.x === sceneRobot.homePos.x && sceneRobot.position.y === sceneRobot.homePos.y &&
                        sceneRobot.position.z === sceneRobot.homePos.z;
                    let trail = this.scene.getObjectByName('trailLine_' + robot.ipAddress);
                    let trailPoints = sceneRobot.pointsArray;
                    if (!isHome) {
                        trailPoints.push(new THREE.Vector3(robot.position.x, robot.position.y, -robot.position.z));
                        trail.visible = true;
                    } else {
                        trailPoints = [];
                        trail.visible = false;
                    }
                    const currentWaterLevel = parseFloat(this.state.currentLabel.split(' ')[0].trim());
                    sceneRobot.position.set(robot.position.x, currentWaterLevel - robot.position.y, -robot.position.z);

                    if (trailPoints.length >= 2) {
                        const numberOfPointsToShow = Math.round((trailPoints.length * (robot.trailLength / 100)));
                        let sliceStart = Math.max(trailPoints.length - numberOfPointsToShow,
                            trailPoints.length - this.MAX_POINTS);
                        trail.geometry.setFromPoints(trailPoints.slice(sliceStart));
                        trail.geometry.verticesNeedUpdate = true;
                    }
                }
            }
            if (robot.hasOwnProperty('roll')) {
                sceneRobot.rotation.z = robot.roll * Math.PI / 180;
            }
            if (robot.hasOwnProperty('pitch')) {
                sceneRobot.rotation.x = robot.pitch * Math.PI / 180;
            }
            if (robot.hasOwnProperty('yaw')) {
                sceneRobot.rotation.y = robot.yaw * Math.PI / 180;
            }
        });
    };

    handleRobotUpdate(update) {
        //add new robots to scene
        update.forEach(r => {
            if (!this.robotGroup.children.map(c => c.name).includes(r.ipAddress)) {
                let i = this.robotGroup.children.length;
                this.generateRobot(r, i * 2, PoolSettings.HEIGHT + 0.5, -PoolSettings.LENGTH_TOTAL - 1);
            }
        });
        this.isHandlingRobotUpdate = false;
    }

    onDocumentMouseMove(event) {
        this.mouse.x = (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
        this.mouse.y = -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, this.camera);

        // if the user is drawing points, the position of the mouse is used to move a 'ghost point'
        if (this.props.isDrawing) {
            let intersectsPool = this.raycaster.intersectObjects(this.pool.poolIntersectRegions.children, true);
            if (intersectsPool.length > 0) {
                this.sectionTool.ghostPoint.visible = true;
                // 0.50 precision
                this.sectionTool.ghostPoint.position.set(
                    Math.round(intersectsPool[0].point.x * 2) / 2,
                    Math.round(intersectsPool[0].point.y * 2) / 2,
                    Math.round(intersectsPool[0].point.z * 2) / 2);
                if (this.sectionTool.index > 0)
                    this.sectionTool.validateCustomPointsFormat();
            } else
                this.sectionTool.ghostPoint.visible = false;
        }
    }

    onDocumentMouseClick(event) {
        this.mouse.x = (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
        this.mouse.y = -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, this.camera);


        // if a user is drawing points and the mouse has not moved during a click we place a point on the pool
        if (this.props.isDrawing && this.mouseDX === this.mouseUX && this.mouseDY === this.mouseUY) {
            //unless one has allready drawn sufficient amounts of points
            if (this.props.canSendSection) {
                CommonUtils.createSnackMessage(this, <FormattedMessage id='THREESCENE.ALLREADY_FOUR_POINTS' />);
            } else {
                let intersectsPool = this.raycaster.intersectObjects(this.pool.poolIntersectRegions.children, true);
                if (intersectsPool.length > 0) {
                    // 0.50 precision
                    let x = Math.round(intersectsPool[0].point.x * 2) / 2;
                    let y = Math.round(intersectsPool[0].point.y * 2) / 2;
                    let z = Math.round(intersectsPool[0].point.z * 2) / 2;

                    let alertResponse = this.sectionTool.createCustomPoint(x, y, z);
                    if (alertResponse === SectionToolStatusTypes.POLYGON_SENDABLE) {
                        this.props.toggleSectionCanSend();
                    }
                    this.secIndex++;
                }
            }
        } // if not, we check if the user clicked a robot and set the camera to track it
        else {
            let robotIntersects = this.raycaster.intersectObjects(this.robotGroup.children, true);
            if (robotIntersects.length > 0) {
                let robotObject = robotIntersects[0].object.parent;
                let robot = this.props.robots.filter(r => r.ipAddress === robotObject.name)[0];
                this.props.selectRobot(robot);
            }
        }
    }

    onWaveCheck() {
        if (!this.state.isLoadingElements && this.water) {
            WaterSettings.WAVES_VISIBLE = !WaterSettings.WAVES_VISIBLE;
            this.water.updateSurfaceWaves(this.deltaTime);
            this.setState({
                showWaves: WaterSettings.WAVES_VISIBLE
            });
        }
    }

    async onButtonWaterClick() {
        if (!this.state.isLoadingElements && WaterSettings.IS_LOCATION_SET) {
            if (WaterSettings.WATER_VISIBLE)
                this.scene.remove(this.scene.getObjectByName('water'));
            else {
                await this.water.generate();
                CommonUtils.updateLevels(this)
                this.scene.add(this.water.mesh);
            }
            WaterSettings.WATER_VISIBLE = !WaterSettings.WATER_VISIBLE;
            this.setState({ showWater: WaterSettings.WATER_VISIBLE });
        }
    }

    onButtonGridClick() {
        if (!this.state.isLoadingElements) {
            GridSettings.VISIBLE ? this.scene.remove(this.grid.mesh) : this.scene.add(this.grid.mesh);
            GridSettings.VISIBLE = !GridSettings.VISIBLE;
            this.setState({
                showGrid: GridSettings.VISIBLE
            });
        }
    }

    onGridAdjust(gridValue) {
        GridSettings.DISTANCE = gridValue;
        this.setState({
            gridSize: GridSettings.DISTANCE,
            gridSizeInput: GridSettings.DISTANCE
        });

        this.scene.remove(this.grid.mesh);
        this.grid = new PoolGrid();
        this.grid.generate();
        this.scene.add(this.grid.mesh);
    }

    onButtonCameraClick() {
        if (!this.state.isLoadingElements)
            this.controls.reset();
        this.isTracking = false;
    }

    createNewSectionTool() {
        this.sectionTool = new SectionTool(null, this.scene);
    }

    render() {
        const { classes } = this.props;

        return (
            <div
                id="scene"
                onFocus={() => {
                    document.documentElement.scrollTop -= 100;
                }}
                ref={mount => {
                    this.mount = mount;
                }}
            >
                <div className={classes.threeScene}>
                    <div className={classes.sceneToolbarTop}>
                        {this.state.showWater ? <InformationMenuContainer
                            averageLabel={this.state.averageLabel}
                            currentLabel={this.state.currentLabel}
                            highOccursAtLabel={this.state.highOccursAtLabel}
                            highTideLabel={this.state.highTideLabel}
                            lowOccursAtLabel={this.state.lowOccursAtLabel}
                            lowTideLabel={this.state.lowTideLabel}
                        /> : <div />}
                        <ControlMenuContainer
                            onButtonCameraClick={this.onButtonCameraClick}
                            onButtonGridClick={this.onButtonGridClick}
                            onButtonWaterClick={this.onButtonWaterClick}
                            onGridAdjust={this.onGridAdjust}
                            onToggleWaves={this.onWaveCheck}
                        />
                    </div>
                </div>
                {CommonUtils.renderRobotInfo()}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    robots: selectRobots,
    site: selectCurrentSite,
    isDrawing: selectIsDrawing,
    isClearing: selectIsClearing,
    customSectionName: selectCustomSectionName,
    currentRobot: selectCurrentRobot,
    selectedRobots: selectSelectedRobots,
    canSendSection: selectCanSend,
    isSendingCustomSection: selectIsSendingSection,
    currentUser: selectCurrentUser,
});

const mapDispatchToProps = dispatch => ({
    toggleIsDrawing: () => dispatch(toggleIsDrawing()),
    toggleIsClearing: () => dispatch(toggleIsClearing()),
    setCurrentSite: (site) => dispatch(setCurrentSite(site)),
    selectRobot: (robot) => dispatch(selectRobot(robot)),
    toggleFeedbackHidden: () => dispatch(toggleFeedbackHidden()),
    setFeedbackMessage: (text) => dispatch(setFeedbackMessage(text)),
    toggleSectionCanSend: () => dispatch(toggleSectionCanSend()),
    resetCustomSectionName: () => dispatch(resetCustomSectionName()),
    toggleSendCustomSection: (customSectionName) => dispatch(toggleSendCustomSection(customSectionName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(LiveThreeScene));