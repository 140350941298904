module.exports = {
    locale: 'nb-NO',
    messages: {
        'INFORMATIONMENUCONTAINER.CURRENT_LABEL': 'Nå:',
        'INFORMATIONMENUCONTAINER.AVERAGE_LABEL': 'Gjennomsnitt:',
        'INFORMATIONMENUCONTAINER.HIGH_TIDE_LABEL': 'Flo:',
        'INFORMATIONMENUCONTAINER.OCCURS_AT_LABEL': 'Hender ved:',
        'INFORMATIONMENUCONTAINER.LOW_TIDE_LABEL': 'Fjære:',
    },
};
