import React from 'react';
import PropTypes from 'prop-types';
import { Stage, Layer, Image, Text } from 'react-konva';
import horizonBackground from '../../../../static/images/horizon_background.svg';
import horizonBall from '../../../../static/images/horizon_ball.svg';
import horizonCircle from '../../../../static/images/horizon_circle.svg';
import horizonMechanics from '../../../../static/images/horizon_mechanics.svg';

class AltitudeInstrument extends React.Component {
    static propTypes = {
        pitch: PropTypes.number,
        roll: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        textColor: PropTypes.string,
        textSize: PropTypes.number,
        scaleFactor: PropTypes.number,
    };

    static defaultProps = {
        roll: 0,
        pitch: 0,
        textColor: '#000',
        textSize: 14,
        scaleFactor: 1.0,
        width: 200,
        height: 200,
    };

    state = {
        horizonBall: null,
        horizonBackground: null,
        horizonCircle: null,
        horizonMechanics: null,
    };

    componentDidMount() {
        this.loadImages();
    }

    componentWillUnmount() {
        this.horizonBall.removeEventListener('load', this.handleLoadBall);
        this.horizonBackground.removeEventListener('load', this.handleLoadBackground);
        this.horizonCircle.removeEventListener('load', this.handleLoadCircle);
        this.horizonMechanics.removeEventListener('load', this.handleLoadMechanics);
    }

    loadImages() {
        this.horizonBall = new window.Image();
        this.horizonBall.src = horizonBall;
        this.horizonBall.addEventListener('load', this.handleLoadBall);

        this.horizonBackground = new window.Image();
        this.horizonBackground.src = horizonBackground;
        this.horizonBackground.addEventListener('load', this.handleLoadBackground);

        this.horizonCircle = new window.Image();
        this.horizonCircle.src = horizonCircle;
        this.horizonCircle.addEventListener('load', this.handleLoadCircle);

        this.horizonMechanics = new window.Image();
        this.horizonMechanics.src = horizonMechanics;
        this.horizonMechanics.addEventListener('load', this.handleLoadMechanics);
    }

    handleLoadBall = () => {
        this.setState({
            horizonBall: this.horizonBall,
        });
    };

    handleLoadBackground = () => {
        this.setState({
            horizonBackground: this.horizonBackground,
        });
    };

    handleLoadCircle = () => {
        this.setState({
            horizonCircle: this.horizonCircle,
        });
    };

    handleLoadMechanics = () => {
        this.setState({
            horizonMechanics: this.horizonMechanics,
        });
    };

    getPitch() {
        if (this.props.pitch >= 40.0) {
            return 40.0;
        } else if (this.props.pitch <= -35.0) {
            return -35.0;
        } else {
            return this.props.pitch;
        }
    }

    render() {
        if (this.state.horizonCircle !== null && this.state.horizonBall !== null && this.state.horizonBackground !== null && this.state.horizonMechanics !== null) {
            const pitch = this.getPitch();

            return (
                <Stage
                    width={this.props.width}
                    height={this.props.height}
                    scale={{
                        x: this.props.scaleFactor,
                        y: this.props.scaleFactor,
                    }}
                >
                    <Layer>
                        <Text
                            text={'Pitch: ' + this.props.pitch + '\xB0'}
                            fontSize={this.props.textSize}
                            fontFamily={'Lato, sans-serif'}
                            x={10}
                            y={185}
                            fill={this.props.textColor}
                        />
                        <Text
                            text={'Roll: ' + this.props.roll + '\xB0'}
                            fontSize={this.props.textSize}
                            fontFamily={'Lato, sans-serif'}
                            x={130}
                            y={185}
                            fill={this.props.textColor}
                        />
                        <Image
                            x={this.state.horizonBackground.x + this.state.horizonBackground.width / 2}
                            y={this.state.horizonBackground.y + this.state.horizonBackground.height / 2}
                            offsetX={this.state.horizonBackground.width / 2}
                            offsetY={this.state.horizonBackground.height / 2}
                            image={this.state.horizonBackground}
                            rotation={this.props.roll}
                        />
                        <Image
                            x={this.state.horizonBall.x + this.state.horizonBall.width / 2}
                            y={this.state.horizonBall.y + this.state.horizonBall.height / 2 + pitch}
                            offsetX={this.state.horizonBall.width / 2}
                            offsetY={this.state.horizonBall.height / 2}
                            image={this.state.horizonBall}
                            rotation={this.props.roll}
                        />
                        <Image
                            x={this.state.horizonCircle.x + this.state.horizonCircle.width / 2}
                            y={this.state.horizonCircle.y + this.state.horizonCircle.height / 2}
                            offsetX={this.state.horizonCircle.width / 2}
                            offsetY={this.state.horizonCircle.height / 2}
                            image={this.state.horizonCircle}
                            rotation={this.props.roll}
                        />
                        <Image
                            x={this.state.horizonMechanics.x + this.state.horizonMechanics.width / 2}
                            y={this.state.horizonMechanics.y + this.state.horizonMechanics.height / 2}
                            offsetX={this.state.horizonMechanics.width / 2}
                            offsetY={this.state.horizonMechanics.height / 2}
                            image={this.state.horizonMechanics}
                            rotation={0}
                        />
                    </Layer>
                </Stage>
            );
        }
        return null;
    }
}

export default AltitudeInstrument;
