import { feedbackActionTypes } from './feedbackTypes';

const INITIAL_STATE = {
    hidden: true,
    message: '',
};

const feedbackReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case feedbackActionTypes.TOGGLE_FEEDBACK_HIDDEN:
            return {
                ...state,
                hidden: !state.hidden,
            };
        case feedbackActionTypes.SET_MESSAGE:
            return {
                ...state,
                message: action.payload,
            };
        default:
            return state;
    }
};

export default feedbackReducer;
