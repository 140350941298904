module.exports = {
    locale: 'nb-NO',
    messages: {
        'CONTROLMENUCONTAINER.CONTROL_MENU': 'Kontrollmeny',
        'CONTROLMENUCONTAINER.RESET_CAMERA_LABEL': 'Nullstill kamera',
        'CONTROLMENUCONTAINER.SHOW_WATER_LABEL': 'Vis vannet',
        'CONTROLMENUCONTAINER.HIDE_WATER_LABEL': 'Skjul vannet',
        'CONTROLMENUCONTAINER.SHOW_GRID_LABEL': 'Vis grid',
        'CONTROLMENUCONTAINER.HIDE_GRID_LABEL': 'Skjul grid',
        'CONTROLMENUCONTAINER.ALTER_GRID_SIZE': 'Endre grid størrelse:',
        'CONTROLMENUCONTAINER.SHOW_WAVES_LABEL': 'Vis bølger',
    },
};