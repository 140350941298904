import * as THREE from 'three';
import { Refractor } from 'three/examples/jsm/objects/Refractor';
import Environment from './Environment';
import { PoolSettings, WaterSettings } from './OperationalValues';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { Line2 } from 'three/examples/jsm/lines/Line2';

class PoolWater extends Environment {
    constructor(waterdudv, topside) {
        super(waterdudv);
        this.mesh = null;
        this.surface1 = null;
        this.surface2 = null;
        this.dudv = waterdudv;
        this.obs = null;
        this.lowTide = null;
        this.highTide = null;
        this.lowTime = null;
        this.highTime = null;
        this.topSide = topside;
    }

    async generate() {
        super.generate();

        this.mesh = new THREE.Group();
        const xml = await this.getWaterLevels()
        let parser = new DOMParser();
        if (xml !== undefined && xml !== null) {
            let xmlObs = parser.parseFromString(xml[0], 'text/xml');
            let xmlTide = parser.parseFromString(xml[1], 'text/xml');
            if (xmlObs.getElementsByTagName('nodata').length > 0
                || xmlObs.getElementsByTagName('error').length > 0
                || xmlTide.getElementsByTagName('nodata').length > 0
                || xmlTide.getElementsByTagName('error').length > 0) {
                alert('An error occurred while trying to get the water level from kartverket');
                WaterSettings.WATER_VISIBLE = false;
                // TODO state must be updated also
            } else {
                this.obs = parseFloat(xmlObs.getElementsByTagName('waterlevel')[xmlObs.getElementsByTagName('waterlevel').length - 1].getAttribute('value')) / 100;
                this.lowTide = xmlTide.getElementsByTagName('waterlevel')[0].getAttribute('flag') === 'low' ?
                    parseFloat(xmlTide.getElementsByTagName('waterlevel')[0].getAttribute('value')) /
                    100 :
                    parseFloat(xmlTide.getElementsByTagName('waterlevel')[1].getAttribute('value')) /
                    100;

                this.lowTime = xmlTide.getElementsByTagName('waterlevel')[0].getAttribute('flag') === 'low' ?
                    xmlTide.getElementsByTagName('waterlevel')[0].getAttribute('time') :
                    xmlTide.getElementsByTagName('waterlevel')[1].getAttribute('time');

                this.highTide = xmlTide.getElementsByTagName('waterlevel')[0].getAttribute('flag') === 'high' ?
                    parseFloat(xmlTide.getElementsByTagName('waterlevel')[0].getAttribute('value')) /
                    100 :
                    parseFloat(xmlTide.getElementsByTagName('waterlevel')[1].getAttribute('value')) /
                    100;

                this.highTime = xmlTide.getElementsByTagName('waterlevel')[0].getAttribute('flag') === 'high' ?
                    xmlTide.getElementsByTagName('waterlevel')[0].getAttribute('time') :
                    xmlTide.getElementsByTagName('waterlevel')[1].getAttribute('time');
                this.updateWaterLevel();
            }
        } else {
            alert('An error occurred while trying to get the water level from kartverket');
            WaterSettings.WATER_VISIBLE = false;
            // TODO state must be updated also
        }
    }

    generateSurface(level) {
        let surfaceShape = new THREE.Shape();
        (function roundedRect(shape, startX, startY, width, length, radius) {
            shape.moveTo(startX, -radius);
            shape.lineTo(startX, -length + radius);
            shape.absarc(radius, -length + radius, radius, Math.PI, Math.PI + Math.PI / 2, false);
            shape.lineTo(width - radius, -length);
            shape.absarc(width - radius, -length + radius, radius, Math.PI + Math.PI / 2, 2 * Math.PI, false);
            shape.lineTo(width, -radius);
            shape.absarc(width - radius, -radius, radius, 0, Math.PI / 2, false);
            shape.lineTo(radius, 0);
            shape.absarc(radius, -radius, radius, Math.PI / 2, Math.PI, false);
        })(surfaceShape, 0, 0, PoolSettings.WIDTH, PoolSettings.LENGTH_TOTAL, PoolSettings.RADIUS);
        let surfaceGeo = new THREE.ShapeBufferGeometry(surfaceShape);

        if (this.surface1 === null || this.surface2 === null) {
            this.surface1 = new Refractor(surfaceGeo, {
                color: new THREE.Color(WaterSettings.COLOR_WATER),
                textureWidth: WaterSettings.SURFACE_RESOLUTION,
                textureHeight: WaterSettings.SURFACE_RESOLUTION,
                shader: this.getSurfaceShader()
            });
            this.surface2 = new Refractor(surfaceGeo, {
                color: new THREE.Color(WaterSettings.COLOR_WATER),
                textureWidth: WaterSettings.SURFACE_RESOLUTION,
                textureHeight: WaterSettings.SURFACE_RESOLUTION,
                shader: this.getSurfaceShader()
            });
        }

        this.surface1.rotation.set(-Math.PI / 2, Math.PI, 0);
        this.surface1.position.set(PoolSettings.WIDTH, level || WaterSettings.AVG_DEPTH, -PoolSettings.LENGTH_TOTAL);
        this.mesh.add(this.surface1);

        this.surface2.rotation.set(Math.PI / 2, Math.PI, 0);
        this.surface2.position.set(PoolSettings.WIDTH, level || WaterSettings.AVG_DEPTH, 0);
        this.mesh.add(this.surface2);
    }

    async getWaterLevels() {
        let isDST = new Date().getTimezoneOffset() === -120 ? '1' : '0';
        let time = new Date().getTime();

        try {
            const responses = await Promise.all([
                fetch(
                    // Observations
                    'https://api.sehavniva.no/tideapi.php?lat=' + WaterSettings.LATITUDE +
                    '&lon=' + WaterSettings.LONGITUDE +
                    // From 3 hours ago
                    '&fromtime=' + this.createDateString(new Date(time - 3 * 60 * 60 * 1000)) +
                    // To now
                    '&totime=' + this.createDateString(new Date(time)) +
                    '&datatype=obs&refcode=' + WaterSettings.DATA_TYPE +
                    '&place=&file=&lang=en&interval=' + WaterSettings.INTERVAL +
                    '&dst=' + isDST + '&tzone=&tide_request=locationdata'),
                fetch(
                    // Tides
                    'https://api.sehavniva.no/tideapi.php?lat=' + WaterSettings.LATITUDE +
                    '&lon=' + WaterSettings.LONGITUDE +
                    // From now
                    '&fromtime=' + this.createDateString(new Date(time)) +
                    // To 24 hours from now
                    '&totime=' + this.createDateString(new Date(time + 24 * 60 * 60 * 1000)) +
                    '&datatype=tab&refcode=' + WaterSettings.DATA_TYPE +
                    '&place=&file=&lang=en&interval=' + WaterSettings.INTERVAL +
                    '&dst=' + isDST + '&tzone=&tide_request=locationdata')
            ])

            return await Promise.all(responses.map(async response => await response.text()))
        } catch (error) {
            console.error('An error occured when trying to get the water level from kartverket', error);
            WaterSettings.WATER_VISIBLE = false;
            // TODO state must be updated also
        }
    }


    createDateString(date) {
        let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
        let day = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString();
        let hours = date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours().toString();
        let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes().toString();
        return `${date.getFullYear().toString()}-${month}-${day}T${hours}%3A${minutes}`;
    }

    updateWaterLevel() {
        this.generateSurface(this.obs + WaterSettings.AVG_DEPTH);
        this.generateUnderWater(this.obs + WaterSettings.AVG_DEPTH);
        this.generateLevelLine(this.obs + WaterSettings.AVG_DEPTH, new THREE.Color(WaterSettings.COLOR_NOW));
        this.generateLevelLine(WaterSettings.AVG_DEPTH, new THREE.Color(WaterSettings.COLOR_AVG));
        this.generateLevelLine(this.lowTide + WaterSettings.AVG_DEPTH, new THREE.Color(WaterSettings.COLOR_LOW));
        this.generateLevelLine(this.highTide + WaterSettings.AVG_DEPTH, new THREE.Color(WaterSettings.COLOR_HIGH));
        this.generateDirectionArrows();
        this.generateTopSide(this.obs + WaterSettings.AVG_DEPTH);
        this.mesh.name = 'water';
    }

    generateTopSide(level) {
        this.topSide.position.set(PoolSettings.WIDTH / 2, level, -PoolSettings.LENGTH_TOTAL + 2.6 / 2 + 0.01);
        this.topSide.rotation.set(0, Math.PI, 0);
        this.mesh.add(this.topSide);
    }

    generateLevelLine(level, color) {
        let material = new LineMaterial({
            color: color,
            linewidth: WaterSettings.LINE_DIM,
            dashed: false
        });

        let path2D = new THREE.Path();
        path2D.moveTo(0, -PoolSettings.RADIUS);
        path2D.lineTo(0, -PoolSettings.LENGTH_TOTAL + PoolSettings.RADIUS);
        path2D.absarc(PoolSettings.RADIUS, -PoolSettings.LENGTH_TOTAL +
            PoolSettings.RADIUS, PoolSettings.RADIUS, Math.PI,
            Math.PI + Math.PI / 2, false);
        path2D.lineTo(PoolSettings.WIDTH - PoolSettings.RADIUS, -PoolSettings.LENGTH_TOTAL);
        path2D.absarc(PoolSettings.WIDTH - PoolSettings.RADIUS,
            -PoolSettings.LENGTH_TOTAL + PoolSettings.RADIUS, PoolSettings.RADIUS,
            Math.PI + Math.PI / 2, 2 * Math.PI, false);
        path2D.lineTo(PoolSettings.WIDTH, -PoolSettings.RADIUS);
        path2D.absarc(PoolSettings.WIDTH - PoolSettings.RADIUS,
            -PoolSettings.RADIUS, PoolSettings.RADIUS,
            0, Math.PI / 2, false);
        path2D.lineTo(PoolSettings.RADIUS, 0);
        path2D.absarc(PoolSettings.RADIUS, -PoolSettings.RADIUS, PoolSettings.RADIUS,
            Math.PI / 2, Math.PI, false);

        let path3D = [];
        for (let point in path2D.getPoints())
            path3D.push(path2D.getPoints()[point].x, level, path2D.getPoints()[point].y);

        let geo = new LineGeometry();
        geo.setPositions(path3D);

        let line = new Line2(geo, material);
        line.computeLineDistances();
        line.scale.set(1, 1, 1);
        this.mesh.add(line);
    }

    generateDirectionArrows() {
        let dir = WaterSettings.ARROW_DIR;
        dir.normalize();

        let startInlet1 = new THREE.Vector3(10, WaterSettings.AVG_DEPTH / 2,
            WaterSettings.ARROW_LENGTH);
        let startInlet2 = new THREE.Vector3(PoolSettings.WIDTH - 10, WaterSettings.AVG_DEPTH / 2,
            WaterSettings.ARROW_LENGTH);
        let startOutlet1 = new THREE.Vector3(10, WaterSettings.AVG_DEPTH / 2,
            -PoolSettings.LENGTH_TOTAL);
        let startOutlet2 = new THREE.Vector3(PoolSettings.WIDTH - 10, WaterSettings.AVG_DEPTH / 2,
            -PoolSettings.LENGTH_TOTAL);

        let arrowInlet1 = new THREE.ArrowHelper(dir, startInlet1, WaterSettings.ARROW_LENGTH, WaterSettings.COLOR_ARROW,
            WaterSettings.ARROW_HEAD_LENGTH, WaterSettings.ARROW_HEAD_WIDTH);
        let arrowInlet2 = new THREE.ArrowHelper(dir, startInlet2, WaterSettings.ARROW_LENGTH, WaterSettings.COLOR_ARROW,
            WaterSettings.ARROW_HEAD_LENGTH, WaterSettings.ARROW_HEAD_WIDTH);
        let arrowOutlet1 = new THREE.ArrowHelper(dir, startOutlet1, WaterSettings.ARROW_LENGTH,
            WaterSettings.COLOR_ARROW,
            WaterSettings.ARROW_HEAD_LENGTH, WaterSettings.ARROW_HEAD_WIDTH);
        let arrowOutlet2 = new THREE.ArrowHelper(dir, startOutlet2, WaterSettings.ARROW_LENGTH,
            WaterSettings.COLOR_ARROW,
            WaterSettings.ARROW_HEAD_LENGTH, WaterSettings.ARROW_HEAD_WIDTH);
        this.mesh.add(arrowInlet1, arrowInlet2, arrowOutlet1, arrowOutlet2);
    }

    updateSurfaceWaves(delta) {
        if (this.surface1 && this.surface2)
            if (WaterSettings.WAVES_VISIBLE) {
                if (this.surface1.material.uniforms['tDudv'].value === null) {
                    this.dudv.wrapS = this.dudv.wrapT = THREE.RepeatWrapping;
                    this.surface1.material.uniforms['tDudv'].value = this.dudv;
                    this.surface2.material.uniforms['tDudv'].value = this.dudv;
                }
                this.surface1.material.uniforms['time'].value += delta;
                this.surface2.material.uniforms['time'].value += delta;
            } else { // Keep the surface but remove the wave effect
                if (this.surface1.material.uniforms['tDudv'].value !== null) {
                    this.surface1.material.uniforms['tDudv'].value = null;
                    this.surface2.material.uniforms['tDudv'].value = null;
                }
            }
    }

    // TODO use post processing color filter when camera is under water? (will improve transparency issue, uncertain how much it will
    // affect performance)
    //https://threejsfundamentals.org/threejs/lessons/threejs-post-processing.html
    generateUnderWater(level) {
        let r = PoolSettings.RADIUS,
            w = PoolSettings.WIDTH,
            l = PoolSettings.LENGTH,
            lTot = PoolSettings.LENGTH_TOTAL,
            h = level || WaterSettings.AVG_DEPTH,
            smoothness = PoolSettings.SMOOTHNESS,
            gap = WaterSettings.GAP,
            material = new THREE.MeshBasicMaterial({
                color: WaterSettings.COLOR_WATER,
                transparent: WaterSettings.UW_TRANSPARENT,
                opacity: WaterSettings.UW_OPACITY,
                wireframe: false
            });
        material.side = WaterSettings.UW_SIDE;

        // Round corners
        let cornerGeo = new THREE.SphereGeometry(r, smoothness, smoothness, 0, Math.PI / 2,
            Math.PI / 2, Math.PI / 2);
        let corner = new THREE.Mesh(cornerGeo, material);
        let cornerProps = [
            { x: w - r - gap, z: -r - gap },
            { x: w - r - gap, z: -lTot + r + gap },
            { x: r + gap, z: -lTot + r + gap },
            { x: r + gap, z: -r - gap }
        ];
        for (let prop in cornerProps) {
            corner.position.set(cornerProps[prop].x, r + gap, cornerProps[prop].z);
            corner.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI / 2);
            this.mesh.add(corner.clone());
        }

        // Curvatures
        let curvatureGeo = new THREE.CylinderGeometry(r, r, 1, smoothness, 1,
            true, -Math.PI / 2, Math.PI / 2);
        let curvature = new THREE.Mesh(curvatureGeo, material);
        let curvatureProps = [
            // Wall-curvatures
            {
                x: w - r - gap,
                y: h / 2 + r / 2 + gap / 2,
                z: -r - gap,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                height: h - r - gap
            },
            {
                x: w - r - gap,
                y: h / 2 + r / 2 + gap / 2,
                z: -lTot + r + gap,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                height: h - r - gap
            },
            {
                x: r + gap,
                y: h / 2 + r / 2 + gap / 2,
                z: -lTot + r + gap,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                height: h - r - gap
            },
            {
                x: r + gap,
                y: h / 2 + r / 2 + gap / 2,
                z: -r - gap,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                height: h - r - gap
            },
            // Floor-curvatures width
            {
                x: w / 2,
                y: r + gap,
                z: -r - gap,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 0, 1),
                height: w - r * 2 - gap * 2
            },
            {
                x: w / 2,
                y: r + gap,
                z: -lTot + r + gap,
                rot: -Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                height: w - r * 2 - gap * 2
            },
            // Floor-curvatures length
            {
                x: r + gap,
                y: r + gap,
                z: -lTot / 2,
                rot: -Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 0, 1),
                height: lTot - r * 2 - gap * 2
            },
            {
                x: w - r - gap,
                y: r + gap,
                z: -lTot / 2,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                height: lTot - r * 2 - gap * 2
            }
        ];
        for (let prop in curvatureProps) {
            curvature.scale.y = curvatureProps[prop].height;
            curvature.position.set(curvatureProps[prop].x, curvatureProps[prop].y, curvatureProps[prop].z);
            curvature.rotateOnAxis(curvatureProps[prop].rotAxis, curvatureProps[prop].rot);
            this.mesh.add(curvature.clone());
        }

        // Faces (walls and floor)
        let faceGeo = new THREE.PlaneGeometry(1, 1, 1, 1);
        let face = new THREE.Mesh(faceGeo, material);
        let faceProps = [
            // Width
            {
                x: w / 2,
                y: h / 2 + r / 2 + gap / 2,
                z: -gap,
                rot: 0,
                rotAxis: new THREE.Vector3(0, 1, 0),
                width: w - r * 2 - gap * 2,
                height: h - r - gap
            },
            {
                x: w / 2,
                y: h / 2 + r / 2 + gap / 2,
                z: -lTot + gap,
                rot: Math.PI,
                rotAxis: new THREE.Vector3(0, 1, 0),
                width: w - r * 2 - gap * 2,
                height: h - r - gap
            },
            // Length
            {
                x: w - gap,
                y: h / 2 + r / 2 + gap / 2,
                z: -lTot / 2,
                rot: -Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                width: lTot - r * 2 - gap * 2,
                height: h - r - gap
            },
            {
                x: gap,
                y: h / 2 + r / 2 + gap / 2,
                z: -lTot / 2,
                rot: Math.PI,
                rotAxis: new THREE.Vector3(0, 1, 0),
                width: lTot - r * 2 - gap * 2,
                height: h - r - gap
            },
            // Floor
            {
                x: w / 2,
                y: gap,
                z: -lTot / 2,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(1, 0, 0),
                width: lTot - r * 2 - gap * 2,
                height: w - r * 2 - gap * 2
            }
        ];
        for (let prop in faceProps) {
            face.scale.x = faceProps[prop].width;
            face.scale.y = faceProps[prop].height;
            face.position.set(faceProps[prop].x, faceProps[prop].y, faceProps[prop].z);
            face.rotateOnAxis(faceProps[prop].rotAxis, faceProps[prop].rot);
            this.mesh.add(face.clone());
        }

        // Differential pressure wall
        // TODO change form quadraticCurve to absarc - see generateInletSection in Site.js
        let dPWallShape = new THREE.Shape();
        (function roundedRect(shape, startX, startY, width, height, radius) {
            shape.moveTo(startX, startY + radius);
            shape.lineTo(startX, startY + height);
            shape.lineTo(startX + width, startY + height);
            shape.lineTo(startX + width, startY + radius);
            shape.quadraticCurveTo(startX + width, startY, startX + width - radius, startY);
            shape.lineTo(startX + radius, startY);
            shape.quadraticCurveTo(startX, startY, startX, startY + radius);
        })(dPWallShape, 0, 0, w, h, r);
        let dPWallGeo = new THREE.ShapeBufferGeometry(dPWallShape);
        let dPWall = new THREE.Mesh(dPWallGeo, material);
        dPWall.position.set(0, 0, -lTot + l - gap);
        this.mesh.add(dPWall.clone());
        dPWall.position.set(0, 0, -lTot + l + gap);
        this.mesh.add(dPWall.clone());
    }

    getSurfaceShader() {
        return {
            uniforms: {
                'color': {
                    value: null
                },
                'time': {
                    value: 0
                },
                'tDiffuse': {
                    value: null
                },
                'tDudv': {
                    value: null
                },
                'textureMatrix': {
                    value: null
                }
            },

            vertexShader: [

                'uniform mat4 textureMatrix;',

                'varying vec2 vUv;',
                'varying vec4 vUvRefraction;',

                'void main() {',

                '	vUv = uv;',

                '	vUvRefraction = textureMatrix * vec4( position, 1.0 );',

                '	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',

                '}'

            ].join('\n'),

            fragmentShader: [

                'uniform vec3 color;',
                'uniform float time;',
                'uniform sampler2D tDiffuse;',
                'uniform sampler2D tDudv;',

                'varying vec2 vUv;',
                'varying vec4 vUvRefraction;',

                'float blendOverlay( float base, float blend ) {',

                '	return( base < 0.5 ? ( 2.0 * base * blend ) : ( 1.0 - 2.0 * ( 1.0 - base ) * ( 1.0 - blend ) ) );',

                '}',

                'vec3 blendOverlay( vec3 base, vec3 blend ) {',

                '	return vec3( blendOverlay( base.r, blend.r ), blendOverlay( base.g, blend.g ),blendOverlay( base.b, blend.b ) );',

                '}',

                'void main() {',

                ' float waveStrength = ' + WaterSettings.WAVE_STRENGTH + ';',
                ' float waveSpeed = ' + WaterSettings.WAVE_SPEED + ';',

                // simple distortion (ripple) via dudv map (see
                // https://www.youtube.com/watch?v=6B7IF6GOu7s)

                '	vec2 distortedUv = texture2D( tDudv, vec2( vUv.x + time * waveSpeed, vUv.y ) ).rg * waveStrength;',
                '	distortedUv = vUv.xy + vec2( distortedUv.x, distortedUv.y + time * waveSpeed );',
                '	vec2 distortion = ( texture2D( tDudv, distortedUv ).rg * 2.0 - 1.0 ) * waveStrength;',

                // new uv coords

                ' vec4 uv = vec4( vUvRefraction );',
                ' uv.xy += distortion;',

                '	vec4 base = texture2DProj( tDiffuse, uv );',

                '	gl_FragColor = vec4( blendOverlay( base.rgb, color ), 1.0 );',

                '}'

            ].join('\n')
        };
    }

    addNewUpdate = (update) => {
        this.update = update;
    }
}

export default PoolWater;