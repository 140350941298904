module.exports = {
    locale: 'nb-NO',
    messages: {
        'SETTINGSDRAWER.TITLE': '{robot} Innstillinger',
        'SETTINGSDRAWER.ROBOT_CONTROL_LABEL_SHOW_ROBOT': 'Vis robot',
        'SETTINGSDRAWER.ROBOT_CONTROL_LABEL_SHOW_TRAIL': 'Vis sti',
        'SETTINGSDRAWER.ROBOT_CONTROL_LABEL_SHOW_PATH': 'Vis bane',
        'SETTINGSDRAWER.ROBOT_CONTROL_LABEL_SHOW_SECTION': 'Vis seksjon',
        'SETTINGSDRAWER.SECTION_LABEL': 'Seksjon:',
        'SETTINGSDRAWER.BTN_PREDEFINED': 'Send seksjon',
        'CUSTOMSECTIONTOOL.SECTION_NAME': 'Seksjonsnavn',
        'CUSTOMSECTIONTOOL.QUIT_DRAWING': 'Slutt å tegne',
        'CUSTOMSECTIONTOOL.DRAW': 'Tegn',
        'CUSTOMSECTIONTOOL.CLEAR_SECTION': 'Fjern',
        'CUSTOMSECTIONTOOL.SEND_CUSTOM_SECTION': 'Lag',
        'CUSTOMSECTIONTOOL.CREATE_CUSTOM_SECTION': 'Lag tilpasset seksjon',
        'CUSTOMSECTIONTOOL.SECTION_NAME_HELPER_TEXT': 'Minst 3 karakter',

        'SETTINGSDRAWER.STEP_BUTTON_NEXT_LABEL': 'Neste >',
        'SETTINGSDRAWER.STEP_BUTTON_PREV_LABEL': '< Forrige',
        'SETTINGSDRAWER.STEP_ONE_TEXT': 'Skriv et navn...',
        'SETTINGSDRAWER.STEP_TWO_TEXT': 'Tegn 4 punkter ved å klikke på en vegg eller gulvet.',
        'SETTINGSDRAWER.STEP_THREE_TEXT': 'Generer seksjon og lagre den.',

        'SETTINGSDRAWER.SECTION_SET_SUCCESSFULLY': 'Robot "{robotName}" har nå mottatt seksjon "{sectionName}" ',
        'SETTINGSDRAWER.ROBOT_ALLREADY_HAS_SECTION': 'Robot "{robotName}" har allerede tildelt seksjon "{sectionName}" ',
        'SETTINGSDRAWER.SECTION_IS_TAKEN': 'Seksjon "{sectionName}" er allerede tildelt robot "{robotName}',

        'ROBOTCONTROL.PERCENTAGE_PATH_LENGTH': '{pathLength}% banelengde',
        'ROBOTCONTROL.PERCENTAGE_TRAIL_LENGTH': '{trailLength}% stilengde',

        'SETTINGSDRAWER.ROV_MODE': 'Rov modus',
        'SETTINGSDRAWER.ROBOT_MODE': 'Robot modus',

        'SETTINGSDRAWER.JOYSICK_ACTIVE': 'Joystick er koblet til {robot}',
        'SETTINGSDRAWER.JOYSICK_NOT_ACTIVE': 'Joystick er koblet fra {robot}',

        'SETTINGSDRAWER.JOYSICK_CONNECT_ERROR': 'Noe har gått galt. Kunne ikke koble til {robot}',
    },
};
