import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import LanguageIcon from '@material-ui/icons/Language';
import LightIcon from '@material-ui/icons/Brightness7';
import DarkIcon from '@material-ui/icons/Brightness4';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import ExitIcon from '@material-ui/icons/ExitToApp';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';

import { selectCurrentUser } from '../../../redux/user/userSelector';

import { styles } from './TopMenuStyles';
import { selectIsJoystickConnected, selectIsLive } from '../../../redux/mode/modeSelector';
import { toggleUseLiveSceneMode } from '../../../redux/mode/modeActions';
import { unselectAllRobots } from '../../../redux/robot/robotActions';

class TopMenu extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        numOfNotifications: PropTypes.number,
        dayTheme: PropTypes.bool,
        onLanguageChange: PropTypes.func,
        onConnectToRobot: PropTypes.func,
        onToggleTheme: PropTypes.func,
        onLogout: PropTypes.func,
    };

    static defaultProps = {
        numOfNotifications: 0,
        dayTheme: true,
        onLanguageChange: null,
        onConnectToRobot: null,
        onToggleTheme: null,
        onLogout: null,
    };

    state = {
        anchorElProfile: null,
        anchorElLanguage: null,
        mobileMoreAnchorEl: null,
    };

    handleLogout = () => {
        if (this.props.onLogout) {
            this.props.onLogout();
        }
    };

    handleLanguageMenuOpen = event => {
        this.setState({
            anchorElLanguage: event.currentTarget
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorElProfile: null,
            anchorElLanguage: null,
        });
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    handleChangeLanguage = langCode => () => {
        if (this.props.onLanguageChange) {
            this.setState({
                anchorElLanguage: null,
            }, () => {
                this.props.onLanguageChange(langCode);
            });
        }
    };

    handleToggleTheme = () => {
        if (this.props.onToggleTheme) {
            this.props.onToggleTheme();
        }
    };

    handleToggleSceneMode = () => {
        this.props.unselectAllRobots();
        this.props.toggleSceneMode();
    };

    renderToggleTheme() {
        let icon = <DarkIcon />;

        if (!this.props.dayTheme) {
            icon = <LightIcon />;
        }

        return (
            <Tooltip title={<FormattedMessage id="TOPMENU.TOGGLE_THEME" />}>
                <IconButton
                    color="inherit"
                    onClick={this.handleToggleTheme}
                >
                    {icon}
                </IconButton>
            </Tooltip>
        );
    }

    renderLogoutIcon() {
        if (this.props.currentUser) {
            return (
                <Tooltip title={<FormattedMessage id='TOPMENU.LOG_OUT' />}>
                    <IconButton
                        color="inherit"
                        onClick={this.handleLogout}
                    >
                        <ExitIcon />
                    </IconButton>
                </Tooltip>
            );
        }
    }

    renderToggleSceneModeButton() {
        return (
            <ToggleButtonGroup
                value={this.props.isLiveMode}
                exclusive={true}
                onChange={this.handleToggleSceneMode}
            >
                <ToggleButton value={true}>
                    <Tooltip title={<FormattedMessage id='TOPMENU.USE_LIVE_MODE' />}>
                        <LiveTvIcon />
                    </Tooltip>
                </ToggleButton>
                <ToggleButton value={false}>
                    <Tooltip title={<FormattedMessage id='TOPMENU.USE_SIMULATION_MODE' />}>
                        <SportsEsportsIcon />
                    </Tooltip>
                </ToggleButton>
            </ToggleButtonGroup>
        );
    }

    renderJoystickIcon() {
        let joyStyle = this.props.theme.customPalette.red;
        if (this.props.isJoystickConnected) {
            joyStyle = this.props.theme.customPalette.darkGreen;
        }

        return (
            <IconButton
                disabled={true}
                style={{ color: `${joyStyle}` }}
            >
                <Icon>gamepad</Icon>
            </IconButton>
        );
    }

    render() {
        const { anchorElLanguage, mobileMoreAnchorEl } = this.state;
        const { classes } = this.props;
        const isLanguageMenuOpen = Boolean(anchorElLanguage);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const renderLanguageMenu = (
            <Menu
                id="language-menu"
                anchorEl={anchorElLanguage}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isLanguageMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem
                    onClick={this.handleChangeLanguage('nb-no')}
                >
                    Norsk
                </MenuItem>
                <MenuItem
                    onClick={this.handleChangeLanguage('en-gb')}
                >
                    English
                </MenuItem>
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem onClick={this.handleLanguageMenuOpen}>
                    <IconButton color="inherit">
                        <LanguageIcon />
                    </IconButton>
                    <p><FormattedMessage id="TOPMENU.MENU_ITEM_LANGUAGE" /></p>
                </MenuItem>
            </Menu>
        );

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                            {this.props.currentUser ? <FormattedMessage id="TOPMENU.TITLE" /> : ''}
                        </Typography>
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            {this.renderJoystickIcon()}
                            {this.renderToggleSceneModeButton()}
                            {this.renderToggleTheme()}
                            <Tooltip title={<FormattedMessage id='TOPMENU.CHANGE_LANGUAGE' />}>
                                <IconButton
                                    aria-owns={isLanguageMenuOpen ? 'language-menu' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleLanguageMenuOpen}
                                    color="inherit"
                                >
                                    <LanguageIcon />
                                </IconButton>
                            </Tooltip>
                            {this.renderLogoutIcon()}
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderLanguageMenu}
                {renderMobileMenu}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    isLiveMode: selectIsLive,
    isJoystickConnected: selectIsJoystickConnected,
});

const mapDispatchToProps = dispatch => ({
    toggleSceneMode: () => dispatch(toggleUseLiveSceneMode()),
    unselectAllRobots: () => dispatch(unselectAllRobots()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(TopMenu));
