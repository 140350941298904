import { sectionActionTypes } from "./sectionTypes";

export const toggleIsDrawing = () => ({
    type: sectionActionTypes.TOGGLE_IS_DRAWING,
});

export const toggleIsClearing = () => ({
    type: sectionActionTypes.TOGGLE_IS_CLEARING,
});

export const setCustomSectionName = name => ({
    type: sectionActionTypes.SET_CUSTOM_SECTION_NAME,
    payload: name,
});

export const toggleSectionCanSend = () => ({
    type: sectionActionTypes.TOGGLE_SECTION_CAN_SEND,
});

export const resetCustomSectionName = () => ({
    type: sectionActionTypes.RESET_CUSTOM_SECTION_NAME,
});

export const toggleSendCustomSection = (sectionName) => ({
    type: sectionActionTypes.TOGGLE_SEND_CUSTOM_SECTION,
    payload: sectionName,
});