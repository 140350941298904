import Environment from './Environment';
import * as THREE from 'three';
import { SectionToolSettings } from './OperationalValues';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { store } from '../../../../redux/store';
import Axios from 'axios';
import SectionToolStatusTypes from './SectionToolStatusTypes';
import { setRobotPath } from '../../../../redux/robot/robotActions';

class SectionTool extends Environment {

    constructor(assets = null, scene) {
        super(assets);
        this.color = new THREE.Color(Math.random(), Math.random(), Math.random());
        this.invalidColor = new THREE.Color('rgb(255, 0, 0)');
        this.mesh = new Array(4);
        this.lines = new Array(4);
        this.index = 0;
        this.scene = scene;
        this.point = null;
        this.ghostPoint = null;
        this.pathPoints = null;
    }

    generate() {
        super.generate();
        let geo = new THREE.SphereBufferGeometry(SectionToolSettings.POINT_RADIUS, SectionToolSettings.POINT_SEGMENTS,
            SectionToolSettings.POINT_SEGMENTS);
        let matGhost = new THREE.MeshBasicMaterial({
            color: this.color,
            transparent: SectionToolSettings.GHOST_TRANSPARENT,
            opacity: SectionToolSettings.GHOST_OPACITY
        });
        this.ghostPoint = new THREE.Mesh(geo, matGhost);
        this.ghostPoint.name = 'ghostPoint';
        this.ghostPoint.visible = false;
    }

    createLineBetweenAdjacentPoints(lastPoint, currentPoint) {
        let mat = new LineMaterial({
            color: this.color,
            linewidth: 0.003,
            dashed: false
        });
        let geo = new LineGeometry();
        let positions = [];
        positions.push(lastPoint.position.x, lastPoint.position.y, lastPoint.position.z);
        positions.push(currentPoint.position.x, currentPoint.position.y, currentPoint.position.z);
        geo.setPositions(positions);
        let path = new Line2(geo, mat);
        path.computeLineDistances();
        path.scale.set(1, 1, 1);
        this.lines.push(path);
        this.scene.add(path);
    }

    createLineGeo = (waypoints) => {
        let geo = new LineGeometry();
        let positions = [];
        waypoints.forEach(p => positions.push(p.x, p.y, p.z));
        geo.setPositions(positions);
        geo.verticesNeedUpdate = true;
        return geo;
    }

    createLine = (waypoints, name, color) => {
        let mat = new LineMaterial({
            color: color,
            linewidth: 0.002,
            dashed: false,
        });

        let geo = this.createLineGeo(waypoints);
        let path = new Line2(geo, mat);
        path.computeLineDistances();
        path.scale.set(1, 1, 1);
        path.name = name;
        return path;
    }

    createCustomPoint(x, y, z) {
        if (this.index === 0) {
            let geo = new THREE.SphereBufferGeometry(SectionToolSettings.POINT_RADIUS, SectionToolSettings.POINT_SEGMENTS,
                SectionToolSettings.POINT_SEGMENTS);
            let mat = new THREE.MeshBasicMaterial({ color: this.color });
            this.point = new THREE.Mesh(geo, mat);
            this.point.name = 'point';
            this.point.position.set(x, y, z);
            this.mesh[this.index] = this.point;
            this.scene.add(this.point);
            this.index++;

            return SectionToolStatusTypes.NO_ALERT;
        } else if (this.index < 4) {
            if (this.validateCustomPointsFormat()) {
                let geo = new THREE.SphereBufferGeometry(SectionToolSettings.POINT_RADIUS, SectionToolSettings.POINT_SEGMENTS,
                    SectionToolSettings.POINT_SEGMENTS);
                let mat = new THREE.MeshBasicMaterial({ color: this.color });
                this.point = new THREE.Mesh(geo, mat);
                this.point.name = 'point';
                this.point.position.set(x, y, z);
                this.mesh[this.index] = this.point;
                this.scene.add(this.point);
                this.createLineBetweenAdjacentPoints(this.mesh[this.index - 1], this.mesh[this.index]);
                this.index++;

                if (this.index === 4)
                    this.createLineBetweenAdjacentPoints(this.mesh[0], this.mesh[this.index - 1]);

                return SectionToolStatusTypes.NO_ALERT;
            } else
                return SectionToolStatusTypes.SECTION_MUST_BE_RECTANGULAR_AND_MIN_80CM;
        }

        return SectionToolStatusTypes.POLYGON_SENDABLE;
    }

    loadCustomSectionPath = async (p1, p2, p3, p4) => {

        let plane;
        let leftOver;
        let ox = [];
        let oy = [];
        if (
            p1.x === p2.x &&
            p2.x === p3.x &&
            p3.x === p4.x
        ) {
            plane = 'yz';
            leftOver = p1.x;
        } else if (
            p1.y === p2.y &&
            p2.y === p3.y &&
            p3.y === p4.y
        ) {
            plane = 'xz';
            leftOver = p1.y;
        } else if (
            p1.z === p2.z &&
            p2.z === p3.z &&
            p3.z === p4.z
        ) {
            plane = 'xy';
            leftOver = p1.z;
        } else {
            throw new Error('drawn shape not on 2d plane');
        }

        [p1, p2, p3, p4].forEach(point => {
            if (plane === 'xy') {
                ox.push(point.x);
                oy.push(point.y);
            } else if (plane === 'yz') {
                ox.push(point.y);
                oy.push(point.z);
            } else if (plane === 'xz') {
                ox.push(point.x);
                oy.push(point.z);
            }
        });


        try {
            const response = await Axios({
                method: 'GET',
                url: 'https://89.162.9.50:40480/api/v1/generate_path',
                headers: {
                    'Authorization': 'Bearer ' + store.getState().user.currentUser.token,
                },
                params: {
                    ox: JSON.stringify(ox),
                    oy: JSON.stringify(oy),
                    radius: 0,
                    resolution: 0.8,
                    shape: 1,
                },

            })

            this.handleCustomSectionPath(response.data, plane, leftOver)
        } catch (error) {
            console.error(error)
        }
    }

    handleCustomSectionPath = (dataPoints, plane, leftOver) => {
        let points = [];
        for (let i = 0; i < dataPoints.rx.length; i++) {
            if (plane === 'xy') {
                points.push({
                    'x': dataPoints.rx[i],
                    'y': dataPoints.ry[i],
                    'z': leftOver,
                });
            } else if (plane === 'xz') {
                points.push({
                    'x': dataPoints.rx[i],
                    'y': leftOver,
                    'z': dataPoints.ry[i],
                });
            } else if (plane === 'yz') {
                points.push({
                    'x': leftOver,
                    'y': dataPoints.rx[i],
                    'z': dataPoints.ry[i],
                });
            }
        }

        let positions = [];


        points.forEach((pos) => {
            positions.push(parseFloat(pos['x']), parseFloat(pos['y']), parseFloat(pos['z']));
        });

        this.pathPoints = positions.slice();
        store.dispatch(setRobotPath(this.pathPoints));
    }

    saveAndSendCustomSectionToPlanningTool(name, robotID) {
        if (!this.validateCustomPoints2D())
            return SectionToolStatusTypes.SECTION_MUST_BE_2D;
        else {
            // 1.2 Remove this.mesh array from scene and replace it with THREE.Group
            let group = new THREE.Group();
            group.name = name;
            this.mesh.forEach(function (point) {
                point.name = '';
                group.add(point);
            });
            //remove drawing objects from the scene
            for (let i = 0; i < this.mesh.length; i++) {
                this.scene.remove(this.mesh[i]);
                this.scene.remove(this.lines[i]);
            }
            return this.sendCustomSectionToPlanningTool(name, robotID);
        }
    }

    sendCustomSectionToPlanningTool() {
        return SectionToolStatusTypes.SUCCESSFULLY_CREATED_CUSTOM_SECTION;
    }

    getCustomSections() {
        // TODO read custom section from currentSite
        return [];
    }

    removeCustomPoints() {
        this.mesh.forEach(m => this.scene.remove(m));
        this.lines.forEach(l => this.scene.remove(l));
        this.mesh = new Array(4);
        this.lines = new Array(4);
        this.index = 0;
        this.ghostPoint.material.color = this.color;
    }

    validateCustomPointsFormat() {
        const minSize = 0.8;
        let length = false;
        let angle = false;

        // eslint-disable-next-line 
        switch (this.index) {
            case 1: { // Creating 2nd point
                const p1 = this.mesh[0].position;
                const p2 = this.ghostPoint.position;
                const line = new THREE.Line3(p1, p2);
                let deltaVector = new THREE.Vector3();
                line.delta(deltaVector);
                length = Math.sqrt(Math.pow(deltaVector.x, 2) + Math.pow(deltaVector.y, 2) + Math.pow(deltaVector.z, 2)) >= minSize;

                const xAxis = new THREE.Vector3(1, 0, 0);
                const yAxis = new THREE.Vector3(0, 1, 0);
                const zAxis = new THREE.Vector3(0, 0, 1);
                deltaVector.normalize();
                deltaVector.x = Math.abs(deltaVector.x);
                deltaVector.y = Math.abs(deltaVector.y);
                deltaVector.z = Math.abs(deltaVector.z);
                angle = deltaVector.equals(xAxis) || deltaVector.equals(yAxis) || deltaVector.equals(zAxis);

                break;
            } case 2: { // Creating 3rd point
                const p1 = this.mesh[0].position;
                const p2 = this.mesh[1].position;
                const p3 = this.ghostPoint.position;

                const line_p1p2 = new THREE.Line3(p1, p2);
                let deltaVector_p1p2 = new THREE.Vector3();
                line_p1p2.delta(deltaVector_p1p2);

                const line_p2p3 = new THREE.Line3(p2, p3);
                let deltaVector_p2p3 = new THREE.Vector3();
                line_p2p3.delta(deltaVector_p2p3);
                const length_p2p3 = Math.sqrt(Math.pow(deltaVector_p2p3.x, 2) + Math.pow(deltaVector_p2p3.y, 2) + Math.pow(deltaVector_p2p3.z, 2));

                angle = deltaVector_p1p2.dot(deltaVector_p2p3) === 0;
                length = length_p2p3 >= minSize;

                break;
            } case 3: { // Creating 4th point
                const p1 = this.mesh[0].position;
                const p2 = this.mesh[1].position;
                const p3 = this.mesh[2].position;
                const p4 = this.ghostPoint.position;

                const line_p1p2 = new THREE.Line3(p1, p2);
                let deltaVector_p1p2 = new THREE.Vector3();
                line_p1p2.delta(deltaVector_p1p2);

                const line_p2p3 = new THREE.Line3(p2, p3);
                let deltaVector_p2p3 = new THREE.Vector3();
                line_p2p3.delta(deltaVector_p2p3);

                const line_p3p4 = new THREE.Line3(p3, p4);
                let deltaVector_p3p4 = new THREE.Vector3();
                line_p3p4.delta(deltaVector_p3p4);

                const line_p1p4 = new THREE.Line3(p1, p4);
                let deltaVector_p1p4 = new THREE.Vector3();
                line_p1p4.delta(deltaVector_p1p4);

                angle = deltaVector_p2p3.dot(deltaVector_p3p4) === 0 && deltaVector_p1p2.dot(deltaVector_p1p4) === 0;
                length = true;

                break;
            }
        }

        this.ghostPoint.material.color = angle && length ? this.color : this.invalidColor;
        this.ghostPoint.material.opacity = angle && length ? SectionToolSettings.GHOST_OPACITY : 1.0;
        return angle && length;
    }

    validateCustomPoints2D() {
        let xCounter = 1, yCounter = 1, zCounter = 1;
        for (let i = 1; i < this.mesh.length; i++) {
            if (this.mesh[i - 1].position.x === this.mesh[i].position.x) xCounter++;
            if (this.mesh[i - 1].position.y === this.mesh[i].position.y) yCounter++;
            if (this.mesh[i - 1].position.z === this.mesh[i].position.z) zCounter++;
        }
        return xCounter === this.mesh.length ||
            yCounter === this.mesh.length ||
            zCounter === this.mesh.length;
    }

    resetSectionTool(scene) {
        let objectsToRemove = [];
        scene.traverse(function (object) {
            if (object.name === 'point' || object.name === 'ghostPoint') objectsToRemove.push(object);
        });
        objectsToRemove.forEach(function (object) {
            scene.remove(object);
        });
        this.mesh = new Array(4);
        this.index = 0;
        this.removeCustomPoints();
    }
}

export default SectionTool;
