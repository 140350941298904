import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
    toggleShowRobot,
    toggleShowTrail,
    toggleShowPath,
    toggleShowSection,
    updateRobotPathLength, updateRobotTrailLength
} from '../../../redux/robot/robotActions';
import { selectCurrentRobot } from '../../../redux/robot/robotSelector';

import { styles } from './SettingsDrawerStyles';
import { selectIsLive } from '../../../redux/mode/modeSelector';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

class RobotControl extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pathLength: this.props.currentRobot.pathLength,
            trailLength: this.props.currentRobot.trailLength,
        };
    }

    handlePathLengthChange = (value) => {
        if (value > 100) {
            value = 100;
        } else if (value < 0) {
            value = 0;
        }
        this.setState({
            pathLength: value,
        }, () => {
            this.props.updateRobotPathLength({
                pathLength: this.state.pathLength,
                robot: this.props.currentRobot,
                isLiveMode: this.props.isLiveMode,
            });
        });
    }

    handleTrailLengthChange = (value) => {
        if (value > 100) {
            value = 100;
        } else if (value < 0) {
            value = 0;
        }

        this.setState({
            trailLength: value,
        }, () => {
            this.props.updateRobotTrailLength({
                trailLength: this.state.trailLength,
                robot: this.props.currentRobot,
                isLiveMode: this.props.isLiveMode,
            });
        });
    }

    render() {

        const { classes } = this.props;

        return (
            <div className={this.props.classes.robotControlRoot}>
                <FormGroup row={true}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.currentRobot.showRobot}
                                name="showRobot"
                                onChange={() => this.props.toggleShowRobot({
                                    isLiveMode: this.props.isLiveMode,
                                    robot: this.props.currentRobot,
                                })
                                }
                            />
                        }
                        label={<FormattedMessage id="SETTINGSDRAWER.ROBOT_CONTROL_LABEL_SHOW_ROBOT" />}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.currentRobot.showTrail}
                                name="showTrail"
                                onChange={() => this.props.toggleShowTrail({
                                    isLiveMode: this.props.isLiveMode,
                                    robot: this.props.currentRobot,
                                })
                                }
                            />
                        }
                        label={<FormattedMessage id="SETTINGSDRAWER.ROBOT_CONTROL_LABEL_SHOW_TRAIL" />}
                    />
                </FormGroup>
                <FormGroup row={true}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.currentRobot.showPath}
                                name="showPath"
                                onChange={() => this.props.toggleShowPath({
                                    isLiveMode: this.props.isLiveMode,
                                    robot: this.props.currentRobot,
                                })}
                            />
                        }
                        label={<FormattedMessage id="SETTINGSDRAWER.ROBOT_CONTROL_LABEL_SHOW_PATH" />}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.currentRobot.showSection}
                                name="showSection"
                                onChange={() => this.props.toggleShowSection({
                                    isLiveMode: this.props.isLiveMode,
                                    robot: this.props.currentRobot,
                                })}
                                disabled={this.props.currentRobot.section === null}
                            />
                        }
                        label={<FormattedMessage id="SETTINGSDRAWER.ROBOT_CONTROL_LABEL_SHOW_SECTION" />}
                    />
                </FormGroup>
                <div className={classes.lengthControlContainer}>
                    <Slider
                        value={this.state.pathLength}
                        aria-labelledby="discrete-slider"
                        onChange={(event, value) => this.handlePathLengthChange(value)}
                        step={10}
                        min={0}
                        max={100}
                        className={classes.lengthControlSlider}
                    />
                    <Typography className={classes.lengthControlLabel}>
                        {<FormattedMessage
                            id='ROBOTCONTROL.PERCENTAGE_PATH_LENGTH'
                            values={{
                                pathLength: this.state.pathLength,
                            }}
                        />}
                    </Typography>

                </div>
                <div className={classes.lengthControlContainer}>
                    <Slider
                        value={this.state.trailLength}
                        aria-labelledby="discrete-slider"
                        onChange={(event, value) => this.handleTrailLengthChange(value)}
                        step={10}
                        min={0}
                        max={100}
                        className={classes.lengthControlSlider}
                    />
                    <Typography
                        className={classes.lengthControlLabel}
                    >
                        {<FormattedMessage
                            id='ROBOTCONTROL.PERCENTAGE_TRAIL_LENGTH'
                            values={{
                                trailLength: this.state.trailLength,
                            }}
                        />}
                    </Typography>

                </div>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    currentRobot: selectCurrentRobot,
    isLiveMode: selectIsLive,
});

const mapDispatchToProps = dispatch => ({
    toggleShowRobot: (robot) => dispatch(toggleShowRobot(robot)),
    toggleShowTrail: (robot) => dispatch(toggleShowTrail(robot)),
    toggleShowPath: (robot) => dispatch(toggleShowPath(robot)),
    toggleShowSection: (robot) => dispatch(toggleShowSection(robot)),
    updateRobotPathLength: (update) => dispatch(updateRobotPathLength(update)),
    updateRobotTrailLength: (update) => dispatch(updateRobotTrailLength(update)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RobotControl));
