const styles = {
    sceneExpansionContainer: {
        margin: 10,
        width: '400px'
    },
    justAround: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    buttonBar: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '2px',
        paddingBottom: '2px',
        display: 'flex',
        position: 'relative',
        borderTopWidth: '5px'
    },
    alterGridPanel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    gridRange: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    gridSizeSlider: {
        width: '70%'
    },
    gridInputContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    gridInput: {
        paddingLeft: '20px',
        width: '60px'
    },

};

export {
    styles,
};
