const SectionToolStatusTypes = {
    NO_ALERT: 'NO_ALERT',
    SECTION_MUST_BE_2D: 'SECTION_MUST_BE_2D',
    SECTION_NAME_ALLREADY_IN_USE: 'SECTION_NAME_ALLREADY_IN_USE',
    LOCAL_STORAGE_NOT_SUPPORTED: 'LOCAL_STORAGE_NOT_SUPPORTED',
    SUCCESSFULLY_CREATED_CUSTOM_SECTION: 'SUCCESSFULLY_CREATED_CUSTOM_SECTION',
    SUCCESSFULLY_SENT_PREDEFINED_SECTION: 'SUCCESSFULLY_SENT_CUSTOM_SECTION',
    POLYGON_SENDABLE: 'POLYGON_SENDABLE',
    SECTION_MUST_BE_RECTANGULAR_AND_MIN_80CM: 'SECTION_MUST_BE_RECTANGULAR_AND_MIN_80CM',
};

export default SectionToolStatusTypes;