import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IntlProvider } from 'react-intl';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { auth, createUserProfileDocument } from './firebase/Firebase';

import { setCurrentUser } from './redux/user/userActions';
import { setCurrentRobot, setRobots, toggleSettingsDrawer, unselectAllRobots } from './redux/robot/robotActions';
import { selectCurrentUser } from './redux/user/userSelector';
import { selectSettingsDrawer } from './redux/robot/robotSelector';

import TopMenu from './assets/components/TopMenu';
import SnackMessage from './assets/components/SnackMessage';
import LandingPage from './assets/components/LandingPage';
import OverviewContainer from './assets/components/OverviewContainer';
import SettingsDrawer from './assets/components/SettingsDrawer/SettingsDrawer';

import { darkTheme, dayTheme } from './AppStyles';
import { routes, userRoles } from './config';

import i18nDataNbNo from './langauge/nb-no';
import i18nDataEnGb from './langauge/en-gb';

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            drawer: false,
            dayTheme: localStorage.getItem('dayTheme') === 'true',
            i18nData: App.getLanguage(),
            langCode: 'en-gb',
            snackbarMessage: '',
            snackbarOpen: false,
            message: '',
            loadingUser: true,
        };

        this._baseURI = 'http://';
    }

    componentDidMount() {
        const { setCurrentUser, setRobots, setCurrentRobot } = this.props;

        this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                const idToken = await userAuth.getIdToken();
                const userRef = await createUserProfileDocument(userAuth);

                userRef.onSnapshot(snapshot => {
                    setCurrentUser({
                        id: snapshot.id,
                        token: idToken,
                        ...snapshot.data(),
                    });
                });

                this.setState({
                    loadingUser: false,
                });
            } else {
                this.setState({
                    loadingUser: false,
                });
            }
            setCurrentUser(userAuth);
            setCurrentRobot(null);
            setRobots([]);
        });

        //selected robots are cleared to avoid trouble should mode be switched
        this.props.unselectAllRobots();

        if (this.props.settingsDrawerHidden) {
            this.props.toggleSettingsDrawer();
        }
    }

    componentWillUnmount() {
        this.unsubscribeFromAuth();
    }

    static getLanguage() {
        let i18nData = null;

        try {
            switch (window.navigator.language.toLowerCase()) {
                case 'nb-no':
                    i18nData = i18nDataNbNo;
                    break;
                case 'en-gb':
                    i18nData = i18nDataEnGb;
                    break;
                default:
                    i18nData = i18nDataEnGb;
                    break;
            }
        } catch (ignore) {
            if (!i18nData) {
                i18nData = i18nDataEnGb;
            }
        }

        return i18nData;
    }

    handleRedirectAfterLogin = () => {
        let route = '';
        if (this.props.currentUser === null) {
            this.props.history.push(routes.HOME);
        } else {
            switch (this.props.currentUser.role) {
                case userRoles.ADMIN:
                    route = routes.OVERVIEW;
                    break;
                case userRoles.SERVICE:
                    route = routes.OVERVIEW;
                    break;
                case userRoles.OPERATOR:
                    route = routes.OVERVIEW;
                    break;
                default:
                    route = routes.OVERVIEW;
                    break;
            }
            this.props.history.push(route);
        }
    };

    handleLanguageChange = (langCode) => {
        let i18nData;
        switch (langCode) {
            case 'nb-no':
                i18nData = i18nDataNbNo;
                break;
            case 'en-gb':
                i18nData = i18nDataEnGb;
                break;
            default:
                i18nData = i18nDataEnGb;
                break;
        }
        this.setState({
            i18nData: i18nData,
            langCode: langCode,
        });
    };

    handleThemeChange = () => {
        this.setState({
            dayTheme: !this.state.dayTheme,
        }, () => {
            localStorage.setItem('dayTheme', this.state.dayTheme);
        });
    };

    handleLogout = async () => {
        await auth.signOut();

        this.setState({ drawer: false }, () => {
            sessionStorage.setItem('route', routes.HOME);
            this.props.history.push(routes.HOME);
        });
    };

    renderTopMenu() {
        if (this.props.currentUser) {
            return (
                <TopMenu
                    onLanguageChange={this.handleLanguageChange}
                    onToggleTheme={this.handleThemeChange}
                    onLogout={this.handleLogout}
                    dayTheme={this.state.dayTheme}
                />
            );
        }
        return null;
    }

    renderSettingsDrawer() {
        if (this.props.currentUser) {
            const { settingsDrawerHidden, toggleSettingsDrawer } = this.props;
            return (
                <SettingsDrawer
                    open={settingsDrawerHidden}
                    toggleDrawer={toggleSettingsDrawer}
                />
            );
        }
        return null;
    }

    renderOverviewContainer = (routeProps) => {
        if (this.props.currentUser) {
            return (
                <OverviewContainer
                    {...routeProps}
                />
            );
        }
    };

    render() {
        return (
            <IntlProvider {...this.state.i18nData}>
                <MuiThemeProvider theme={this.state.dayTheme ? dayTheme : darkTheme}>
                    <CssBaseline />
                    {this.renderTopMenu()}
                    {this.renderSettingsDrawer()}
                    <Switch>
                        <Route
                            exact={true}
                            path={routes.HOME}
                            render={() =>
                                <LandingPage
                                    loadingUser={this.state.loadingUser}
                                />
                            }
                        />
                        <Route
                            path={'/:clientId' + routes.OVERVIEW}
                            render={routeProps => this.renderOverviewContainer(routeProps)}
                        />
                    </Switch>
                    <SnackMessage />
                </MuiThemeProvider>
            </IntlProvider>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user)),
    setCurrentRobot: robot => dispatch(setCurrentRobot(robot)),
    setRobots: robots => dispatch(setRobots(robots)),
    toggleSettingsDrawer: () => dispatch(toggleSettingsDrawer()),
    unselectAllRobots: () => dispatch(unselectAllRobots()),
});

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    settingsDrawerHidden: selectSettingsDrawer,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
