module.exports = {
    locale: 'en-GB',
    messages: {
        'LOGIN.REDIRECT': 'Continue to app',
        'LOGIN.LOGIN': 'Log in',
        'LOGIN.CLIENT_ID': 'Client-ID',
        'LOGIN.PASSWORD': 'Password',
        'LOGIN.LOGIN_FAILED': 'Incorrect Client ID or password',
        'LOGIN.LOADING_USER': 'Waiting for user...',
    },
};
