import { messagesNbNo as ControlMenuContainer } from '../assets/components/ControlMenuContainer/language';
import { messagesNbNo as InformationMenuContainer } from '../assets/components/InformationMenuContainer/language';
import { messagesNbNo as LoginForm } from '../assets/components/LoginForm/language';
import { messagesNbNo as OverviewContainer } from '../assets/components/OverviewContainer/language';
import { messagesNbNo as SettingsDrawer } from '../assets/components/SettingsDrawer/language';
import { messagesNbNo as ThreeScene } from '../assets/components/ThreeScene/language';
import { messagesNbNo as TopMenu } from '../assets/components/TopMenu/language';

const sideMenu = {
    'MENU.HOME': 'Hjem',
    'MENU.NAVIGATION': 'Vær',
    'MENU.OPERATION': 'Operasjon',
    'MENU.OPERATION_SETTINGS': 'Innstillinger',
    'MENU.OPERATION_DRIFT': 'Drift',
    'MENU.SERVICE': 'Service',
    'MENU.SERVICE_MAIN': 'Forbindelse',
    'MENU.SERVICE_PARAMETERS': 'Planlegging',
    'MENU.SERVICE_CONTROLLER': 'Regulatorjustering',
    'MENU.DARK_MODE': 'Nattmodus',
    'MENU.LIGHT_MODE': 'Dagmodus',
    'MENU.LOG_OUT': 'Logg ut',
    'MENU.ADMIN_DASHBOARD': 'Dashbord',
    'MENU.CONTACT': 'Kontakt oss',
};

const globalMessages = {
    'ERROR.UNAUTHORIZED': 'Ingen tilgang, oppdater siden eller logg inn igjen',
};

export default {
    locale: 'nb-NO',
    messages: Object.assign(
        {},
        ControlMenuContainer,
        InformationMenuContainer,
        LoginForm,
        OverviewContainer,
        SettingsDrawer,
        ThreeScene,
        TopMenu,

        globalMessages,
        sideMenu,
    ),
};