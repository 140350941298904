import backgroundImage from '../../static/images/landingpage_background.jpg';

const styles = {
    root: {
        width: '100%',
        height: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    header: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        height: 64,
        width: '100%',
        boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
        backgroundColor: '#11111125',
        alignItems: 'center',
    },
    headerImage: {
        height: 50,
        marginLeft: 15,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        height: '100%',
    },
    caption: {
        fontSize: '2em',
        display: 'flex',
        flexDirection: 'column',
        textShadow: '0 1px 15px black',
    },
};

export {
    styles,
};
