import { sectionActionTypes } from './sectionTypes';

const INITIAL_STATE = {
    isDrawing: false,
    isClearing: false,
    customSectionName: '',
    canSend: false,
    isSending: false,
}

const sectionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case sectionActionTypes.TOGGLE_IS_DRAWING:
            return {
                ...state,
                isDrawing: !state.isDrawing,
            };
        case sectionActionTypes.TOGGLE_IS_CLEARING:
            return {
                ...state,
                isClearing: !state.isClearing,
            }
        case sectionActionTypes.SET_CUSTOM_SECTION_NAME:
            return {
                ...state,
                customSectionName: action.payload,
            }
        case sectionActionTypes.TOGGLE_SECTION_CAN_SEND:
            return {
                ...state,
                canSend: !state.canSend,
            }
        case sectionActionTypes.RESET_CUSTOM_SECTION_NAME:
            return {
                ...state,
                customSectionName: '',
            }
        case sectionActionTypes.TOGGLE_SEND_CUSTOM_SECTION:
            if (action.payload === null) {
                return {
                    ...state,
                    isSending: !state.isSending,
                };
            } else {
                return {
                    ...state,
                    isSending: !state.isSending,
                    customSectionName: action.payload,
                };
            }
        default:
            return state;
    }
}

export default sectionReducer;