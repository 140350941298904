module.exports = {
    locale: 'en-GB',
    messages: {
        'SETTINGSDRAWER.TITLE': '{robot} Settings',
        'SETTINGSDRAWER.ROBOT_CONTROL_LABEL_SHOW_ROBOT': 'Show robot',
        'SETTINGSDRAWER.ROBOT_CONTROL_LABEL_SHOW_TRAIL': 'Show trail',
        'SETTINGSDRAWER.ROBOT_CONTROL_LABEL_SHOW_PATH': 'Show path',
        'SETTINGSDRAWER.ROBOT_CONTROL_LABEL_SHOW_SECTION': 'Show section',
        'SETTINGSDRAWER.SECTION_LABEL': 'Section:',
        'SETTINGSDRAWER.BTN_PREDEFINED': 'Send section',
        'CUSTOMSECTIONTOOL.SECTION_NAME': 'Section name',
        'CUSTOMSECTIONTOOL.QUIT_DRAWING': 'Quit drawing',
        'CUSTOMSECTIONTOOL.DRAW': 'Draw',
        'CUSTOMSECTIONTOOL.CLEAR_SECTION': 'Clear',
        'CUSTOMSECTIONTOOL.SEND_CUSTOM_SECTION': 'Create',
        'CUSTOMSECTIONTOOL.CREATE_CUSTOM_SECTION': 'Create custom section',
        'CUSTOMSECTIONTOOL.SECTION_NAME_HELPER_TEXT': 'Minimum 3 character',

        'SETTINGSDRAWER.STEP_BUTTON_NEXT_LABEL': 'Next >',
        'SETTINGSDRAWER.STEP_BUTTON_PREV_LABEL': '< Previous',
        'SETTINGSDRAWER.STEP_ONE_TEXT': 'Type a name...',
        'SETTINGSDRAWER.STEP_TWO_TEXT': 'Draw 4 points by clicking on one of the walls or floor.',
        'SETTINGSDRAWER.STEP_THREE_TEXT': 'Generate section and save it.',

        'SETTINGSDRAWER.SECTION_SET_SUCCESSFULLY': 'Robot "{robotName}" has now been given section "{sectionName}" ',
        'SETTINGSDRAWER.ROBOT_ALLREADY_HAS_SECTION': 'Robot "{robotName}" allready has section "{sectionName}" ',
        'SETTINGSDRAWER.SECTION_IS_TAKEN': 'Section "{sectionName}" is allready assigned to robot "{robotName}',

        'ROBOTCONTROL.PERCENTAGE_PATH_LENGTH': '{pathLength}% path length',
        'ROBOTCONTROL.PERCENTAGE_TRAIL_LENGTH': '{trailLength}% trail length',

        'SETTINGSDRAWER.ROV_MODE': 'Rov mode',
        'SETTINGSDRAWER.ROBOT_MODE': 'Robot mode',

        'SETTINGSDRAWER.JOYSICK_ACTIVE': 'Joystick is now connected to {robot}',
        'SETTINGSDRAWER.JOYSICK_NOT_ACTIVE': 'Disconnected joystick from {robot}',
        'SETTINGSDRAWER.JOYSICK_CONNECT_ERROR': 'Something went wrong, Could not connect to {robot}',
    },
};
