const styles = theme => ({
    root: {
        margin: 5,
    },
    robotCard: {
        height: 400,
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
    },
    checkboxContainer: {
        marginRight: '10px',
        marginLeft: '20px'
    },
    widget: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.primary.main,
        margin: '10px',
        borderRadius: '25px',
        padding: '5px 10px',
        opacity: '0.30',
        width: 'max-content',
        '&:hover': {
            opacity: 1.0,
        },
        color: theme.palette.primary.contrastText,
    },
    velocityWidget: {
        flexDirection: 'column',
    },
    velocityWidgetSingle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        zIndex: 3,
        width: '100%',
        justifyContent: 'space-between',
    },
    cardTitle: {
        color: '#fff',
        textShadow: '0 1px #fff',
        paddingLeft: '10px',
        opacity: '0.9',
    },
    headerButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerButton: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: 'white',
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    noStreamingRobotCardHeader: {
        backgroundColor: '#696969',
    },
    expansionPanels: {
        padding: '10px 10px 10px 10px',
        margin: '0px 0px 0px 0px',
    },
    noVideo: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    robotStream: {
        position: 'absolute',
        zIndex: 1,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    },
    instruments: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        right: 0,
        bottom: 0,
        zIndex: 2,
    },
    robotInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2,
    },
    robotCardFooter: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        zIndex: 2,
    },
    armedText: {
        color: theme.customPalette.red,
        fontWeight: 600,
        fontSize: '1.1em',
    },
    disarmedText: {
        color: theme.customPalette.green,
        fontWeight: 600,
        fontSize: '1.1em',
    },
    rovMode: {
        border: `3px solid ${theme.customPalette.red}`,
    },
    isInControl: {
        border: `3px dashed ${theme.customPalette.darkOrange}`,
    },
});

export {
    styles,
};
