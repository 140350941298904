import { createSelector } from 'reselect';

const selectFeedback = state => state.feedback;

export const selectFeedbackMessage = createSelector(
    [selectFeedback],
    feedback => feedback.message,
);

export const selectFeedbackHidden = createSelector(
    [selectFeedback],
    feedback => feedback.hidden,
);
