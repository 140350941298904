const statusCode = {
    OK: 200,
    CREATED: 201,
    UNAUTHORIZED: 401,
};

const userRoles = {
    ADMIN: 100,
    SERVICE: 200,
    OPERATOR: 300,
};

const routes = {
    HOME: '/',
    OVERVIEW: '/overview',
};

const joystickButtons = {
    SHIFT: 0,
    MAN_MODE: 1,
    ALT_HOLD_MODE: 2,
    STABILIZE_MODE: 3,
    CAMERA_TILT_UP: 4,
    CAMERA_TILT_DOWN: 5,
    DISARM: 8,
    ARM: 9,
    GAIN_DOWN: 10,
    GAIN_UP: 11,
    LIGHTS_UP: 12,
    LIGHTS_DOWN: 13,
    //GAIN_UP_PITCH_TRIM_UP: 12,
    //GAIN_DOWN_PITCH_TRIM_DOWN: 13,
    //LIGHTS_UP_ROLL_TRIM_RIGHT: 14,
    //LIGHTS_DOWN_TOLL_TRIM_LEFT: 15,
};

const operationMode = {
    ALT_HOLD_MODE: 2,
    STABILIZE_MODE: 0,
    POS_HOLD_MODE: 16,
    MANUAL_MODE: 19,
    WAYPOINT_MODE: 666,
};

export const NO_OF_DEFAULT_SECTIONS = 10;

export {
    statusCode,
    userRoles,
    routes,
    joystickButtons,
    operationMode,
};