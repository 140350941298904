module.exports = {
    locale: 'en-GB',
    messages: {
        'ROBOTCARD.ROV_RUN_COUNT': 'Rov run count',
        'ROBOTCARD.DEPTH_TO_SURFACE': 'Depth to surface',
        'ROBOTCARD.ROV_TEMPERATURE': 'Rov temperature',
        'ROBOTCARD.WEATHER_TEMPERATURE': 'Water temperature',
        'ROBOTCARD.DRONE_SPEED': 'Drone speed',
        'ROBOTCARD.OPERATION_TIME': 'Operation time',
        'ROBOTCARD.SETTINGS_DRAWER': 'Open settings drawer',
        'ROBOTCARD.NO_VIDEO_CONNECTION': 'No video connection',
        'ROBOTCARD.ARMED': 'Armed',
        'ROBOTCARD.DISARMED': 'Disarmed',
        'OVERVIEW_CONTAINER.SIMULATION_MODE_BANNER': 'Simulation mode',
        'ROBOTCARD.TETHER_TURN': 'Tether turn',
        'ROBOTCARD.TETHER_TURN_CLOCKWISE': '{turns} clockwise',
        'ROBOTCARD.JOY_GAIN': 'Joystick gain',

        'ROBOTCARD.RUN_COUNTER': 'd[days] h[h] m[m] s[s]',
    },
};