import siteTypes from './siteTypes';

const INITIAL_STATE = {
    currentSite: null,
};

const siteReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case siteTypes.SET_CURRENT_SITE:
            return {
                ...state,
                currentSite: action.payload,
            };
        default:
            return state;
    }
};

export default siteReducer;
