module.exports = {
	locale: 'nb-NO',
	messages: {
		'THREESCENE.RESET_CAMERA_LABEL': 'Nullstill kamera',
		'THREESCENE.SHOW_WATER_LABEL': 'Vis vannet',
		'THREESCENE.HIDE_WATER_LABEL': 'Skjul vannet',
		'THREESCENE.SHOW_GRID_LABEL': 'Vis grid',
		'THREESCENE.HIDE_GRID_LABEL': 'Skjul grid',
		'THREESCENE.SHOW_WAVES_LABEL': 'Vis bølger',
		'THREESCENE.INFORMATION_MENU_LABEL': 'Informasjon',
		'THREESCENE.WATER_LEVELS_LABEL': 'Vannivå',
		'THREESCENE.CURRENT_LABEL': 'Nå:',
		'THREESCENE.AVERAGE_LABEL': 'Gjennomsnitt:',
		'THREESCENE.HIGH_TIDE_LABEL': 'Flo:',
		'THREESCENE.OCCURS_AT_LABEL': 'Hender ved:',
		'THREESCENE.LOW_TIDE_LABEL': 'Fjære:',
		'THREESCENE.SWITCH_TO_PLAYMODE': 'Bytt til avspillingsmodus',
		'THREESCENE.SWITCH_TO_LIVEMODE': 'Bytt til livemodus',
		'THREESCENE.CONTROL_MENU': 'Kontrollmeny',
		'THREESCENE.ALTER_GRID_SIZE': 'Endre grid størrelse:',
		'THREESCENE.FLOOR': 'Gulv',
		'THREESCENE.INLET': 'Inntak',
		'THREESCENE.OUTLET': 'Uttak',
		'THREESCENE.LEFT_WALL': 'Venstre vegg',
		'THREESCENE.RIGHT_WALL': 'Høyre vegg',
		'THREESCENE.SECTION_MUST_BE_2D': 'Seksjonen må være en 2D form, altså på en av bassengets sider',
		'THREESCENE.ALLREADY_FOUR_POINTS': 'Du har allerede tegnet 4 punkt, trykk send eller fjern seksjon for å begynne igjen',
		'THREESCENE.SECTION_NAME_ALLREADY_IN_USE': 'Det eksisterer alt en seksjon med dette navnet',
		'THREESCENE.LOCAL_STORAGE_NOT_SUPPORTED': 'Kunne ikke lagre seksjon da det ikke er støtte for local storage.\n' +
			'https://caniuse.com/#feat=mdn-api_window_localstorage\n' +
			'https://www.whatismybrowser.com/detect/?utm_source=whatismybrowsercom&utm_medium=internal&utm_campaign=breadcrumbs',
		'THREESCENE.SUCCESSFULLY_CREATED_CUSTOM_SECTION': 'Suksessfult lagd seksjon "{sectionName}" og lagt den til listen av forhåndsdefinerte seksjoner',
		'THREESCENE.SUCCESSFULLY_SENT_PREDEFINED_SECTION': 'Suksessfult sendt seksjon "{sectionName}" til robot "{robotId}"',
	}
};
