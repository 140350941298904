import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import { toggleFeedbackHidden } from '../../../redux/feedback/feedbackActions';
import { selectFeedbackHidden, selectFeedbackMessage } from '../../../redux/feedback/feedbackSelector';

import { styles } from './SnackMessageStyles';

class SnackMessage extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    getTransition(props) {
        return <Slide direction="up" {...props} />;
    }

    render() {
        const { classes, hidden, message, toggleFeedbackHidden } = this.props;

        return (
            <Snackbar
                TransitionComponent={this.getTransition}
                className={classes.root}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={!hidden}
                autoHideDuration={6000}
                onClose={toggleFeedbackHidden}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{message}</span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={toggleFeedbackHidden}
                    >
                        <CloseIcon/>
                    </IconButton>,
                ]}
            />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    toggleFeedbackHidden: () => dispatch(toggleFeedbackHidden()),
});

const mapStateToProps = createStructuredSelector({
    message: selectFeedbackMessage,
    hidden: selectFeedbackHidden,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SnackMessage));
