module.exports = {
    locale: 'nb-NO',
    messages: {
        'LOGIN.REDIRECT': 'Fortsett til appen',
        'LOGIN.LOGIN': 'Logg inn',
        'LOGIN.CLIENT_ID': 'Klient-ID',
        'LOGIN.PASSWORD': 'Passord',
        'LOGIN.LOGIN_FAILED': 'Feil bruker ID eller passord',
        'LOGIN.LOADING_USER': 'Venter på bruker...',
    },
};
