import * as THREE from 'three';
import { MeshPhysicalMaterial } from 'three';
import Environment from './Environment';
import { PoolSettings, WaterSettings } from './OperationalValues';


class Pool extends Environment {
    constructor(assets = null, generateDetails = true) {
        super(assets);
        this.mesh = null;
        this.poolIntersectRegions = null;
        this.generateDetails = generateDetails
    }

    generate() {
        this.mesh = [];
        this.poolIntersectRegions = new THREE.Group();
        this.poolIntersectRegions.name = 'poolIntersectRegions';
        this.generatePool();
    }

    generatePool() {
        let r = PoolSettings.RADIUS,
            w = PoolSettings.WIDTH,
            l = PoolSettings.LENGTH,
            lTot = PoolSettings.LENGTH_TOTAL,
            h = PoolSettings.HEIGHT,
            smoothness = PoolSettings.SMOOTHNESS;
        let materialParams = {
            transparent: PoolSettings.TRANSPARENT,
            opacity: PoolSettings.OPACITY,
            roughness: 1,
            metalness: 0,
            reflectivity: 0.5,
            clearcoat: 0,
            fog: true,
            wireframe: PoolSettings.WIREFRAME
        };
        let poolDark = new MeshPhysicalMaterial(materialParams);
        poolDark.color.set(PoolSettings.COLOR_DARK);
        poolDark.side = PoolSettings.SIDE;
        let poolLight = new MeshPhysicalMaterial(materialParams);
        poolLight.color.set(PoolSettings.COLOR_LIGHT);
        poolLight.side = PoolSettings.SIDE;

        // Round corners
        let cornerGeo = new THREE.SphereGeometry(r, smoothness, smoothness, 0, Math.PI / 2,
            Math.PI / 2, Math.PI / 2);
        let corner = new THREE.Mesh(cornerGeo, poolLight);
        let cornerProps = [
            { x: w - r, z: -r },
            { x: w - r, z: -lTot + r },
            { x: r, z: -lTot + r },
            { x: r, z: -r }
        ];
        for (let prop in cornerProps) {
            corner.position.set(cornerProps[prop].x, r, cornerProps[prop].z);
            corner.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI / 2);
            this.poolIntersectRegions.add(corner.clone());
        }

        // Curvatures
        let curvatureGeo = new THREE.CylinderGeometry(r, r, 1, smoothness, 1,
            true, -Math.PI / 2, Math.PI / 2);

        let curvature = new THREE.Mesh(curvatureGeo, poolLight);
        let curvatureProps = [
            // Wall-curvatures
            {
                x: w - r,
                y: h / 2 + r / 2,
                z: -r,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                height: h - r
            },
            {
                x: w - r,
                y: h / 2 + r / 2,
                z: -lTot + r,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                height: h - r
            },
            {
                x: r,
                y: h / 2 + r / 2,
                z: -lTot + r,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                height: h - r
            },
            {
                x: r,
                y: h / 2 + r / 2,
                z: -r,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                height: h - r
            },
            // Floor-curvatures width
            {
                x: w / 2,
                y: r,
                z: -r,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 0, 1),
                height: w - r * 2
            },
            {
                x: w / 2,
                y: r,
                z: -lTot + r,
                rot: -Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                height: w - r * 2
            },
            // Floor-curvatures length
            {
                x: r,
                y: r,
                z: -lTot / 2,
                rot: -Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 0, 1),
                height: lTot - r * 2
            },
            {
                x: w - r,
                y: r,
                z: -lTot / 2,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                height: lTot - r * 2
            }
        ];
        for (let prop in curvatureProps) {
            curvature.scale.y = curvatureProps[prop].height;
            curvature.position.set(curvatureProps[prop].x, curvatureProps[prop].y, curvatureProps[prop].z);
            curvature.rotateOnAxis(curvatureProps[prop].rotAxis, curvatureProps[prop].rot);
            this.poolIntersectRegions.add(curvature.clone());
        }

        // Faces (walls and floor)
        let faceGeo = new THREE.PlaneGeometry(1, 1, 1, 1);
        let face = new THREE.Mesh(faceGeo, poolLight);
        let faceProps = [
            // Width
            {
                x: w / 2,
                y: h / 2 + r / 2,
                z: 0,
                rot: 0,
                rotAxis: new THREE.Vector3(0, 1, 0),
                width: w - r * 2,
                height: h - r
            },
            {
                x: w / 2,
                y: h / 2 + r / 2,
                z: -lTot,
                rot: Math.PI,
                rotAxis: new THREE.Vector3(0, 1, 0),
                width: w - r * 2,
                height: h - r
            },
            // Length
            {
                x: w,
                y: h / 2 + r / 2,
                z: -lTot / 2,
                rot: -Math.PI / 2,
                rotAxis: new THREE.Vector3(0, 1, 0),
                width: lTot - r * 2,
                height: h - r
            },
            {
                x: 0,
                y: h / 2 + r / 2,
                z: -lTot / 2,
                rot: Math.PI,
                rotAxis: new THREE.Vector3(0, 1, 0),
                width: lTot - r * 2,
                height: h - r
            },
            {
                x: w / 2,
                y: 0,
                z: -lTot / 2,
                rot: Math.PI / 2,
                rotAxis: new THREE.Vector3(1, 0, 0),
                width: lTot - r * 2,
                height: w - r * 2
            }
        ];
        for (let prop in faceProps) {
            face.scale.x = faceProps[prop].width;
            face.scale.y = faceProps[prop].height;
            face.position.set(faceProps[prop].x, faceProps[prop].y, faceProps[prop].z);
            face.rotateOnAxis(faceProps[prop].rotAxis, faceProps[prop].rot);
            this.poolIntersectRegions.add(face.clone());
        }

        // Differential pressure wall
        let dPWallShape = new THREE.Shape();
        (function roundedRect(shape, startX, startY, width, height, radius) {
            shape.moveTo(startX, startY + height);
            shape.lineTo(startX, startY + radius);
            shape.absarc(startX + radius, startY + radius, radius,
                Math.PI, Math.PI + Math.PI / 2, false);
            shape.lineTo(startX + width - radius, startY);
            shape.absarc(startX + width - radius, startY + radius, radius,
                Math.PI + Math.PI / 2, 2 * Math.PI, false);
            shape.lineTo(startX + width, startY + height);
        })(dPWallShape, 0, 0, w, h, r);
        let dPWallGeo = new THREE.ShapeBufferGeometry(dPWallShape);
        dPWallGeo.translate(0, 0, -lTot + l);
        this.poolIntersectRegions.add(new THREE.Mesh(dPWallGeo, poolDark));

        // Details
        if (this.generateDetails) {
            let detailGeo = new THREE.CylinderGeometry(1, 1, 0.05, smoothness * 2, 1);
            let detail = new THREE.Mesh(detailGeo, poolDark);
            let detailProps = [
                // Floor outlets
                {
                    r: 0.5,
                    x: r + Math.sin(Math.PI / 4),
                    y: 0,
                    z: -lTot + r + Math.sin(Math.PI / 4),
                    rot: new THREE.Vector3(0, 1, 0),
                    angle: 0
                },
                {
                    r: 0.5,
                    x: (r + Math.sin(Math.PI / 4)) +
                        (w - r * 2 - Math.sin(Math.PI / 4) * 2) / 3,
                    y: 0,
                    z: -lTot + r + Math.sin(Math.PI / 4),
                    rot: new THREE.Vector3(0, 1, 0),
                    angle: 0
                },
                {
                    r: 0.5,
                    x: (r + Math.sin(Math.PI / 4)) +
                        (w - r * 2 - Math.sin(Math.PI / 4) * 2) / 3 * 2,
                    y: 0,
                    z: -lTot + r + Math.sin(Math.PI / 4),
                    rot: new THREE.Vector3(0, 1, 0),
                    angle: 0
                },
                {
                    r: 0.5,
                    x: (r + Math.sin(Math.PI / 4)) +
                        (w - r * 2 - Math.sin(Math.PI / 4) * 2),
                    y: 0,
                    z: -lTot + r + Math.sin(Math.PI / 4),
                    rot: new THREE.Vector3(0, 1, 0),
                    angle: 0
                },

                // Wall inlets (behind differential pressure wall)
                {
                    r: 1,
                    x: 10,
                    y: WaterSettings.AVG_DEPTH / 2,
                    z: 0,
                    rot: new THREE.Vector3(1, 0, 0),
                    angle: Math.PI / 2
                },
                {
                    r: 1,
                    x: w - 10,
                    y: WaterSettings.AVG_DEPTH / 2,
                    z: 0,
                    rot: new THREE.Vector3(1, 0, 0),
                    angle: 0
                },

                // Wall outlets
                {
                    r: 2,
                    x: 10,
                    y: WaterSettings.AVG_DEPTH / 2,
                    z: -lTot,
                    rot: new THREE.Vector3(1, 0, 0),
                    angle: 0
                },
                {
                    r: 2,
                    x: w - 10,
                    y: WaterSettings.AVG_DEPTH / 2,
                    z: -lTot,
                    rot: new THREE.Vector3(1, 0, 0),
                    angle: 0
                }
            ];

            for (let prop in detailProps) {
                detail.scale.x = detailProps[prop].r;
                detail.scale.z = detailProps[prop].r;
                detail.rotateOnAxis(detailProps[prop].rot, detailProps[prop].angle);
                detail.position.set(detailProps[prop].x, detailProps[prop].y, detailProps[prop].z);
                this.mesh.push(detail.clone());
            }
        }
    }
}

export default Pool;