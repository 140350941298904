
const styles = theme => ({
    robotCardContainer : {
        overflowY : "scroll"
    },
    robotCardRoot: {
        paddingBottom: 70,
    },
    contentHorizontal : {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyItems: "flex"
    },
    gutterHorizontal : {
        cursor: "e-resize",
        height: "100%",
    },
    contentVertical : {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    gutterVertical : {
        cursor: "s-resize"
    },
    split : {
        width: "100%",
        height: "100%",
    },
    gutter : {
        cursor: "e-e-resize",
        height: "100%"
    }

});

export {
    styles,
};
