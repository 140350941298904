module.exports = {
    locale: 'en-GB',
    messages: {
        'INFORMATIONMENUCONTAINER.CURRENT_LABEL': 'Now:',
        'INFORMATIONMENUCONTAINER.AVERAGE_LABEL': 'Average:',
        'INFORMATIONMENUCONTAINER.HIGH_TIDE_LABEL': 'High tide:',
        'INFORMATIONMENUCONTAINER.OCCURS_AT_LABEL': 'Occurs at:',
        'INFORMATIONMENUCONTAINER.LOW_TIDE_LABEL': 'Low tide:',
    },
};
