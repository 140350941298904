import * as THREE from 'three';
import { GridSettings, Playback, WaterSettings } from './componenets/OperationalValues';

let Parameters = Object.freeze({
    AXIS_SIZE: 2
});

export function addCoordinateSystem() {
    let coordinates = [];
    addAxis(1, coordinates); // x-axis, red
    addAxis(2, coordinates); // y-axis, green
    addAxis(3, coordinates); // z-axis, blue
    return coordinates;
}

export function getRandomVal(min, max) {
    return Math.floor(Math.random() * (max - min) + 1) + min;
}

export function constructSimulation(that) {
    that.renderer = null;
    that.camera = null;
    that.scene = null;

    that.robotUpdateQueue = [];
    that.siteUpdateQueue = [];
    that.selectedRobotUpdateQueue = [];
    that.playbackPaths = [];
    that.isHandlingPlayback = false;
    that.isHandlingRobotUpdate = false;
    that.isHandlingSiteUpdate = false;
    that.isHandlingSelectedRobotUpdate = false;

    that.entityManager = null;
    that.robotTrailLines = {};
    that.robotPathLines = {};

    that.pointLight = null;
    that.controls = null;
    that.isTracking = null;
    that.trackedRobot = null;
    that.frameId = null;
    that.clock = null;
    that.deltaTime = null;
    that.time = null;
    that.runningTime = null;
    that.oldTime = null;
    that.stats = null;
    that.useSectionTool = false;
    that.isShown = true;
    that.sectionTool = null;
    that.robotRenderers = {};
    that.robotCameras = {};
    that.controller = null;
    that.lastGameButton = {};
    that.rovCameraTilt = 0;

    //Debug
    that.robotCameraHelpers = {};

    that.pool = null;
    that.grid = null;
    that.water = null;
    that.robots = null;
    that.robotStack = [];
    that.robotGroup = null;
    that.rov = null;
    that.site = null;
    that.secIndex = 0;
    that.font = null;
    that.robotPaths = null;

    // Raycasting
    that.raycaster = null;
    that.mouse = null;
    that.mouseDX = null;
    that.mouseDY = null;
    that.mouseUX = null;
    that.mouseUY = null;

    that.state = {
        isLoadingElements: true,
        isLiveMode: false,
        isLoadingPos: true,
        loadingPosValue: 0,
        isRunning: false,
        sliderValue: 0,
        pauseText: 'Pause visualization',
        speed: 1,
        currentLabel: '',
        averageLabel: '',
        highTideLabel: '',
        highOccursAtLabel: '',
        lowOccursAtLabel: '',
        lowTideLable: '',
        occursAtLabel: '',
        showWaves: WaterSettings.WAVES_VISIBLE,
        showGrid: GridSettings.VISIBLE,
        showWater: WaterSettings.WATER_VISIBLE,
        gridSize: GridSettings.DISTANCE,
        gridSizeInput: GridSettings.DISTANCE,
        playbackValue1: Playback.defaultValue1,
        playbackValue2: Playback.defaultValue2
    };
}

// https://medium.com/@charpeni/arrow-functions-in-class-properties-might-not-be-as-great-as-we-think-3b3551c440b1
export function bindSimulation(that) {
    that.renderAnimation = that.renderAnimation.bind(that);
    that.generateRobotVisualization = that.generateRobotVisualization.bind(that);
    that.generateRobot = that.generateRobot.bind(that);
    that.onDocumentMouseClick = that.onDocumentMouseClick.bind(that);
    that.onDocumentMouseMove = that.onDocumentMouseMove.bind(that);
    that.onButtonWaterClick = that.onButtonWaterClick.bind(that);
    that.onWaveCheck = that.onWaveCheck.bind(that);
    that.onButtonGridClick = that.onButtonGridClick.bind(that);
    that.onGridAdjust = that.onGridAdjust.bind(that);
    that.onButtonCameraClick = that.onButtonCameraClick.bind(that);
    that.onButtonCameraClick = that.onButtonCameraClick.bind(that);
    that.handleFollowRobotPathLive = that.handleFollowRobotPathLive.bind(that);
    that.handleFollowRobotPathPlayback = that.handleFollowRobotPathPlayback.bind(that);
    that.handleSelectedRobotUpdate = that.handleSelectedRobotUpdate.bind(that);
    that.handleAxisMove = that.handleAxisMove.bind(that);
    that.handleButtonPress = that.handleButtonPress.bind(that);
    that.stopRobotPlayback = that.stopRobotPlayback.bind(that);
    that.sync = that.sync.bind(that);
}

export function constructLive(that) {
    that.renderer = null;
    that.camera = null;
    that.scene = null;

    that.robotUpdateQueue = [];
    that.siteUpdateQueue = [];
    that.isHandlingRobotUpdate = false;
    that.isHandlingSiteUpdate = false;

    that.pointLight = null;
    that.controls = null;
    that.isTracking = null;
    that.trackedRobot = null;
    that.frameId = null;
    that.clock = null;
    that.deltaTime = null;
    that.time = null;
    that.oldTime = null;
    that.stats = null;
    that.isShown = true;
    that.sectionTool = null;
    that.pool = null;
    that.grid = null;
    that.water = null;
    that.robots = null;
    that.site = null;
    that.secIndex = 0;
    that.font = null;
    that.robotPaths = null;
    that.robotGroup = null;
    that.assetLoader = null;

    // Robot position and orientation
    that.trailMaterial = null;
    that.MAX_POINTS = 500;

    // Raycasting
    that.raycaster = null;
    that.mouse = null;
    that.mouseDX = null;
    that.mouseDY = null;
    that.mouseUX = null;
    that.mouseUY = null;

    that.state = {
        isLoadingElements: true,
        isLiveMode: true,
        isLoadingPos: true,
        loadingPosValue: 0,
        isRunning: false,
        sliderValue: 0,
        pauseText: 'Pause visualization',
        speed: 1,
        currentLabel: '',
        averageLabel: '',
        highTideLabel: '',
        highOccursAtLabel: '',
        lowOccursAtLabel: '',
        lowTideLable: '',
        occursAtLabel: '',
        showWaves: WaterSettings.WAVES_VISIBLE,
        showGrid: GridSettings.VISIBLE,
        showWater: WaterSettings.WATER_VISIBLE,
        gridSize: GridSettings.DISTANCE,
        gridSizeInput: GridSettings.DISTANCE
    };
}

// https://medium.com/@charpeni/arrow-functions-in-class-properties-might-not-be-as-great-as-we-think-3b3551c440b1
export function bindLive(that) {
    that.generateRobotVisualization = that.generateRobotVisualization.bind(that);
    that.generateRobot = that.generateRobot.bind(that);
    that.renderAnimation = that.renderAnimation.bind(that);
    that.onDocumentMouseClick = that.onDocumentMouseClick.bind(that);
    that.onDocumentMouseMove = that.onDocumentMouseMove.bind(that);
    that.onButtonWaterClick = that.onButtonWaterClick.bind(that);
    that.onWaveCheck = that.onWaveCheck.bind(that);
    that.onButtonGridClick = that.onButtonGridClick.bind(that);
    that.onGridAdjust = that.onGridAdjust.bind(that);
    that.onButtonCameraClick = that.onButtonCameraClick.bind(that);
    that.handleRobotUpdate = that.handleRobotUpdate.bind(that);
    that.updateRobotPositionAndOrientation = that.updateRobotPositionAndOrientation.bind(that);
}

export function createConvexRegionHelper(navMesh) {
    const regions = navMesh.regions;

    const geometry = new THREE.BufferGeometry();
    const material = new THREE.MeshBasicMaterial(
        { vertexColors: THREE.VertexColors }
    );
    material.side = THREE.DoubleSide;

    const positions = [];
    const colors = [];

    const color = new THREE.Color();

    for (let region of regions) {
        // one color for each convex region
        color.setHex(Math.random() * 0xffffff);

        // count edges
        let edge = region.edge;
        const edges = [];
        do {
            edges.push(edge);
            edge = edge.next;
        } while (edge !== region.edge);

        // triangulate
        const triangleCount = (edges.length - 2);

        for (let i = 1, l = triangleCount; i <= l; i++) {
            const v1 = edges[0].vertex;
            const v2 = edges[i + 0].vertex;
            const v3 = edges[i + 1].vertex;

            positions.push(v1.x, v1.y, v1.z);
            positions.push(v2.x, v2.y, v2.z);
            positions.push(v3.x, v3.y, v3.z);

            colors.push(color.r, color.g, color.b);
            colors.push(color.r, color.g, color.b);
            colors.push(color.r, color.g, color.b);
        }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    const mesh = new THREE.Mesh(geometry, material);

    return mesh;
}

export function savePath(path) {
    if (localStorage) {
        let paths = localStorage.getItem('simulationPaths') !== null ?
            JSON.parse(localStorage.getItem('simulationPaths')) : [];
        paths.push(path);
        localStorage.setItem('simulationPaths', JSON.stringify(paths));
        return true;
    } else return false;
}

function getAllSavedPaths() {
    return updateSavedPaths();
}

export function getAllPathsInInterval(fromTime, toTime, that) {
    let allPaths = getAllSavedPaths();
    that.playbackPaths = [];
    if (allPaths.length > 0) {
        let fromMillis, toMillis;
        if (fromTime === 24) {
            let temp = new Date(Date.now());
            temp.setHours(23, 59, 59);
            fromMillis = temp.getTime();
        } else {
            let temp = new Date(Date.now());
            temp.setHours(fromTime, 0, 0, 0);
            fromMillis = temp.getTime();
        }

        if (toTime === 24) {
            let temp = new Date(Date.now());
            temp.setHours(23, 59, 59);
            toMillis = temp.getTime();
        } else {
            let temp = new Date(Date.now());
            temp.setHours(toTime, 0, 0, 0);
            toMillis = temp.getTime();
        }

        // Filter out paths that match or partially match interval
        for (let i = 0; i < allPaths.length; i++) {
            // Cannot get paths that have not finished yet
            if (allPaths[i].approxEndTime <= new Date().getTime()) {
                // When whole path is in time interval
                if (allPaths[i].startTime >= fromMillis && allPaths[i].approxEndTime <= toMillis) {
                    that.playbackPaths.push(allPaths[i]);

                    // When path starts before fromTime
                } else if (allPaths[i].approxEndTime > fromMillis) {
                    // When path starts before fromTime and ends after toTime
                    if (allPaths[i].startTime < fromMillis && allPaths[i].approxEndTime > toMillis) {
                        let timeDiffBefore = (fromMillis - allPaths[i].startTime) / 1000;
                        let timeDiffAfter = (toMillis - allPaths[i].approxEndTime) / 1000;
                        let distanceToCutBefore = timeDiffBefore * allPaths[i].maxSpeed;
                        let distanceToCutAfter = timeDiffAfter * allPaths[i].maxSpeed;
                        let iteratedDistance = 0;
                        let points = allPaths[i]._waypoints;
                        let pointsInInterval = [];
                        for (let i = 1; i < points.length; i++) {
                            if (iteratedDistance < distanceToCutBefore) {
                                iteratedDistance += Math.sqrt(
                                    Math.pow(points[i].x - points[i - 1].x, 2) +
                                    Math.pow(points[i].y - points[i - 1].y, 2) +
                                    Math.pow(points[i].z - points[i - 1].z, 2)
                                );
                            } else
                                pointsInInterval.push(points[i]);
                        }
                        iteratedDistance = 0;
                        for (let i = points.length - 2; i > 0; i--) {
                            if (iteratedDistance < distanceToCutAfter) {
                                iteratedDistance += Math.sqrt(
                                    Math.pow(points[i].x - points[i + 1].x, 2) +
                                    Math.pow(points[i].y - points[i + 1].y, 2) +
                                    Math.pow(points[i].z - points[i + 1].z, 2)
                                );
                                pointsInInterval.pop();
                            }
                        }
                        allPaths[i]._waypoints = pointsInInterval;
                        that.playbackPaths.push(allPaths[i]);

                        //When path ends before toTime
                    } else {
                        let timeDiff = (fromMillis - allPaths[i].startTime) / 1000;
                        let distanceToCut = timeDiff * allPaths[i].maxSpeed;
                        let iteratedDistance = 0;
                        let points = allPaths[i]._waypoints;
                        let pointsInInterval = [];
                        for (let i = 1; i < points.length; i++) {
                            if (iteratedDistance < distanceToCut) {
                                iteratedDistance += Math.sqrt(
                                    Math.pow(points[i].x - points[i - 1].x, 2) +
                                    Math.pow(points[i].y - points[i - 1].y, 2) +
                                    Math.pow(points[i].z - points[i - 1].z, 2)
                                );
                            } else
                                pointsInInterval.push(points[i]);
                        }
                        allPaths[i]._waypoints = pointsInInterval;
                        that.playbackPaths.push(allPaths[i]);
                    }

                    // When path starts within interval and overshoots toTime
                } else if (allPaths[i].startTime < toMillis) {
                    let timeDiff = (toMillis - allPaths[i].approxEndTime) / 1000;
                    let distanceToCut = timeDiff * allPaths[i].maxSpeed;
                    let iteratedDistance = 0;
                    let points = allPaths[i]._waypoints;
                    for (let i = points.length - 2; i > 0; i--) {
                        if (iteratedDistance < distanceToCut) {
                            iteratedDistance += Math.sqrt(
                                Math.pow(points[i].x - points[i + 1].x, 2) +
                                Math.pow(points[i].y - points[i + 1].y, 2) +
                                Math.pow(points[i].z - points[i + 1].z, 2)
                            );
                            points.pop();
                        }
                    }
                    allPaths[i]._waypoints = points;
                    that.playbackPaths.push(allPaths[i]);
                }
            }
        }
        that.playbackPaths.sort(function (a, b) {
            return a.startTime - b.startTime
        });
    }
}

function updateSavedPaths() {
    let updatedPaths = [];
    if (localStorage.getItem('simulationPaths') !== null) {
        let dateNow = new Date();
        for (let i = 0; i < JSON.parse(localStorage.getItem('simulationPaths')).length; i++) {
            let path = JSON.parse(localStorage.getItem('simulationPaths'))[i];
            let pathDate = new Date(path.startTime);
            if (pathDate.getDate() === dateNow.getDate() && pathDate.getMonth() === dateNow.getMonth() &&
                pathDate.getFullYear() === dateNow.getFullYear()) {
                updatedPaths.push(path);
            }
        }
        localStorage.setItem('simulationPaths', JSON.stringify(updatedPaths));
    }
    return updatedPaths;
}

function addAxis(axis, coordinates) {
    let fromNeg = new THREE.Vector3(axis === 1 ? -Parameters.AXIS_SIZE : 0,
        axis === 2 ? -Parameters.AXIS_SIZE : 0,
        axis === 3 ? -Parameters.AXIS_SIZE : 0);
    let toNeg = new THREE.Vector3(0, 0, 0);
    let fromPos = new THREE.Vector3(0, 0, 0);
    let toPos = new THREE.Vector3(axis === 1 ? Parameters.AXIS_SIZE : 0,
        axis === 2 ? Parameters.AXIS_SIZE : 0,
        axis === 3 ? Parameters.AXIS_SIZE : 0);
    let axisColor = (axis === 1 ? 0xff0000 : (axis === 2 ? 0x00ff00 : 0x0000ff));

    let posMat = new THREE.LineBasicMaterial({ linewidth: 2, color: axisColor });
    let negMat = new THREE.LineDashedMaterial({ linewidth: 2, color: axisColor, dashSize: 0.8, gapSize: 0.5 });

    let gNeg = new THREE.Geometry();
    gNeg.vertices.push(fromNeg);
    gNeg.vertices.push(toNeg);
    let coordNeg = new THREE.Line(gNeg, negMat, THREE.LineSegments);
    coordNeg.computeLineDistances(); // NB!
    coordinates.push(coordNeg);

    let gPos = new THREE.Geometry();
    gPos.vertices.push(fromPos);
    gPos.vertices.push(toPos);
    let coordPos = new THREE.Line(gPos, posMat, THREE.LineSegments);
    coordPos.computeLineDistances();
    coordinates.push(coordPos);
}